// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect, useCallback} from "react";
import { useHistory } from "react-router-dom";
import { PlasmicOnboardingBankingDetailsPersonal } from "./plasmic/astriusdraft/PlasmicOnboardingBankingDetailsPersonal";
import LoadingSpinner2 from "./LoadingSpinner2";
import axios from "axios";
import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess } from 'react-plaid-link';
import { v4 } from "uuid";
import {useAuth} from "../contexts/AuthContext"
import { config } from './constants'

const handleBeforeUnload = (e) => {
  e.preventDefault();
  const message =
    "Are you sure you want to leave? All provided data will be lost.";
  e.returnValue = message;
  return message;
};

function Link(props, ref1){

  const {currentUser, publicToken, setPublicToken, metaData, setMetaData} = useAuth()


  const history = useHistory()

  const onSuccess = useCallback (async (public_token, metadata) => {

    setPublicToken(public_token)
    setMetaData(metadata)

    window.removeEventListener("beforeunload", handleBeforeUnload);

    history.push("/review-details")

  })

  const config1 = {
    onSuccess,
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: props.linkToken,
    env: config.version,
  }
  
  const {open, ready } = usePlaidLink(config1)


return <PlasmicOnboardingBankingDetailsPersonal root={{ ref1 }} {...props} addBankButton={{
  onClick: () => open()
}}/>;


}



function OnboardingBankingDetailsPersonal_(props, ref) {
  const [loading, setLoading] = useState(true)
  const [linkToken, setLinkToken] = useState(null);
  const {currentUser} = useAuth()


  async function generateToken(){

    

    const response = await axios({
      method: "POST",
      url: `${config.endpoint}/achFunctions-plaidAPIRequest`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      },
      data: {
        "user": v4()
      }
    })
    
    setLinkToken(response.data.link_token)
    
    setLoading(false)

   

    }


    useEffect(() => {

      window.addEventListener("beforeunload", handleBeforeUnload);

      generateToken()
      
    
      }, [])




  return ( linkToken == null ? <PlasmicOnboardingBankingDetailsPersonal root={{ ref }} {...props} vertStack={ loading && {
    children: <LoadingSpinner2/>
  }}/> : <Link linkToken={linkToken}/> )

}

const OnboardingBankingDetailsPersonal = React.forwardRef(
  OnboardingBankingDetailsPersonal_
);

export default OnboardingBankingDetailsPersonal;
