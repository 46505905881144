// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicOnboardingSoleProprietorDetailsRetry } from "./plasmic/astriusdraft/PlasmicOnboardingSoleProprietorDetailsRetry";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import {useAuth} from "../contexts/AuthContext"
import "./Onboarding.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {updateDoc, doc} from "firebase/firestore"; 
import { db } from "../firebase"
import LoadingSpinner from "./LoadingSpinner";
import { config } from './constants'

function OnboardingSoleProprietorDetailsRetry_(props, ref) {
  const history = useHistory()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [legalBusinessName, setLegalBusinessName] = useState('')
  const [displayBusinessName, setDisplayBusinessName] = useState('')
  const [ssn, setSSN] = useState("")
  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [industry, setIndustry] = useState('')
  const [dob, setDob] = useState('')
  const [error, setError] = useState(false)
  const {currentUser, tempUrl, onboardModal, setOnboardModal} = useAuth()
  const [loading, setLoading] = useState(false)



  return ( onboardModal ? <LoadingSpinner/> : loading ? <LoadingSpinner/> : <PlasmicOnboardingSoleProprietorDetailsRetry root={{ ref }} {...props}
    firstName={{
      value: firstName,
      onChange: (e) => {
        setFirstName(e.target.value)
      }
    }}
    lastName={{
      value: lastName,
      onChange: (e) => {
        setLastName(e.target.value)
      }
    }}
    legalBusinessName={{
      value: legalBusinessName,
      onChange: (e) => {
        setLegalBusinessName(e.target.value)
      }
    }}
    displayBusinessName={{
      value: displayBusinessName,
      onChange: (e) => {
        setDisplayBusinessName(e.target.value)
      }
    }}
    ssn={{
      value: ssn,
      onChange: (e) => {
        setSSN(e.target.value)
      }, 
      onBlur: (e) => {
        if (!(e.target.value.includes("-")) && ssn.length > 4){
          setSSN(ssn.substring(0, 3) + "-" + ssn.substring(3,5) + "-" + ssn.substring(5))
        }
      }
    }}
    address1={{
      value: address1,
      onChange: (e) => {
        setAddress1(e.target.value)
      }
    }}
    city={{
      value: city,
      onChange: (e) => {
        setCity(e.target.value)
      }
    }}
    selectState={{
      onChange: (e) => {
       setState(e)
      }
    }}
    zip={{
      value: zip,
      onChange: (e) => {
        setZip(e.target.value)
      }
    }}
    selectIndustry={{
      onChange: (e) => {
        setIndustry(e)
      }
    }}
    dob={{
      children: <DatePicker placeholderText={"mm/dd/yyyy"} className="dateBox" selected={dob} onChange={(date) => setDob(date)} />
    }}
    errorText={{
      wrap: node => error ? node : null
    }}
    continueButton={{
      onClick: async () => {

  
          if (firstName === '' || lastName  === '' || legalBusinessName  === '' || displayBusinessName  === '' || ssn  === '' || address1  === '' 
          || city  === '' || state  === '' || zip  === '' || industry  === '' || dob  === ''){
            setError(true)
          } else{
           
            setLoading(true)

            var reqBody = {
              firstName: firstName,
              lastName: lastName,
              email: currentUser.email,
              type: "business",
              dateOfBirth: dob.getFullYear() + "-" + ("0" + (Number(dob.getMonth())+ 1)).slice(-2) + "-" + ("0" + dob.getDate()).slice(-2),
              ssn: ssn,
              address1: address1,
              city: city,
              state: state,
              postalCode: zip,
              businessClassification: industry,
              businessType: "soleProprietorship",
              businessName: legalBusinessName,
            }



       const retryStatus = await axios({
        method: "POST",
        url: `${config.endpoint}/onboardingFunctions-handleRetryStatus`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: {
          customerUrl: tempUrl.customerUrl,
          reqBody: reqBody
        }
      })

     
      

      var customerUrlJSON = JSON.stringify({
        customerUrl: tempUrl.customerUrl
      })
  
      
      const resVerificationStatus = await axios({
        method: "POST",
        url: `${config.endpoint}/onboardingFunctions-getVerificationStatus`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: {
          customerUrl: customerUrlJSON
        }
      })
  
  
      var businessVerificationStatus = resVerificationStatus.data.status

    
    

      if (businessVerificationStatus === "verified") {

        await updateDoc(doc(db, "userInfo", tempUrl.docId), {
          verificationStatus: businessVerificationStatus
      
        })


        history.push("/account-approved")


      } else if (businessVerificationStatus === "document") {

        await updateDoc(doc(db, "userInfo", tempUrl.docId), {
          verificationStatus: businessVerificationStatus
      
        })

        history.push("/onboarding-personaldetails-document")

      }



          }
          
      }
    }}
    />
  );
}

const OnboardingSoleProprietorDetailsRetry = React.forwardRef(
  OnboardingSoleProprietorDetailsRetry_
);

export default OnboardingSoleProprietorDetailsRetry;
