// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicMenuSelectStack } from "./plasmic/astriusdraft/PlasmicMenuSelectStack";
import { useAuth } from "../contexts/AuthContext";

function MenuSelectStack_(props, ref) {
 


  return <PlasmicMenuSelectStack root={{ ref }} {...props} />


}

const MenuSelectStack = React.forwardRef(MenuSelectStack_);

export default MenuSelectStack;
