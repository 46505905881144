import React from "react";
import "../spinner2.css";

export default function LoadingSpinner2() {
  return (
    <div className="spinner2">
        {/* <span>Loading...</span> */}
    <div className="half-spinner2"></div>
    </div>
  );
}