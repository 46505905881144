// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicOnboardingOwnerDetailsDocument } from "./plasmic/astriusdraft/PlasmicOnboardingOwnerDetailsDocument";
import axios from "axios"
import LoadingSpinner2 from "./LoadingSpinner2";
import {updateDoc, doc, getDoc} from "firebase/firestore"; 
import { db } from "../firebase"
import { config } from './constants'
import {useAuth} from "../contexts/AuthContext"
import { useHistory } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";


function OnboardingOwnerDetailsDocument_(props, ref) {
  const hiddenFileInput = React.useRef(null);
  const { tempUrl, setTempUrl, currentUser, onboardModal, setOnboardModal } = useAuth()
  const [licenseCheck, setLicenseCheck] = useState(false)
  const [idCheck, setIdCheck] = useState(false)
  const [type, setType] = useState('')
  const [error, setError] = useState(false)
  const [contentError, setContentError] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()


  const handleChange = async (event) => {
    const formData = new FormData();
    const file = event.target.files[0];
    const fileName = event.target.files[0].name

    function convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
       reader.onerror = reject;
      });
    }

   
    formData.append("file", event.target.files[0], {
      filename: event.target.files[0].name,
      contentType: event.target.files[0].type,
      knownLength: event.target.files[0].size,
    });


  


if (!(event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg' )){
  setContentError(true)
} else {
  
  const b64 = await convertFileToBase64(file);

  const data = {
    file: b64.split(`data:${event.target.files[0].type};base64,`)[1],
    filename: event.target.files[0].name,
    contentType: event.target.files[0].type,
    knownLength: event.target.files[0].size,
    customerUrl: tempUrl.ownerUrl,
    documentType: type,
  };

  try {

    setLoading(true)

    const status =  await axios({
      method: "POST",
      url: `${config.endpoint}/onboardingFunctions-handleBizDocument`,
      data,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      }
    })
    


    var tempOwners = tempUrl.owners


    for (var i = 0; i < tempOwners.length; i++){

      if (tempOwners[i].ownerUrl === tempUrl.ownerUrl){
        tempOwners[i].ownerVerification = "pending"
        break;
      }

    }


    var ownersgood = true

    for (var i = 0; i < tempOwners.length; i++){
           
      if (tempOwners[i].ownerVerification !== "verified" && tempOwners[i].ownerVerification !== "pending"){

        

        setTempUrl({ownerUrl: tempOwners[i].ownerUrl, name: tempOwners[i].name, docId: tempUrl.docId, owners: tempOwners, customerUrl: tempUrl.customerUrl})

        if (tempOwners[i].ownerVerification === "incomplete"){
          ownersgood = false
          history.push("/verify-owners")
          break;
        } else {
          
          ownersgood = false
          history.push("/verify-owners-document")
          setLicenseCheck(false)
          setIdCheck(false)
          setLoading(false)
          break;
        }

      

      }

    }


  if (ownersgood) {

    history.push("/onboarding-businessdetails-documentreceived")

  }



  } catch (e){
    setLoading(false)
    alert("An error occured while uploading your file. Please ensure that your file meets all of the requirements and try again.")
  }
 


}



  };


  return (
  <>
 { onboardModal ? <LoadingSpinner/> : <PlasmicOnboardingOwnerDetailsDocument root={{ ref }} {...props} 
  titleSlot={`Our compliance partners have requested additional documentation in order to verify the identity of your owner ${tempUrl.name}.`}
  subtitleSlot={`Please upload a driver's license or government-issued ID card in order to verify ${tempUrl.name}'s identity. The uploaded file must be either a .jpg, .jpeg, or .png, and be no larger than 10MB in size.`}
  vertStack={ loading && {
    children: <LoadingSpinner2/>
  }} 
  submitButton={{
    onClick: () =>  {

      if (type === ''){
        setError(true)
      } else {
        hiddenFileInput.current.click()
      }
      
    }
  }}
  licenseCheck={{
    isChecked: licenseCheck,
    onChange: (e) => {
      setType('license')
      setLicenseCheck(!licenseCheck)
      setIdCheck(false)
    },
  }}
  idCheck={{
    isChecked: idCheck,
    onChange: (e) => {
      setType('idCard')
      setIdCheck(!idCheck)
      setLicenseCheck(false)
    },
  }}
  errorText={{
    wrap: node => error ? node : null
  }}
  contentError={{
    wrap: node => contentError ? node : null
  }}
  /> }
  <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
  </>
  )
}

const OnboardingOwnerDetailsDocument = React.forwardRef(
  OnboardingOwnerDetailsDocument_
);

export default OnboardingOwnerDetailsDocument;
