// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function GroupIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 25 24"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M12.5 0C5.597 0 0 5.372 0 12c0 6.627 5.597 11.999 12.5 11.999 6.904 0 12.5-5.372 12.5-12C25 5.372 19.404 0 12.5 0zm0 3.588c2.284 0 4.135 1.777 4.135 3.97 0 2.191-1.85 3.968-4.135 3.968-2.283 0-4.134-1.777-4.134-3.969s1.851-3.969 4.134-3.969zm-.003 17.274a9.402 9.402 0 01-5.974-2.115 1.664 1.664 0 01-.618-1.286c0-2.222 1.874-4.001 4.19-4.001h4.811c2.316 0 4.183 1.778 4.183 4.001 0 .495-.225.964-.617 1.285a9.399 9.399 0 01-5.975 2.116z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default GroupIcon;
/* prettier-ignore-end */
