// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useRef, useEffect} from "react";
import { PlasmicEditSubscription } from "./plasmic/astriusdraft/PlasmicEditSubscription";
import Form from 'react-bootstrap/Form';
import CurrencyInput from "react-currency-input-field";

function EditSubscription_(props, ref) {

  
  const { subscriptionEnabled, setSubscriptionEnabled, subscriptionAmount, subscriptionCycle, setSubscriptionCycle, currencyRef } = props

  

  useEffect(() => {

    currencyRef.current.value = "$" + subscriptionAmount

  }, [] )


  return <PlasmicEditSubscription root={{ ref }} {...props}
  subscriptionStatus={{
    children : <>
    <Form.Select aria-label="Default select example" onChange={(e) => {

      setSubscriptionEnabled(e.target.value) 
    
    }
      } value={subscriptionEnabled + ""}>
      <option value="true">Active</option>
      <option value="false">Cancel Subscription</option>
    </Form.Select>
  </>
  }}
  cycleStack={{
    children : <>
    <Form.Select aria-label="Default select example" onChange={(e) => setSubscriptionCycle(e.target.value)} value={subscriptionCycle}>
      <option value="Weekly" >Weekly</option>
      <option value="Biweekly">Biweekly</option>
      <option value="Every 3 Weeks">Every 3 Weeks</option>
      <option value="Monthly">Monthly</option>
      <option value="Bimonthly">Bimonthly</option>
      <option value="Every 3 Months">Every 3 Months</option>
      <option value="Every 4 Months">Every 4 Months</option>
      <option value="Every 5 Months">Every 5 Months</option>
      <option value="Every 6 Months">Every 6 Months</option>
    </Form.Select>
  </>
  }}
  subscriptionAmount={{
    children: <CurrencyInput ref={currencyRef}
    className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    placeholder="$ "
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  />;
}

const EditSubscription = React.forwardRef(EditSubscription_);

export default EditSubscription;
