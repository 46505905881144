import * as React from "react";
import { PlasmicAutomationSelect__Option } from "./plasmic/astriusdraft/PlasmicAutomationSelect__Option";

function AutomationSelect__Option_(props, ref) {
  const { plasmicProps } = PlasmicAutomationSelect__Option.useBehavior(
    props,
    ref
  );

  return <PlasmicAutomationSelect__Option {...plasmicProps} />;
}

const AutomationSelect__Option = React.forwardRef(AutomationSelect__Option_);

export default Object.assign(AutomationSelect__Option, {
  __plumeType: "select-option"
});
