// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicSignup2 } from "./plasmic/astriusdraft/PlasmicSignup2";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom"
import validator from 'validator'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, updateEmail, updatePassword, onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore"; 
import LoadingSpinner from "./LoadingSpinner";
import TagManager from 'react-gtm-module'
 

const tagManagerArgs = {
    gtmId: 'GTM-NF9C29K'
}
 
TagManager.initialize(tagManagerArgs)


function Signup2_(props, ref) {
  const [emailContent, setEmailContent] = useState('')
  const [passwordContent, setPasswordContent] = useState('')
  const [invalidEmailError, setInvalidEmailError] = useState(false)
  const [incorrectPasswordsError, setIncorrectPasswordsError] = useState(false)
  const [customError, setCustomError] = useState(false)
  const [errorContent, setErrorContent] = useState('Failed to signup')
  const { signup, currentUser } = useAuth()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [check, setCheck] = useState(false)


  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  function containsNumber(str) {
    const specialChars = /[1234567890]/;
    return specialChars.test(str);
  }

  async function handleSubmit() {
   
    setInvalidEmailError(false)
    setIncorrectPasswordsError(false)
    setCustomError(false)
    setErrorContent('Failed to login')
  
  
  if (!(validator.isEmail(emailContent))){
    setInvalidEmailError(true)
  } else if (!(passwordContent.length > 11 && containsSpecialChars(passwordContent) && containsNumber(passwordContent))){
    setErrorContent("Password needs to be more than 11 characters, contain at least 1 special character, and contain at least 1 number")
    setCustomError(true)
  } else if (!check){
    setErrorContent("Must check agree before signing up")
    console.log('ok')
    setCustomError(true)
  } else {
  
    var email = emailContent
    var password = passwordContent
  
    try {
      setLoading(true)
      await signup(email, password)
      
  
      history.push("/verify-email")
    } catch (e){
      setCheck(false)
      setLoading(false)
      if (e.code === "auth/email-already-in-use"){
        setErrorContent("Email is already in use")
        setCustomError(true)
      } else if (e.code === "auth/weak-password"){
        setErrorContent("Password needs to be at least 6 characters")
        setCustomError(true)
      } else {
        setCustomError(true)
      }
  
    }
   
  }
  setLoading(false)
  
  }
  


  return loading ? <LoadingSpinner/> : <PlasmicSignup2 root={{ ref }} {...props} 
  signupEmailInput={{
    danger: invalidEmailError,
    autoFocus: true,
    value: emailContent,
    onChange: (e) => {
      setEmailContent(e.target.value);
    }

  }}
  signupPasswordInput={{
    danger: incorrectPasswordsError,
    value: passwordContent,
    onChange: (e) => {
      setPasswordContent(e.target.value);
    }

  }}
  signupButton={{
    onClick: () => handleSubmit(),
    disabled: loading
  }}
invalidEmailText={{
  wrap: node => invalidEmailError ? node : null
}}
incorrectPasswordsText={{
  wrap: node => incorrectPasswordsError ? node : null 
}}
customErrorInstance={{
  wrap: node => customError ? node : null,
  props: {
    customErrorText: errorContent
  }
}}
checkAgree={{
  onChange: (e) => {
    setCheck(e)
  }
}}
  />;
}

const Signup2 = React.forwardRef(Signup2_);

export default Signup2;
