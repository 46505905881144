import React, { useContext, useState, useEffect, useRef } from 'react'
import { auth } from "../firebase"
import {
  createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut,
  sendPasswordResetEmail, updateEmail, updatePassword, onAuthStateChanged, sendEmailVerification
} from "firebase/auth";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, listAll, list } from "firebase/storage";
import { v4 } from "uuid";
import { parse, stringify, toJSON, fromJSON } from 'flatted';
import { useHistory } from 'react-router-dom';
import useLocalStorage from "../hooks/useLocalStorage";
import { ToastContainer, toast } from 'react-toastify';

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}


export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [onboarded, setOnboarded] = useState()
  const [loading, setLoading] = useState(true)
  const [globalLoading, setglobalLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [milestones, setMilestones] = useState([{ id: new Date().getTime(), description: '', dueDate: "", amount: '', status: "Awaiting Payment" }]);
  const [escrowReference, setEscrowReference] = useState(null);
  const [globalHits, setGlobalHits] = useState([])
  const [showOptions, setShowOptions] = useState(false)
  const [clientSubmission, setClientSubmission] = useState({ company: "", contactName: "", email: "", invoiceId: "" })
  const history = useHistory()
  const [escrowPaymentPreview, setEscrowPaymentPreview] = useState({ escrowDeposit: 0, milestoneOrContract: "", title: "", milestonesExist: false, dueDate: "" })
  const [escrowSubmitParams, setEscrowSubmitParams] = useState({})
  const [timeSelectPostClick, setTimeSelectPostClick] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)
  const [currentTimeSelect, setCurrentTimeSelect] = useState("Today")
  const [currentParam, setCurrentParam] = useState('')
  const [invoiceFields, setInvoiceFields] = useState([{ item: '', quantity: 0, price: '', id: v4(), balanceDue: 0 }])
  const [invoiceBalance, setInvoiceBalance] = useState(0)
  const [invoiceSubmitParams, setInvoiceSubmitParams] = useState({})
  const [closeCardModal, setCloseCardModal] = useState(false)
  const [onboardModal, setOnboardModal] = useState(false)
  const [identity, setIdentity] = useState(false)
  const [authUser, setAuthUser] = useState()
  /// contractslist2 global states
  const [contractHits, setContractHits] = useState([])
  const [openContractSearch, setOpenContractSearch] = useState(false)
  const [edgeLoading, setEdgeLoading] = useState(false)

  //paymentslist global states
  const [paymentHits, setPaymentHits] = useState([])
  const [openPaymentSearch, setOpenPaymentSearch] = useState(false)

  //clientslist global states
  const [clientHits, setClientHits] = useState([])
  const [openClientSearch, setOpenClientSearch] = useState(false)

  const [isVerified, setIsVerified] = useState(false)

  const [paySuccess, setPaySuccess] = useState(false)

  const [permissionModal, setPermissionModal] = useState(false)

  const [permissions, setPermissions] = useState([])


  function checkPermission(name) {

    if (permissions.length === 0)
      return true


    return permissions.includes(name)


  }

  const showToast = (status, msg) => {

    if (status) {
      toast.success(msg, {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      toast.error(msg, {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }


  }

  function setOpen(bool) {
    setIsOpen(bool)
  }

  async function signup(email, password) {
    const newUser = await createUserWithEmailAndPassword(auth, email, password)


    const host = window.location.host
    const protocol = host.startsWith('localhost') ? 'http' : 'https'
    const settings = {
      url: `${protocol}://${host}/get-started`,
    }

    await sendEmailVerification(auth.currentUser, settings)


    return newUser

  }
  //update all of these to the 
  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  //may need to fix this later idk
  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }



  //uploading file for handleEscrowSubmit
  // async function uploadFile(upload){

  //     if (upload == null) return '';

  //     const path = `contractFile/${upload.name + v4()}`

  //     const imageRef = ref(storage, path);

  //     uploadBytes(imageRef, upload).then((snapshot) => {


  //     });

  //     return path    

  // }





  //handling escrow submission
  async function handleEscrowSubmit(title, upload, milestonesExist, dueDate, paymentMethods, automationOptions, reminderOptions, client, contractDescription) {

    const temp = escrowPaymentPreview

    if (milestonesExist) {
      temp.escrowDeposit = milestones[0].amount
      temp.milestoneOrContract = "Milestone 1: " + milestones[0].description
    } else {
      temp.escrowDeposit = price
      temp.milestoneOrContract = "Contract Title: " + title
    }

    temp.title = title
    temp.milestonesExist = milestonesExist

    var price = 0;

    for (var i = 0; i < milestones.length; i++) {
      price += Number(milestones[i].amount.replace(/[^0-9\.-]+/g, ''))
    }


    for (var i = 0; i < milestones.length; i++) {

      console.log(Number(milestones[i].amount.replace(/[^0-9\.-]+/g, "")))


      if (Number(milestones[i].amount.replace(/[^0-9\.-]+/g, "")) > 5000) {
        alert('ACH payments are capped at $5000 per transaction. If you would like to accept a single payment that is greater than this amount, then please divide it up across multiple different transactions with a new client created for each transaction. Please note that you can still use the same email of an existing client when creating a new one for the sake of passing this $5000 ACH transaction limit.')
      }

    }


    setEscrowPaymentPreview(temp)


    setEscrowSubmitParams({ title: title, price: price.toLocaleString('en-US', { style: 'currency', currency: 'USD', }), upload: upload, uploadName: upload == null ? "Contract Terms" : upload.name, milestonesExist: milestonesExist, dueDate: dueDate, paymentMethods: paymentMethods, automationOptions: automationOptions, reminderOptions: reminderOptions, client: client, contractDescription: contractDescription })

    history.push("/escrowpayment-preview")

    // history.pushState({}, null, );

    // add logic that perhaps says if back is selected then return and don't add items to the database, 
    // but then if send is sent on this page (which can be indicate via context state, ) then do add these items to the database. 
    // may have to check though to see if the state is maintained and make sure user doesn't re-load page or else for that you'll have to add some local storage thing. 


    // const path = await uploadFile(upload)


    //  const escrowsubmisionsInstance = await addDoc(collection(db, "escrowsubmissions"), {
    //     title: title,
    //     price: price,
    //     user: currentUser.uid,
    //     contractFile: path,
    //     milestonesExist: milestonesExist,
    //     dueDate: dueDate,
    //     paymentMethods: JSON.stringify(paymentMethods),
    //     automationOptions: stringify(automationOptions),
    //     reminderOptions: stringify(reminderOptions),
    //     client: stringify(client),
    //     date: serverTimestamp(),
    //    })


    //   for (var i = 0; i < milestones.length; i++){
    //     await addDoc(collection(escrowsubmisionsInstance, "Milestone Entries"), {
    //       milestoneTitle: milestones[i].description,
    //       milestonePrice: milestones[i].amount,
    //       dueDate: milestones[i].dueDate,
    //      })

    //   }



  }


  /// onboarding contexts
  const [setupDetails, setSetupDetails] = useLocalStorage({})
  const [businessDetails, setBusinessDetails] = useState({})
  const [controllers, setControllers] = useState([])
  const [publicToken, setPublicToken] = useState('')
  const [metaData, setMetaData] = useState('')
  const [personalDetails, setPersonalDetails] = useState({})
  const [tempUrl, setTempUrl] = useState({})
  //milestone submit contexts
  const [milestoneModal, setMilestoneModal] = useState(false)
  const [milestoneName, setMilestoneName] = useState('')
  const milestoneAmount = useRef(null)
  const [milestoneDueDate, setMilestoneDueDate] = useState('')
  const [milestoneDescription, setMilestoneDescription] = useState('')


  async function submitNewMilestone() {


    setMilestoneModal(false)

    var docId = ''
    var currentMilestones = []
    var price = 0

    const q = query(collection(db, "contracts"), where("user", "==", currentUser.uid), where("id", "==", currentParam))

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      docId = doc.id

      currentMilestones = doc.data().milestones
      price = doc.data().price
    });


    currentMilestones.push({ amount: milestoneAmount.current.value, description: milestoneName, dueDate: milestoneDueDate, id: v4(), paymentId: '', status: 'Upcoming Milestones' })



    await updateDoc(doc(db, "contracts", docId), {
      milestones: currentMilestones,
      price: Number(milestoneAmount.current.value.replace(/[^0-9\.-]+/g, "")) + price,
    })

    setMilestoneName('')
    setMilestoneDueDate('')
    setMilestoneDescription('')


  }



  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, user => {

      setCurrentUser(user)


      if (user) {
        setglobalLoading(true);

        const q = query(collection(db, "userInfo"), where("user", "==", user.uid))

        onSnapshot(q, (querySnapshot) => {
          if (querySnapshot.empty) {
            setglobalLoading(false);
            return;

          }
          const userInfo = querySnapshot.docs[0].data();

          setAuthUser(userInfo)

          if (userInfo?.parent_user) {
            const qa = query(collection(db, "userInfo"), where("user", "==", userInfo.parent_user))

            onSnapshot(qa, (querySnapshot) => {
              if (querySnapshot.empty) {
                setglobalLoading(false);
                return;

              }
              const parentInfo = querySnapshot.docs[0].data();



              const index = parentInfo.user_defined_roles.findIndex(obj => obj.name === userInfo.user_role);

              if (index !== -1) {
                setPermissions(parentInfo.user_defined_roles[index].permissions)

              } else {
                console.log("Index not found.");
                return;
              }


              setglobalLoading(false);

            })
            let duplicatedUser = user
            duplicatedUser.uid = userInfo?.parent_user
            userInfo?.parent_user && setCurrentUser(() => duplicatedUser)
          }
          else {
            setglobalLoading(false);

          }

        })
      }
      else {
        setglobalLoading(false);
      }


      setLoading(false)
    })

    return unsubscribe

  }, [])

  function handleSetMilestones(ms) {
    setMilestones(ms)
  }





  const value = {
    currentUser,
    authUser,
    isOpen,
    setOpen,
    signup,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    globalLoading,

    // escrow submission
    handleEscrowSubmit,
    milestones,
    handleSetMilestones,

    //hits
    globalHits,
    setGlobalHits,
    showOptions,
    setShowOptions,

    //client submission
    clientSubmission,
    setClientSubmission,
    escrowPaymentPreview,
    escrowSubmitParams,
    setEscrowSubmitParams,
    timeSelectPostClick,
    setTimeSelectPostClick,
    showSideBar,
    setShowSideBar,
    contractHits,
    setContractHits,
    openContractSearch,
    setOpenContractSearch,
    paymentHits,
    setPaymentHits,
    openPaymentSearch,
    setOpenPaymentSearch,
    clientHits,
    setClientHits,
    openClientSearch,
    setOpenClientSearch,
    setupDetails,
    setSetupDetails,
    businessDetails,
    setBusinessDetails,
    controllers,
    setControllers,
    publicToken,
    setPublicToken,
    metaData,
    setMetaData,
    personalDetails,
    setPersonalDetails,
    currentTimeSelect,
    setCurrentTimeSelect,
    milestoneModal,
    setMilestoneModal,
    milestoneName,
    setMilestoneName,
    milestoneAmount,
    milestoneDueDate,
    setMilestoneDueDate,
    milestoneDescription,
    setMilestoneDescription,
    submitNewMilestone,
    setCurrentParam,
    invoiceFields,
    setInvoiceFields,
    invoiceBalance,
    setInvoiceBalance,
    invoiceSubmitParams,
    setInvoiceSubmitParams,
    tempUrl,
    setTempUrl,
    closeCardModal,
    setCloseCardModal,
    edgeLoading,
    setEdgeLoading,
    isVerified,
    setIsVerified,
    onboardModal,
    setOnboardModal,
    identity,
    setIdentity,
    paySuccess,
    setPaySuccess,
    permissionModal,
    setPermissionModal,
    checkPermission,
    showToast

  }


  return (
    <AuthContext.Provider value={value}>
      <ToastContainer />
      {!loading && children}
    </AuthContext.Provider>
  )
}
