import * as React from "react";
import { PlasmicSelectControllerType__Overlay } from "./plasmic/astriusdraft/PlasmicSelectControllerType__Overlay";

function SelectControllerType__Overlay_(props, ref) {
  const { plasmicProps } = PlasmicSelectControllerType__Overlay.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectControllerType__Overlay {...plasmicProps} />;
}

const SelectControllerType__Overlay = React.forwardRef(
  SelectControllerType__Overlay_
);

export default Object.assign(SelectControllerType__Overlay, {
  __plumeType: "triggered-overlay"
});
