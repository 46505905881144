// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicApplicationKeys } from "./plasmic/astriusdraft/PlasmicApplicationKeys";
import { useAuth } from "../contexts/AuthContext"
import { db, storage } from "../firebase"
import { collection, addDoc, updateDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore";
import LoadingSpinner from "./LoadingSpinner"; 
import { ToastContainer, toast } from 'react-toastify';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import axios from "axios";
import { config } from "./constants";
import { Button, Modal, Form, Placeholder } from "react-bootstrap"
import DeveloperModal from "./DeveloperModal"
import PlasmicDeveloper2 from "./plasmic/astriusdraft/PlasmicDeveloper2";


function ApplicationKeys_(props, ref) {
  const { currentUser, checkPermission, showToast } = useAuth();
  const [user, setUser] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const  [docId, setDocId] = React.useState(); 
  const [showModal, setShowModal] = React.useState(false)

  const setKeysFromModal = (keys)=>{
  
     setUser({
      ...user,
      user_webHook_client_key : keys.clientKey,
      user_webHook_url : keys.webHookKey,
      user_webHook_client_key_dev : keys.devClientKey,
      user_webHook_url_dev : keys.devWebHookKey,

    })
  
  }
 

  const handleClose = () => setShowModal(false);

const fetchUser = async () => {
try {
  
  const response = await axios({
    method: "POST",
    url: `${config.endpoint}/getUserDecryptedData`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: {
      payload: [ "user_api_key", "user_app_id", "user_webHook_client_key", "user_webHook_client_key_dev" ],
      uid : currentUser.uid
    }
  })
      setDocId(response.data.docId)
      setUser(response.data.user)
       
      setLoading(false)
} catch (error) {
  console.log(error)
}
 
}
  React.useEffect(()=>{
    if (!currentUser) return setLoading(false);
   
    fetchUser();

  },[]);

  const handleUpdateKey=async()=>{


    if(!checkPermission("Regenerate Api Key")){
      showToast(false,"Permission Denied")
      return;
    }

    setLoading(true)

    const hashDigest = sha256(docId);
      const user_api_key = Base64.stringify(hmacSHA512(hashDigest, `@astrius${Math.random()}`));



     await axios({
      method: "POST",
      url: `${config.endpoint}/encryptUserData`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      },
      data: {
        payload:{
          user_api_key : "sk_"+user_api_key,
          user_app_id : docId
        },
        docId
      }
    })
    
    setLoading(false)

    setUser({...user, user_api_key: "sk_"+user_api_key})
    
    toast.success("Api Key is regenerated successfully", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    // console.log();
  }
  // Use PlasmicApplicationKeys to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicApplicationKeys are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ApplicationKeysProps here, but feel free
  // to do whatever works for you.
  return <React.Fragment>
    <ToastContainer />
    {loading? <LoadingSpinner/> :
    <PlasmicApplicationKeys rectangle={{ ref }} {...props}
    appId={{
      value: user?.user_app_id || docId
    }}
    apiKey={{
      value:user?.user_api_key || ""
    }}
    copyKey={{
      onClick: () => {
        navigator.clipboard.writeText(user?.user_api_key);
        toast.success("Api Key copied to clipboard", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }}
    regenerateApiKey={{
      onClick:() => handleUpdateKey()
    }}

    addKeys= {{
      onClick:() => { 

        if(!checkPermission("Add WebHook Keys")){
          showToast(false,"Permission Denied")
          return;
        }

        setShowModal(true)
       },
    }}

    clientKey = {{
      value : user?.user_webHook_client_key || "",
      placeholder : ""
    }}

    webHookKey = {{
      value : user?.user_webHook_url || "",
      placeholder : ""
    }}

    devClientKey = {{
      value : user?.user_webHook_client_key_dev || "",
      placeholder : ""
    }}

    devWebHookKey = {{
      value : user?.user_webHook_url_dev || "",
      placeholder : ""
    }}
     />}

    <Modal
       show={showModal}
       onHide={handleClose}
       backdrop="static"
       aria-labelledby="contained-modal-title-vcenter"
       centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Keys</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeveloperModal 
          setKeysFromModal={setKeysFromModal} 
          docId={docId} 
          showToast={showToast}
          setShowModal = {setShowModal}
           />
        </Modal.Body>

      </Modal>

  </React.Fragment>
}

const ApplicationKeys = React.forwardRef(ApplicationKeys_);

export default ApplicationKeys;
