// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function VisaLogoIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 22 7"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M5.425 6.872H3.547L2.14 1.499c-.067-.247-.209-.466-.418-.569A6.113 6.113 0 000 .362V.155h3.025c.418 0 .731.31.783.671l.73 3.876L6.417.155h1.826L5.425 6.872zm3.86 0H7.513L8.972.155h1.774l-1.46 6.717zm3.756-4.856c.052-.362.365-.569.73-.569.574-.052 1.2.052 1.722.31L15.806.31A4.49 4.49 0 0014.189 0c-1.722 0-2.974.93-2.974 2.222 0 .982.887 1.498 1.514 1.808.677.31.938.517.886.827 0 .465-.522.671-1.043.671a4.473 4.473 0 01-1.826-.413l-.313 1.447a5.117 5.117 0 001.93.362c1.93.05 3.13-.879 3.13-2.274 0-1.757-2.452-1.86-2.452-2.634zM21.7 6.872L20.292.155h-1.513a.785.785 0 00-.73.517l-2.608 6.2h1.825l.365-.982h2.243l.209.982H21.7zm-2.66-4.908l.521 2.531h-1.46l.939-2.531z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default VisaLogoIcon;
/* prettier-ignore-end */
