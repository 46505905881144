// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect } from "react";
import { PlasmicClientsList } from "./plasmic/astriusdraft/PlasmicClientsList";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, useHits } from 'react-instantsearch-hooks-web';
import '@algolia/autocomplete-theme-classic';
import { useAuth } from "../contexts/AuthContext";
import ContractsListCard from "./ContractsListCard";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAt, endAt, startAfter, endBefore, limitToLast } from "firebase/firestore";
import ClientsListCard from "./ClientsListCard";
import { Button, Modal, Form } from "react-bootstrap"
import AddClientModal from "./AddClientModal"
import { v4 } from "uuid";
import Moment from 'moment';
import LoadingSpinner from "./LoadingSpinner";
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";
import axios from "axios";
import { config } from './constants'
import createTableFlowImporter from "@tableflow/js";
import ProgressBarPage from "./ProgressBarPage";

const algoliaClient = algoliasearch('3YSA4177MW', '0019c18519fefc703571e7786ea74dad');


const searchClient = {

  ...algoliaClient,

  search(requests) {


    if (requests.every(({ params }) => !params.query)) {
      // setOpenContractSearch(false)
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }
    // setOpenContractSearch(true)
    return algoliaClient.search(requests);
  },
};


function CustomHits(props) {
  const { hits, results, sendEvent, query } = useHits();
  const { currentUser, clientHits, setClientHits, openClientSearch, setOpenClientSearch } = useAuth()


  if (typeof results.query === 'undefined') {
    setOpenClientSearch(false)
  } else {
    setOpenClientSearch(true)
  }


  setClientHits(hits)



  return (<>
  </>)

}


function ClientsList_(props, ref) {

  const { currentUser, clientHits, setClientHits, openClientSearch, checkPermission, clientSubmission, setClientSubmission, showToast } = useAuth()
  const [clients, setClients] = useState([])
  const [last, setLast] = useState({})
  const [first, setFirst] = useState({})
  const [showPagination, setShowPagination] = useState(false)
  const formatDate = Moment().format("MMM Do, YYYY");
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [clientLoading, setClientLoading] = useState(false)
  const [csvData, setCsvData] = useState([]);
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const handleClose = () => setShowModal(false);


  async function getRandomLetter() {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    return alphabet[randomIndex];
  }
  
  async function generateInvoiceIdentifier(inputString) {
    // Generate a random letter
    const randomLetter = await getRandomLetter();
  
    // Extract the first 7 characters of the input string
    const firstSevenCharacters = inputString.slice(0, 7);
  
    // Capitalize the first 8 characters
    const capitalizedString = firstSevenCharacters.toUpperCase();
  
    // Concatenate the random letter, capitalized part, and the rest of the string
    const finalString = randomLetter + capitalizedString
  
    return finalString;
  }



  async function modalSubmit() {

    if (clientSubmission.contactName === '') {
      alert("Contact name is required")
    } else if (clientSubmission.email === '') {
      alert("Email is required")
    } else if (clientSubmission.company === '') {
      alert("Company name is required")
    } else {


      var clientName = ''

      if (clientSubmission.company === '') {
        clientName = clientSubmission.contactName
      } else {
        clientName = clientSubmission.company
      }


      setShowModal(false);

      setClientLoading(true)

      var docId = v4()
      const clientId = v4();


      const params = {
        customer: {
          display_id: clientId,
          display_name: clientSubmission.contactName,
          company_name: clientSubmission.company,
          title: null,
          first_name: clientSubmission.contactName.split(" ")[0],
          middle_name: null,
          last_name:  clientSubmission.contactName.split(" ")[1] ? clientSubmission.contactName.split(" ")[1] : null,
          suffix: null,
          individual: null,
          project: null,
          addresses: [
            {
              id: null,
              type: 'primary',
              string: null,
              name: null,
              line1: null,
              line2: null,
              line3: null,
              line4: null,
              street_number: null,
              city: null,
              state: null,
              postal_code: null,
              country: null,
              latitude: null,
              longitude: null,
              county: null,
              contact_name: null,
              salutation: null,
              phone_number: null,
              fax: null,
              email: null,
              website: null,
              row_version: null
            }
          ],
          phone_numbers: [
            {
              id: null,
              country_code: null,
              area_code: null,
              number: '111-111-1111',
              extension: null,
              type: 'primary'
            }
          ],
          emails: [
            {
              id: '',
              email: clientSubmission.email,
              type: 'primary'
            }
          ],
          websites: [
            {
              id: null,
              url: 'http://example.com',
              type: 'primary'
            }
          ],
          bank_accounts: [
            {
              account_number: '123465',
              account_name: 'SPACEX LLC',
              account_type: 'credit_card',
              iban: 'CH2989144532982975332',
              bic: 'AUDSCHGGXXX',
              bsb_number: '062-001',
              branch_identifier: '001',
              bank_code: 'BNH',
              currency: 'USD'
            }
          ],
          notes: null,
          tax_rate: {
            id: null
          },
          tax_number: null,
          currency: "USD",
          account: null,
          parent: null,
          status: "active",
          row_version: null
        }
      }

      const response = await axios({
        method: "POST",
        url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=createCustomer`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: params
      }).catch(err => {
        if(err.response.status == 403){
          alert(err.response.data.message)
        }
        return
      });

    

      await setDoc(doc(db, "clients", docId), {
        company: clientName,
        contactName: clientSubmission.contactName,
        email: clientSubmission.email,
        notes: "",
        date: serverTimestamp(),
        user: currentUser.uid,
        clientId: clientId,
        displayDate: formatDate,
        amountSpent: 0,
        docId: docId,
        url: "NA",
        api_deck_clientId: response ? response.data.id : null,
        invoiceNum: 1,
        invoice_identifier: (clientSubmission.invoiceId === "" || clientSubmission.invoiceId === " ") ? await generateInvoiceIdentifier(clientId) : clientSubmission.invoiceId.slice(0, 8).toUpperCase()
      })

      clientSubmission.invoiceId = ""

      setClientLoading(false)

    }
  }


  
  useEffect(() => {

    const q = query(collection(db, "clients"), where("user", "==", currentUser.uid), orderBy("date", "desc"))
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempDocs = [];
      querySnapshot.forEach((doc) => {
        tempDocs.push(doc)
      });
    
   if (tempDocs.length > 10){
     setShowPagination(true)
   }
    
     
  
    });
   
  
  
  }, [])




  useEffect(() => {

    const q = query(collection(db, "clients"), where("user", "==", currentUser.uid), orderBy("date", "desc"), limit(10))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      const tempDocs = []
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
        tempDocs.push(doc)
      });


      setClients(tempData)

      setLast(tempDocs[tempDocs.length - 1])
      setFirst(tempDocs[0])

      setLoading(false)


    });



  }, [])



  /// function for next page pagination
  function nextPage() {

    // if (contracts.length == 11){

    const q = query(collection(db, "clients"), where("user", "==", currentUser.uid), orderBy("date", "desc"), startAfter(last), limit(10))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      const tempDocs = []
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
        tempDocs.push(doc)
      });

      if (tempDocs.length > 0) {
        setLast(tempDocs[tempDocs.length - 1])
        setFirst(tempDocs[0])
      }



      setClients(tempData)

    });


  }
  /// function for last page pagination

  function prevPage() {

    const q = query(collection(db, "clients"), where("user", "==", currentUser.uid), orderBy("date", "desc"), endBefore(first), limitToLast(10))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      const tempDocs = []
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
        tempDocs.push(doc)
      });

      if (tempDocs.length > 0) {
        setLast(tempDocs[tempDocs.length - 1])
        setFirst(tempDocs[0])
      }

      setClients(tempData)

    });

  }

  async function handleFileChange (event) {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const contents = e.target.result;
        const rows = contents.split('\n');
        const headers = rows[0].split(',');

        const data = [];

        for (let i = 1; i < rows.length; i++) {
          const rowData = rows[i].split(',');
          if (rowData.length === headers.length) {
            const rowObject = {};
            for (let j = 0; j < headers.length; j++) {
              rowObject[headers[j].trim()] = rowData[j].trim();
            }
            data.push(rowObject);
          }
        }

        setCsvData(data);

        console.log(data)

        // for (var i = 0; i < data.length; i++) {

        //   // set client submission to the current one
        //    await setClientSubmission({ company: "", contactName: "", email: "", invoiceId: "" })
        //   await modalSubmit()

        // }

      };

      reader.readAsText(selectedFile);
      setFile(selectedFile);


    }
  };




  const importer = createTableFlowImporter({
    importerId: "4ccb7239-5c51-470c-a1f3-8cb440a2ec51", // Use your importer ID from https://app.tableflow.com/importers
    modalOnCloseTriggered: () => importer.close(),
    onComplete: async (data, error) => {

      console.log(data) 

        var clients = data.rows
        
        importer.close()

        setBulkLoading(true)
        setClientLoading(true)


        for (var i = 0; i < clients.length; i++) {
    
          setProgress(i)

          var clientSubmission = { company: clients[i].values.company_name, contactName: clients[i].values.contact_name, email: clients[i].values.email, invoiceId: clients[i].values.custom_invoice_id ? clients[i].values.custom_invoice_id : ""}
         
          var docId = v4()
          var clientId = v4();

    
          const params = {
            customer: {
              display_id: clientId,
              display_name: clientSubmission.contactName,
              company_name: clientSubmission.company,
              title: null,
              first_name: clientSubmission.contactName.split(" ")[0],
              middle_name: null,
              last_name:  clientSubmission.contactName.split(" ")[1] ? clientSubmission.contactName.split(" ")[1] : null,
              suffix: null,
              individual: null,
              project: null,
              addresses: [
                {
                  id: null,
                  type: 'primary',
                  string: null,
                  name: null,
                  line1: null,
                  line2: null,
                  line3: null,
                  line4: null,
                  street_number: null,
                  city: null,
                  state: null,
                  postal_code: null,
                  country: null,
                  latitude: null,
                  longitude: null,
                  county: null,
                  contact_name: null,
                  salutation: null,
                  phone_number: null,
                  fax: null,
                  email: null,
                  website: null,
                  row_version: null
                }
              ],
              phone_numbers: [
                {
                  id: null,
                  country_code: null,
                  area_code: null,
                  number: '111-111-1111',
                  extension: null,
                  type: 'primary'
                }
              ],
              emails: [
                {
                  id: '',
                  email: clientSubmission.email,
                  type: 'primary'
                }
              ],
              websites: [
                {
                  id: null,
                  url: 'http://example.com',
                  type: 'primary'
                }
              ],
              bank_accounts: [
                {
                  account_number: '123465',
                  account_name: 'SPACEX LLC',
                  account_type: 'credit_card',
                  iban: 'CH2989144532982975332',
                  bic: 'AUDSCHGGXXX',
                  bsb_number: '062-001',
                  branch_identifier: '001',
                  bank_code: 'BNH',
                  currency: 'USD'
                }
              ],
              notes: null,
              tax_rate: {
                id: null
              },
              tax_number: null,
              currency: "USD",
              account: null,
              parent: null,
              status: "active",
              row_version: null
            }
          }
    
          const response = await axios({
            method: "POST",
            url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=createCustomer`,
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${await currentUser.getIdToken()}`
            },
            data: params
          }).catch(err => {
            if(err.response.status == 403){
              alert(err.response.data.message)
            }
            return
          });
         

          await setDoc(doc(db, "clients", docId), {
            company: clientSubmission.company,
            contactName: clientSubmission.contactName,
            email: clientSubmission.email,
            notes: "",
            date: serverTimestamp(),
            user: currentUser.uid,
            clientId: clientId,
            displayDate: formatDate,
            amountSpent: 0,
            docId: docId,
            url: "NA",
            api_deck_clientId: response ? response.data.id : null,
            invoiceNum: 1,
            invoice_identifier: (clientSubmission.invoiceId === "" || clientSubmission.invoiceId === " ") ? await generateInvoiceIdentifier(clientId) : clientSubmission.invoiceId.slice(0, 8).toUpperCase()
          })
    
        }

        setBulkLoading(false)

    }
    ,
    darkMode: false,
  });



  return (
    <>
      {bulkLoading ? <ProgressBarPage completed={progress} /> : clientLoading ? <LoadingSpinnerWithText text={"Creating Client..."} /> :
        <PlasmicClientsList root={{ ref }} {...props}
          addClientButton={{
            onClick: () => {

              if(!checkPermission("Create Client")) {
                showToast(false,"Permission Denied")
                return;
              }
              setShowModal(true)
            }
          }}
          uploadButton={{
            onClick: () => {

              if (!checkPermission("Create Client")) {
                 showToast(false,"Permission Denied")
                 return;
              }
    
              // var input = document.createElement('input')
              // input.type = 'file';

              // input.onchange = e => {
              //   handleFileChange(e)
              // }

              // input.click();

              importer?.showModal();


            }
          }}
          searchVertStack={{
            children:
              <>
                <InstantSearch searchClient={searchClient} indexName="Firebase_Client_Collection"  >
                  <Configure />
                  <SearchBox className="searchbox" placeholder="Search..." />
                  <CustomHits />
                </InstantSearch>
              </>
          }}
          clientsListStack={{
            children:
              <>
                {openClientSearch ? clientHits.map(x => {
                  if (x.user == currentUser.uid) {
                    return <ClientsListCard key={x.clientId} clientName={x.company} clientEmail={x.email} date={x.displayDate} id={x.clientId} />
                  }

                }) : clients.map(x => {
                  return <ClientsListCard key={x.clientId} clientName={x.company} clientEmail={x.email} date={x.displayDate} id={x.clientId} />
                })}
              </>
          }}
          paginationStack={{
            wrap: node => showPagination ? node : null
          }}
          nextButton={{
            onClick: () => nextPage()
          }}
          prevButton={{
            onClick: () => prevPage()
          }}
          vertStack={loading && {
            children: <LoadingSpinner />
          }}
        />}
      <Modal show={showModal} onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddClientModal />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={modalSubmit}>
            Add client
          </Button>
        </Modal.Footer>
      </Modal>
    </>)
}

const ClientsList = React.forwardRef(ClientsList_);

export default ClientsList;