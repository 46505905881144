// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function AmexIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 25 7"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M2.818 0L0 6.419h3.373l.418-1.024h.956l.418 1.024h3.713v-.782l.33.782h1.921l.331-.798v.798h7.721l.94-.997.879.997 3.965.008-2.826-3.2L24.965 0h-3.904l-.914.978L19.296 0h-8.4l-.722 1.657L9.436 0H6.07v.755L5.696 0H2.818zm11.53.911h4.434l1.356 1.508 1.4-1.508h1.356l-2.06 2.315 2.06 2.288h-1.417L20.12 3.99l-1.407 1.525h-4.365V.911zm1.095 1.795v-.842h2.767l1.207 1.345-1.26 1.352h-2.714v-.918h2.419v-.937h-2.419zM3.47.91h1.644l1.87 4.353V.91h1.8l1.444 3.121 1.33-3.12h1.792v4.605h-1.09l-.009-3.609-1.59 3.61h-.975l-1.599-3.61v3.61H5.844l-.426-1.033H3.121l-.425 1.031H1.494L3.47.911zm.043 2.62l.757-1.84.756 1.84H3.513z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default AmexIcon;
/* prettier-ignore-end */
