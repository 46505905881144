import * as React from "react";
import { PlasmicSubscriptionSelect } from "./plasmic/astriusdraft/PlasmicSubscriptionSelect";
import SubscriptionSelect__Option from "./SubscriptionSelect__Option";
import SubscriptionSelect__OptionGroup from "./SubscriptionSelect__OptionGroup";

function SubscriptionSelect_(props, ref) {
  const { plasmicProps, state } = PlasmicSubscriptionSelect.useBehavior(
    props,
    ref
  );

  return <PlasmicSubscriptionSelect {...plasmicProps} />;
}

const SubscriptionSelect = React.forwardRef(SubscriptionSelect_);

export default Object.assign(SubscriptionSelect, {
  Option: SubscriptionSelect__Option,
  OptionGroup: SubscriptionSelect__OptionGroup,
  __plumeType: "select"
});
