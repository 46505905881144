// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect, useRef} from "react";
import { PlasmicInvoiceFields } from "./plasmic/astriusdraft/PlasmicInvoiceFields";
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-datepicker";
import { useAuth } from "../contexts/AuthContext";
import "react-datepicker/dist/react-datepicker.css";
import "../Input.css"
import { setPlumeStrictMode } from "@plasmicapp/react-web";


function InvoiceFields_(props, ref) {

const {invoiceFields, setInvoiceFields, invoiceBalance, setInvoiceBalance} = useAuth()
const [item, setItem] = useState('')
const [quantity, setQuantity] = useState()
const [price, setPrice] = useState()

const currencyIn = useRef(null)


useEffect(() => {



  for (var i = 0; i < invoiceFields.length; i++){
    
    if (invoiceFields[i].id === props.id){
      setItem(invoiceFields[i].item)

      if (invoiceFields[i].quantity !== 0){
        setQuantity(invoiceFields[i].quantity)


      }
      
      if (invoiceFields[i].price !== ''){
        setPrice(Number(invoiceFields[i].price.replace(/[^0-9\.-]+/g,"")))
      }
      

      break;
    }
    
    }


}, [])




function updateItem(){
  
  var temp = invoiceFields
  
  for (var i = 0; i < temp.length; i++){
    
  if (temp[i].id === props.id){
    temp[i].item = item
    break;
  }
  
  }
  
  setInvoiceFields(temp)
 
  
  }


  function updateAmount(value) {
  
    var temp = invoiceFields
    
    for (var i = 0; i < temp.length; i++){
      
    if (temp[i].id === props.id){
      // Todo: add in code that gets rid of the "$ " before the number //
      temp[i].price = value
      temp[i].balanceDue = Number(temp[i].price.replace(/[^0-9\.-]+/g,"")) * temp[i].quantity
     
      break;
    }
    
    }
    
    setInvoiceFields(temp)

    updateTotalBalance()

    
    }


    function updateQuantity(){
  
      var temp = invoiceFields
      
      for (var i = 0; i < temp.length; i++){
        
      if (temp[i].id === props.id){
        temp[i].quantity = quantity
        temp[i].balanceDue = Number(temp[i].price.replace(/[^0-9\.-]+/g,"")) * temp[i].quantity
        break;
      }
      
      }
      
      setInvoiceFields(temp)
     
      updateTotalBalance()
      
      }


      function updateTotalBalance(){

        var temp = 0

        for (var i = 0; i < invoiceFields.length; i++) {
          temp += invoiceFields[i].balanceDue
        }

        setInvoiceBalance(temp)

      }


      function decrementTotalBalance(){

        var temp = invoiceFields

        for (var i = 0; i < temp.length; i++){
        
          

          if (temp[i].id === props.id){
            setInvoiceBalance(invoiceBalance - temp[i].balanceDue)
          }
          
          }

      }



  return <PlasmicInvoiceFields root={{ ref }} {...props}
  item={{
    value: item,
    placeholder: "Describe your service...",
    onChange: (e) => {
      setItem(e.target.value)
    },
    onBlur: (e) => {
      updateItem()
    }
  }}
  quantity={{
    value: quantity,
    onChange: (e) => {
      setQuantity(e.target.value)
    },
    onBlur: (e) => {
      updateQuantity()
    }
  }}
  pricing={{
    children: <CurrencyInput className="input2"
    ref={currencyIn}
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    value={price}
    placeholder="$ "
    onValueChange={(e) => {
      setPrice(e)
    }}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
    onBlur={(e) => {
      updateAmount(e.target.value)
    }}
    />
  }}
  deleteField={{
    wrap: node => props.number === "1." ? null : node,
    props: {
      onClick: () => {
        decrementTotalBalance()
        setInvoiceFields(invoiceFields.filter((x) => x.id !== props.id))
      }
    }
  }}
  />;
}

const InvoiceFields = React.forwardRef(InvoiceFields_);

export default InvoiceFields;
