// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useRef, useEffect } from "react";
import { PlasmicEscrowSubmission } from "./plasmic/astriusdraft/PlasmicEscrowSubmission";
import "../Input.css"
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MilestoneEntry from "./MilestoneEntry";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, listAll, list, } from "firebase/storage";
import { v4 } from "uuid";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, useHits } from 'react-instantsearch-hooks-web';
import SearchResults from "./SearchResults"
import AddNewClientThroughMenu from "./AddNewClientThroughMenu";
import { Button, Modal, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import 'bootstrap/dist/css/bootstrap.min.css';
import AddClientModal from "./AddClientModal"
import { Link, useHistory } from "react-router-dom"
import Moment from 'moment';
import ClickAwayListener from 'react-click-away-listener';
import axios from "axios"
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";
import { config } from './constants'

const algoliaClient = algoliasearch('3YSA4177MW', '0019c18519fefc703571e7786ea74dad');


const searchClient = {

  ...algoliaClient,

  search(requests) {


    if (requests.every(({ params }) => !params.query)) {
      // setOpenContractSearch(false)
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }
    // setOpenContractSearch(true)
    return algoliaClient.search(requests);
  },
};


function CustomHits(props) {
  const { handleEscrowSubmit, milestones, handleSetMilestones, globalHits, setGlobalHits, escrowSubmitParams, setOpenClientSearch } = useAuth()
  const { hits, results, sendEvent } = useHits();

  setGlobalHits(hits)

  if (typeof results.query === 'undefined') {
    setOpenClientSearch(false)
  } else {
    setOpenClientSearch(true)
  }

  return (<>
  </>)
}



function EscrowSubmission_(props, ref1) {

  const [titleContent, setTitleContent] = useState('')
  const [priceContent, setPriceContent] = useState('')
  const [value, setValue] = useState(0);
  const [showMilestone, setShowMilestone] = useState(true)
  const [startDate, setStartDate] = useState('');
  const [loading, setLoading] = useState(false)
  const [number, setNumber] = useState(1)
  const [currentUpload, setCurrentUpload] = useState(null);
  const [contractDescription, setContractDescription] = useState('')
  const [advancedOptions, setAdvancedOptions] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState({ ACH: true, Card: false, Wire: true, CardSurcharge: true })
  const [recurringEscrow, setRecurringEscrow] = useState(false)
  const [paymentReminders, setPaymentReminders] = useState(false)
  const [recurringNumber, setRecurringNumber] = useState('')
  const [reminderNumber, setReminderNumber] = useState('')
  const [automationOptions, setAutomationOptions] = useState({ enabled: false, number: 0, unit: '' })
  const [reminderOptions, setReminderOptions] = useState({ enabled: false, number: 0, unit: '' })
  const [openSearchVertStack, setOpenSearchVertStack] = useState(false)
  const [achSwitch, setAchSwitch] = useState(true)
  const [cardSwitch, setCardSwitch] = useState(false)
  const [cardSurchargeSwitch, setCardSurchargeSwitch] = useState(true)
  const [error, setError] = useState(false)
  const [clientsList, setClientsList] = useState([])

  const [showSearch, setShowSearch] = useState(true)
  const [companySelect, setCompanySelect] = useState('')
  const [emailSelect, setEmailSelect] = useState('')
  const [client, setClient] = useState({})
  const [showModal, setShowModal] = useState(false)

  const formatDate = Moment().format("MMM Do, YYYY");


  const [cardApproved, setCardApproved] = useState(true)


  const { handleEscrowSubmit, milestones, handleSetMilestones, globalHits, setGlobalHits, clientSubmission, showToast, currentUser, escrowSubmitParams, checkPermission, openClientSearch, setOpenClientSearch } = useAuth()
  const currencyInput = useRef(null)
  const ref2 = useRef(null)
  const history = useHistory()


  useEffect(() => {


    if (Object.keys(escrowSubmitParams).length) {


      setShowSearch(false)
      setClient(escrowSubmitParams.client)
      setCompanySelect(client.company)
      setEmailSelect(client.email)
      setTitleContent(escrowSubmitParams.title)
      setContractDescription(escrowSubmitParams.contractDescription)

      if (escrowSubmitParams.upload !== null) {
        setCurrentUpload(escrowSubmitParams.upload)
      }

      setPaymentMethods(escrowSubmitParams.paymentMethods)
      setAchSwitch(escrowSubmitParams.paymentMethods.ACH)
      setCardSwitch(escrowSubmitParams.paymentMethods.Card)
      setCardSurchargeSwitch(escrowSubmitParams.paymentMethods.CardSurcharge)

      setPaymentReminders(escrowSubmitParams.reminderOptions.enabled)
      setReminderNumber(escrowSubmitParams.reminderOptions.number)
      setReminderOptions(escrowSubmitParams.reminderOptions)

    }




  }, [])


  useEffect(() => {

    const q = query(collection(db, "clients"), where("user", "==", currentUser.uid), orderBy("date", "desc"), limit(5))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
      });

      setClientsList(tempData)

    });


  }, [])



  const handleClose = () => setShowModal(false);

  async function modalSubmit() {

    setOpenClientSearch(false)

    if (clientSubmission.contactName === '') {
      alert("Contact name is required")
    } else if (clientSubmission.email === '') {
      alert("Email is required")
    } else if (clientSubmission.company === '') {
      alert("Company name is required")
    } else {


      var clientName = ''

      if (clientSubmission.company === '') {
        clientName = clientSubmission.contactName
      } else {
        clientName = clientSubmission.company
      }


      setShowModal(false);

      setLoading(true)

      var docId = v4()
      const clientId = v4();

      var payload = {
        firstName: clientSubmission.contactName,
        lastName: clientName,
        email: v4() + clientSubmission.email,
        ipAddress: "99.99.99.99",
      }


      const params = {
        customer: {
          display_id: clientId,
          display_name: clientSubmission.contactName,
          company_name: clientSubmission.company,
          title: null,
          first_name: clientSubmission.contactName.split(" ")[0],
          middle_name: null,
          last_name:  clientSubmission.contactName.split(" ")[1] ? clientSubmission.contactName.split(" ")[1] : null,
          suffix: null,
          individual: null,
          project: null,
          addresses: [
            {
              id: null,
              type: 'primary',
              string: null,
              name: null,
              line1: null,
              line2: null,
              line3: null,
              line4: null,
              street_number: null,
              city: null,
              state: null,
              postal_code: null,
              country: null,
              latitude: null,
              longitude: null,
              county: null,
              contact_name: null,
              salutation: null,
              phone_number: null,
              fax: null,
              email: null,
              website: null,
              row_version: null
            }
          ],
          phone_numbers: [
            {
              id: null,
              country_code: null,
              area_code: null,
              number: '111-111-1111',
              extension: null,
              type: 'primary'
            }
          ],
          emails: [
            {
              id: '',
              email: clientSubmission.email,
              type: 'primary'
            }
          ],
          websites: [
            {
              id: null,
              url: 'http://example.com',
              type: 'primary'
            }
          ],
          bank_accounts: [
            {
              account_number: '123465',
              account_name: 'SPACEX LLC',
              account_type: 'credit_card',
              iban: 'CH2989144532982975332',
              bic: 'AUDSCHGGXXX',
              bsb_number: '062-001',
              branch_identifier: '001',
              bank_code: 'BNH',
              currency: 'USD'
            }
          ],
          notes: null,
          tax_rate: {
            id: null
          },
          tax_number: null,
          currency: "USD",
          account: null,
          parent: null,
          status: "active",
          row_version: null
        }
      }

      const response = await axios({
        method: "POST",
        url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=createCustomer`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: params
      }).catch(err => {
        if(err.response.status == 403){
          alert(err.response.data.message)
        }
        return
      });

      

      setCompanySelect(clientSubmission.company)
      setEmailSelect(clientSubmission.email)
      setClient({ company: clientSubmission.company, email: clientSubmission.email, contactName: clientSubmission.contactName, notes: "", date: serverTimestamp(), docId: docId, url: "NA", api_deck_clientId: response ? response.data.id : null })

      setShowSearch(false)
      setGlobalHits([])
      setOpenSearchVertStack(false)

      function getRandomLetter() {
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const randomIndex = Math.floor(Math.random() * alphabet.length);
        return alphabet[randomIndex];
      }
      
      function generateInvoiceIdentifier(inputString) {
        // Generate a random letter
        const randomLetter = getRandomLetter();
      
        // Extract the first 7 characters of the input string
        const firstSevenCharacters = inputString.slice(0, 7);
      
        // Capitalize the first 8 characters
        const capitalizedString = firstSevenCharacters.toUpperCase();
      
        // Concatenate the random letter, capitalized part, and the rest of the string
        const finalString = randomLetter + capitalizedString
      
        return finalString;
      }

      await setDoc(doc(db, "clients", docId), {
        company: clientName,
        contactName: clientSubmission.contactName,
        email: clientSubmission.email,
        notes: "",
        date: serverTimestamp(),
        user: currentUser.uid,
        clientId: clientId,
        displayDate: formatDate,
        amountSpent: 0,
        docId: docId,
        url: "NA",
        api_deck_clientId: response ? response.data.id : null,
        invoiceNum: 1,
        invoice_identifier: (clientSubmission.invoiceId === "" || clientSubmission.invoiceId === " ") ? generateInvoiceIdentifier(clientId) : clientSubmission.invoiceId.slice(0, 8).toUpperCase()
      })

      clientSubmission.invoiceId = ""

      setLoading(false)

    }
  }


  function handleSearchResultsClick(client) {
    setCompanySelect(client.company)
    setEmailSelect(client.email)
    console.log(client)

    setClient({ company: client.company, email: client.email, contactName: client.contactName, notes: client.notes, date: client.date, docId: client.docId, url: client.url, api_deck_clientId: client.api_deck_clientId })

    setShowSearch(false)
    setGlobalHits([])
    setOpenSearchVertStack(false)
  }

  function handleAddClient() {

    if(!checkPermission("Create Client")) {
      showToast(false,"Permission Denied")
      return;
    }

    setOpenSearchVertStack(false)
    setShowModal(true)
  }

  const onClickAway = () => {
    setOpenSearchVertStack(false)
  }



  return (
    <>
      {loading ? <LoadingSpinnerWithText text={"Creating Client..."} /> :
        <PlasmicEscrowSubmission root={{ ref1 }} {...props}
          companySelected={companySelect} emailSelected={emailSelect}
          deleteSelection={{
            onClick: () => {
              setShowSearch(true)
            }
          }}
          searchRectangle={{
            root: {
              wrap: node => showSearch ? node : null
            },
            children:
              <>
                {showSearch &&
                  <InstantSearch searchClient={searchClient} indexName="Firebase_Client_Collection"  >
                    <Configure hitsPerPage={5} />
                    <SearchBox
                      placeholder=""
                    />
                    {openSearchVertStack &&
                      <CustomHits />}
                  </InstantSearch>}
              </>,
            onClick: () => {
              setOpenSearchVertStack(true)
            }
          }}
          selectedClient={{
            wrap: node => !showSearch ? node : null
          }}
          ///use this to do it tomorrow morning. And when the values is selected, hide the search and display the value like Stripe does
          //except you then have the ability to 'x' off the value you are hiding 
          menuSelectStack={{
            root: {
              wrap: node => openSearchVertStack ? node : null
            },
            children:
              <ClickAwayListener onClickAway={onClickAway}>
                <>
                  <AddNewClientThroughMenu onClick={handleAddClient} />
                  {openClientSearch ? globalHits.map(x => {

                    if (x.user === currentUser.uid) {
                      return <SearchResults key={v4()} companyName={x.company} emailAddress={x.email} onClick={() => handleSearchResultsClick(x)} />
                    }

                  }) : clientsList.map(x => {

                    return <SearchResults key={v4()} companyName={x.company} emailAddress={x.email} onClick={() => handleSearchResultsClick(x)} />

                  })}
                </>
              </ClickAwayListener>
          }}
          contractTitleInput={{
            value: titleContent,
            placeholder: "Enter here",
            onChange: (e) => {
              setTitleContent(e.target.value)
            }
          }}
          // priceRectangle={{
          //   children: <CurrencyInput ref={currencyInput} 
          //   className="input"
          //   prefix="$"
          //   name="currencyInput"
          //   id="currencyInput"
          //   data-number-to-fixed="2"
          //   data-number-stepfactor="100"
          //   // value={value}
          //   placeholder="$ "
          //   // onChange={handleChange}
          //   // onBlur={handleOnBlur}
          //   allowDecimals
          //   decimalsLimit="2"
          //   onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          //   disableAbbreviations
          // />
          // }}
          // yesMilestoneCheck={{
          //   onChange: (e) => {
          //     // setShowMilestone(!showMilestone)
          //     /// Possibly put setmilestones to have a milestone already and then set show milestones just to true without this button.
          //     if (milestones.length == 0){
          //       handleSetMilestones([{id: new Date().getTime(), description: "", dueDate: "", amount: 0, status: "Awaiting Payment"}])
          //     }
          //   }
          // }}
          // milestoneStack={{
          //   wrap: node => showMilestone ? node : null,
          // }}
          // defaultDateBox={{
          //   children: <DatePicker className="dateBox" selected={startDate} onChange={(date) => setStartDate(date)} />
          // }}
          // defaultDateStack={{
          //   wrap: node => !showMilestone ? node : null,
          // }}
          addMilestoneButton={{
            onClick: () => handleSetMilestones([...milestones, { id: new Date().getTime(), description: "", dueDate: "", amount: '', status: "Upcoming Milestones", paymentId: "" }])
          }}
          moreMilestonesStack={{
            children: milestones.map((x, i) => {
              i += 1
              return <MilestoneEntry key={x.id} id={x.id} number={i + "."} />
            })
          }}
          uploadButton={{
            onClick: () => {
              var input = document.createElement('input');
              input.type = 'file';

              input.onchange = e => {
                setCurrentUpload(e.target.files[0])
              }

              input.click();
            }
          }}
          contractDescription={{
            value: contractDescription,
            onChange: (e) => {
              setContractDescription(e.target.value)
            }
          }}
          advancedOptionsInstance={{
            onClick: () => setAdvancedOptions(!advancedOptions),
            opened: advancedOptions
          }}
          advancedOptionsStack={{
            wrap: node => advancedOptions ? node : null
          }}
          achSwitch={{
            isChecked: achSwitch,
            onChange: (e) => {
              setAchSwitch(e)
              const temp = paymentMethods
              temp.ACH = e
              setPaymentMethods(temp)
            }
          }}
          cardSwitch={{
            isChecked: cardSwitch,
            onChange: (e) => {


              setCardSwitch(e)
              const temp = paymentMethods
              temp.Card = e
              setPaymentMethods(temp)

              if (e && cardSurchargeSwitch) {
                setCardSurchargeSwitch(false)
                const temp = paymentMethods
                temp.CardSurcharge = false
                setPaymentMethods(temp)
              }



            }
          }}
          cardSurchargeSwitch={{
            isChecked: cardSurchargeSwitch,
            onChange: (e) => {


              setCardSurchargeSwitch(e)
              const temp = paymentMethods
              temp.CardSurcharge = e
              setPaymentMethods(temp)

              if (e && cardSwitch) {
                setCardSwitch(false)
                const temp = paymentMethods
                temp.Card = false
                setPaymentMethods(temp)
              }


            }
          }}
          paymentReminderSwitch={{
            isChecked: paymentReminders,
            onChange: (e) => {
              setPaymentReminders(e)
              const temp = reminderOptions
              temp.enabled = !temp.enabled
              setReminderOptions(temp)
            }
          }}
          recurringEscrowStack={{
            wrap: node => recurringEscrow && advancedOptions ? node : null
          }}
          paymentReminderStack={{
            wrap: node => paymentReminders ? node : null
          }}
          paymentReminderStack2={{
            wrap: node => paymentReminders ? node : null
          }}
          paymentReminderText={{
            wrap: node => paymentReminders ? node : null
          }}
          recurringNumber={{
            value: recurringNumber,
            onChange: (e) => {
              setRecurringNumber(e.target.value)
            },
            onBlur: (e) => {
              const temp = automationOptions
              temp.number = e.target.value
              setAdvancedOptions(temp)
            }
          }}
          reminderNumber={{
            value: reminderNumber,
            onChange: (e) => {
              setReminderNumber(e.target.value)
            },
            onBlur: (e) => {
              const temp = reminderOptions
              temp.number = e.target.value
              setReminderOptions(temp)
            }
          }}
          recurringEscrowSelect={{
            onChange: (e) => {
              const temp = automationOptions
              temp.unit = e
              setAdvancedOptions(temp)
            }
          }}
          paymentReminderSelect={{
            onChange: (e) => {
              const temp = reminderOptions
              temp.unit = e
              setReminderOptions(temp)
            }
          }}
          sendButton={{
            onClick: () => {

              var incompleteMilestones = false


              for (var i = 0; i < milestones.length; i++) {

                if (milestones[i].description === '' || milestones[i].amount === '') {
                  incompleteMilestones = true
                  break;
                }

              }


              if (!Object.keys(client).length || titleContent === '' || incompleteMilestones) {

                setError(true)

              } else {
                setError(false)
                handleEscrowSubmit(titleContent, currentUpload, showMilestone, startDate, paymentMethods, automationOptions, reminderOptions, client, contractDescription)
              }


            }
          }}
          errorText={{
            wrap: node => error ? node : null
          }}
          contractUploadText={currentUpload == null ? null : currentUpload.name}
        />}
      <Modal show={showModal} onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddClientModal />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={modalSubmit}>
            Add client
          </Button>
        </Modal.Footer>
      </Modal>
    </>)
}

const EscrowSubmission = React.forwardRef(EscrowSubmission_);


export default EscrowSubmission;