// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect, useCallback } from 'react'
import { PlasmicCurrentInvoicePaymentsPage } from './plasmic/astriusdraft/PlasmicCurrentInvoicePaymentsPage'
import { PlasmicCurrentInvoicePaymentsPage11 } from './plasmic/astriusdraft/PlasmicCurrentInvoicePaymentsPage11'
import { PlasmicCurrentInvoicePaymentsPageAch } from './plasmic/astriusdraft/PlasmicCurrentInvoicePaymentsPageAch'
import { PlasmicCurrentInvoicePaymentsPageCard } from './plasmic/astriusdraft/PlasmicCurrentInvoicePaymentsPageCard'
import {PlasmicCurrentInvoicePaymentsPageSurcharge} from './plasmic/astriusdraft/PlasmicCurrentInvoicePaymentsPageSurcharge'
import PaymentCard from './paymentCard';
import LoadingSpinner from './LoadingSpinner'
import axios from 'axios'
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from 'react-plaid-link'
import { v4 } from 'uuid'
import PaymentSuccess from './PaymentSuccess'
import { useParams } from 'react-router-dom'
import { db, storage } from '../firebase'
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from 'firebase/firestore'
import { format } from 'date-fns'
import InvoiceItem from './InvoiceItem'
import Moment from 'moment'
import PaymentVoided from './PaymentVoided'
import LoadingSpinnerWithText from './LoadingSpinnerWithText'
import { Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../CardModal.css'
import { useAuth } from '../contexts/AuthContext'
import CardModalHeader from './CardModalHeader'
import CardModalFoot from "./CardModalFoot"
import EdgePayInput from './EdgePayInput'
import EdgePayInput1 from './EdgePayInput1'
import { config } from './constants'
import PaymentFraud from "./PaymentFraud"
import { saveAs } from 'file-saver';

// function CardModalHeader1(props, ref) {
//   const {closeCardModal, setCloseCardModal} = useAuth()

//   return <PlasmicCardModalHeader root={{ ref }} {...props} closeButton={{ onClick: () => setCloseCardModal(false)}}/>;

// }



function Link(props, ref) {
  let { paymentId } = useParams()
  const [loading, setLoading] = useState(false)
  const [showSidebar, setShowSideBar] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(
    props.screenData.paymentSuccess,
  )
  const [successDate, setSuccessDate] = useState(props.screenData.datePaid)
  const formatDate = Moment().format('MMM Do, YYYY')
  const [showModal, setShowModal] = useState(false)
  const { closeCardModal, setCloseCardModal,  edgeLoading, setEdgeLoading, paySuccess, setPaySuccess } = useAuth()
  const [achModal, setAchModal] = useState(false)
  const [paymentFraud, setPaymentFraud] = useState(props.screenData.paymentFraud)
  const [sessionId, setSessionId] = useState('')

  const handleClose = () => setCloseCardModal(false)

  const handleAchClose = () => setAchModal(false)

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  function formatInvoiceNum() {

    return props.screenData.invoice_identifier + "-" + String(props.screenData.invoiceNum).padStart(4, '0');


  }



useEffect(() => {

  const session_id = v4()
    
  window.seon.config({
    host: "seondf.com",
    session_id: session_id,
    audio_fingerprint: true,
    canvas_fingerprint: true,
    webgl_fingerprint: true,
    onSuccess: function(message) {
      console.log("success", message);
    },
    onError: function(message) {
      console.log("error", message);
    }
  });

  setSessionId(session_id)

}, [])

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };


async function handleFraud() {

  const response = await axios({
    method: 'POST',
    url: `${config.endpoint}/handleFraud`,
    headers: {
      'authorization': props.screenData.yourToken
    },
    data: {
      paymentId: paymentId,
    },
  })

}

// Create PDF of Inovice for Download

const handleDownloadPDF = async ()=>{
  axios({
   method: "POST",
   url: `${config.endpoint}/createInvoicePDF`,
   headers: {
     "Content-Type": "application/json",
   },
   data: {
     clientEmail: props?.screenData?.clientEmail,
     clientName: props?.screenData?.clientCompany,
     merchantName: props?.screenData?.merchantName,
     amount: props?.screenData?.amount,
     invoiceNum: props?.screenData?.invoiceNum ? props?.screenData?.invoiceNum : props?.screenData?.invoiceNum,
     invoice_identifier: props?.screenData?.invoice_identifier ? props?.screenData?.invoice_identifier : null,
     date: props?.screenData?.date,
     dueDate: props?.screenData?.dueDate,
     products: props?.screenData?.products ? props?.screenData?.products : [],
     invoiceFields: props?.screenData?.invoiceFields,
     userInfoDocId: props?.screenData?.userInfoDocId,
     amountNum: props?.screenData?.amountNum * 100,
     buttonUrl: window.location.href,
   },
 }).then((res)=>{

  // Assuming pdfResponse.attachment.content contains the base64-encoded PDF
  const base64Data = res.data.attachment.content;

  // Decode the base64 data into a binary string
  const binaryString = atob(base64Data);

  // Create an array buffer from the binary string
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the array buffer with the correct MIME type
  const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

  // Trigger the download
  saveAs(blob, res.data.attachment.filename);
  }).catch((err)=>{console.log(err)});
}



/// consider adding a window load event handlering to prevent refresh here
  const onCardSuccess = useCallback( async (resp) => {
    
    var zip = document.getElementById('zip').value
    
    const apiDeckPayment = {
      payment: {
        currency: "USD",
        currency_rate: null,
        total_amount: props.screenData.amountNum,
        reference: null,
        payment_method: "Credit Card",
        payment_method_reference: null,
        payment_method_id: null,
        // accounts_receivable_account_type: "Account",
        // accounts_receivable_account_id: "123456",
        // this needs to be the bank account where the payments are being made to/from
        account: {
            id: props.screenData.api_deck_bank_id
        },
        transaction_date: Date(props.screenData?.date?.nanoseconds),
        customer: {
          id: JSON.parse(props.screenData?.client)?.api_deck_clientId,
          display_name: JSON.parse(props.screenData?.client)?.contactName,
          name: JSON.parse(props.screenData?.client)?.contactName,
        },
        supplier: {
          id: "12345",
          display_name: "Windsurf Shop",
          address: {
            id: "123",
            type: "primary",
            string: "25 Spring Street, Blackburn, VIC 3130",
            name: "HQ US",
            line1: "Main street",
            line2: "apt #",
            line3: "Suite #",
            line4: "delivery instructions",
            street_number: "25",
            city: "San Francisco",
            state: "CA",
            postal_code: "94104",
            country: "US",
            latitude: "40.759211",
            longitude: "-73.984638",
            county: "Santa Clara",
            contact_name: "Elon Musk",
            salutation: "Mr",
            phone_number: "111-111-1111",
            fax: "122-111-1111",
            email: "elon@musk.com",
            website: "https://elonmusk.com",
            row_version: "1-12345",
          },
        },
        reconciled: true,
        status: "paid",
        type: "accounts_receivable",
        allocations: [
          {
            id: props.screenData?.api_deck_invoiceId,
            type: "invoice",
            amount: props.screenData?.amountNum,
          },
        ],
        note: "Some notes about this payment",
        row_version: v4(),
        display_id: v4(),
      },
    };

    setEdgeLoading(true)

    setCloseCardModal(false)

    const ipAddress = await axios.get('https://geolocation-db.com/json/')
  
    var ip = ipAddress.data.IPv4
    
      window.addEventListener("beforeunload", handleBeforeUnload);

      window.seon.getBase64Session(async function(data) {
        if (data) {
          console.log("Session payload", data);
        } else {
          console.log("Failed to retrieve session data.");
        }
      
        console.log(ip)

      // might have to update the processCard function within this
      const payment = await axios({
          method: "POST",
          url: `${config.endpoint}/propayFunctions-handleCardPayment`,
          headers: {
            'authorization': props.screenData.yourToken
          },
          data: {
            ip: ip,
            email: props.screenData.clientEmail,
            email_domain: props.screenData.clientEmail.split('@').pop(),
            session_id: sessionId,
            session: data,
            token: resp.token_value,
            zip: zip,
            amount: props.screenData.amountNum,
            paymentId: paymentId,
            type: "invoice",
            apiDeckPayload:apiDeckPayment,
            user:props.screenData.user
          }
        })

        window.removeEventListener("beforeunload", handleBeforeUnload);

       if (payment.data !== "APPROVE") {
        
        setPaymentFraud(true)
        await handleFraud()
  
      } else {

        setSuccessDate(formatDate)
        setPaymentSuccess(true)

      }

      });

  

  }, [])

  

  const onSuccess = useCallback(async (public_token, metadata) => {
    setLoading(true)
    const apiDeckPayment = {
      payment: {
        currency: "USD",
        currency_rate: null,
        total_amount: props.screenData.amountNum,
        reference: null,
        payment_method: "Credit Card",
        payment_method_reference: null,
        payment_method_id: null,
        // accounts_receivable_account_type: "Account",
        // accounts_receivable_account_id: "123456",
        // this needs to be the bank account where the payments are being made to/from
        account: {
            id: props.screenData.api_deck_bank_id
        },
        transaction_date: Date(props.screenData?.date?.nanoseconds),
        customer: {
          id: JSON.parse(props.screenData?.client)?.api_deck_clientId,
          display_name: JSON.parse(props.screenData?.client)?.contactName,
          name: JSON.parse(props.screenData?.client)?.contactName,
        },
        supplier: {
          id: "12345",
          display_name: "Windsurf Shop",
          address: {
            id: "123",
            type: "primary",
            string: "25 Spring Street, Blackburn, VIC 3130",
            name: "HQ US",
            line1: "Main street",
            line2: "apt #",
            line3: "Suite #",
            line4: "delivery instructions",
            street_number: "25",
            city: "San Francisco",
            state: "CA",
            postal_code: "94104",
            country: "US",
            latitude: "40.759211",
            longitude: "-73.984638",
            county: "Santa Clara",
            contact_name: "Elon Musk",
            salutation: "Mr",
            phone_number: "111-111-1111",
            fax: "122-111-1111",
            email: "elon@musk.com",
            website: "https://elonmusk.com",
            row_version: "1-12345",
          },
        },
        reconciled: true,
        status: "paid",
        type: "accounts_receivable",
        allocations: [
          {
            id: props.screenData?.api_deck_invoiceId,
            type: "invoice",
            amount: props.screenData?.amountNum,
          },
        ],
        note: "Some notes about this payment",
        row_version: v4(),
        display_id: v4(),
      },
    };

    const ipAddress = await axios.get('https://geolocation-db.com/json/')
  
    var ip = ipAddress.data.IPv4


    if (props.screenData?.clientUrl) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      
      window.seon.getBase64Session(async function(data) {
        if (data) {
          console.log("Session payload", data);
        } else {
          console.log("Failed to retrieve session data.");
        }
        

        /// modified function - see if this works

        console.log(ip)

        const payment = await axios({
          method: "POST",
          url: `${config.endpoint}/createTransfer`,
          headers: {
            'authorization': props.screenData.yourToken
          },
          data: {
            ip: ip,
            email: props.screenData.clientEmail,
            email_domain: props.screenData.clientEmail.split('@').pop(),
            session_id: sessionId,
            session: data,
            public_token_object: {
              public_token: public_token,
            },
            account_id: metadata.account_id,
            metadata: metadata,
            customerUrl: props.screenData.clientUrl,
            paymentId: props.screenData.paymentId,
            type: 'invoice',
            apiDeckPayload:apiDeckPayment,
            user: props.screenData.user
          }
        })

      console.log(payment.data)

      window.removeEventListener("beforeunload", handleBeforeUnload);

      if (payment.data !== "APPROVE") {
        setPaymentFraud(true)
  
        await handleFraud()
  
      } else {
        
        setSuccessDate(formatDate)
        setPaymentSuccess(true)

      }


      });

    }

  
  
  }, [])


  const config1 = {
    onSuccess,
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: props.linkToken,
    env: config.version,
  }

  const { open, ready } = usePlaidLink(config1)


  function handleBankTransfer() {
    // add logic here that says if plaid, then open(), else then open the standard ACH modal with Dwolla form

    open()

    // make this look better by customizing the CSS
    // setAchModal(true)
  }


  return (
    <>
      { paymentFraud ? <PaymentFraud/> : paymentSuccess ? (
        <PaymentSuccess
          amount={ (typeof props.screenData.surchargedAmount !== 'undefined' && Number(props.screenData.surchargedAmount) !== props.screenData.amountNum ) ? "$" + props.screenData.surchargedAmount : props.screenData.amountNum.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          }) }
          date={'On ' + successDate}
          screenData={props.screenData}
        />
      ) : 
      edgeLoading ?  <LoadingSpinnerWithText text={'Processing Payment'} /> : loading ? (
        <LoadingSpinnerWithText text={'Processing Payment'} />
      ) : props.screenData.paymentVoided ? (
        <PaymentVoided date={'On ' + props.screenData.voidDate} />
      ) : <PaymentCard pageData = {props.screenData}
       pageType = {props.screenData?.type !== "Invoice Payment" ?
        "" : props.pageType}
       handleBankTransfer = {handleBankTransfer}
       handleModalOpen = { () => setCloseCardModal(true) }
       handleDownloadPDF = {handleDownloadPDF}
       />}
       
      <Modal
        dialogClassName="card-modal"
        show={closeCardModal}
        onHide={handleClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <CardModalHeader color={props?.screenData?.color}/>
          <EdgePayInput1
            screenData={props.screenData}
            type="invoice"
            onCardSuccess={onCardSuccess}
          />
          <CardModalFoot/>
        </Modal.Body>
      </Modal>
    </>
  )
}

function CurrentInvoicePaymentsPage_(props, ref) {
  let { paymentId } = useParams()
  const [loading, setLoading] = useState(true)
  const [screenData, setScreenData] = useState({})
  const [userData, setUserData] = useState({})
  const [linkToken, setLinkToken] = useState(null)
  const [docId, setDocId] = useState('')
  const [pageType, setPageType] = useState('')
  const { currentUser } = useAuth()


  useEffect(async () => {

   
    // combine paymentInfo axios request and generate token into one request. Then test it out.

    const response = await axios({
      method: 'POST',
      url: `${config.endpoint}/paymentInfo`,
      data: {
        paymentId,
        type: 'invoice',
        user: v4()
      }
      })

      console.log(response.data)

      const paymentDoc = response.data.paymentDoc

      setLinkToken(response.data.link_token.link_token)

        if (paymentDoc.paymentMethods.ACH && !(paymentDoc.paymentMethods.Card || paymentDoc.paymentMethods.CardSurcharge)){
          setPageType('ACH')
        } else if ((paymentDoc.paymentMethods.Card || paymentDoc.paymentMethods.CardSurcharge) && !paymentDoc.paymentMethods.ACH){
          setPageType('Card')
        } else if (paymentDoc.paymentMethods.CardSurcharge && paymentDoc.paymentMethods.ACH){
          setPageType('CardSurcharge')
        }

        setLoading(false)
        setScreenData(paymentDoc)
 
     }, [])

  return (
    <>
      {loading || !Object.keys(screenData).length ? (
        <LoadingSpinner />
      ) : linkToken == null ? (
        <PlasmicCurrentInvoicePaymentsPage11 root={{ ref }} {...props} />
      ) : (
        <Link
          linkToken={linkToken}
          screenData={screenData}
          docId={docId}
          pageType={pageType}
        />
      )}
    </>
  )
}

const CurrentInvoicePaymentsPage = React.forwardRef(CurrentInvoicePaymentsPage_)

export default CurrentInvoicePaymentsPage