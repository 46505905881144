import React from "react";
import "../spinner2.css";

export default function LoadingSpinner4() {
  return (
    <div className="spinner4">
        {/* <span>Loading...</span> */}
    <div className="half-spinner4"></div>
    </div>
  );
}