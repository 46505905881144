import { useAuth } from "../contexts/AuthContext";

const AuthTest = () => {
  const { currentUser } = useAuth();

  const makeRequest = async (withAuth) => {
    console.log({ currentUser });
    const authToken = await currentUser.getIdToken();

    const headers = withAuth
      ? {
          Authorization: `Bearer ${authToken}`,
        }
      : {};

    fetch(
      'https://us-central1-astrius-dev.cloudfunctions.net/authenticationRequired',
      {
        headers,
      }
    ).then(async (res) =>
      window.alert(`Got response: ${res.status} - ${await res.text()}`)
    );
  };

  return (
    <>
      <button onClick={() => makeRequest(true)}>Authorized Call</button>
      <button onClick={() => makeRequest(false)}>Unauthorized Call</button>
    </>
  );
};


export default AuthTest;