// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicSearchResults } from "./plasmic/astriusdraft/PlasmicSearchResults";

function SearchResults_(props, ref) {
 
  return <PlasmicSearchResults root={{ ref }} {...props} />;
}

const SearchResults = React.forwardRef(SearchResults_);

export default SearchResults;
