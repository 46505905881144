// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicRolesCard } from "./plasmic/astriusdraft/PlasmicRolesCard";
import EditRoleModal from "./EditRoleModal"
import { Button, Modal } from "antd"
import { useState } from "react";
import PermissionChip from "./PermissionChip";
import "./roleCard.css";


function RolesCard_(props, ref) {

  const [showModal, setShowModal] = useState(false)
  const { role, permissions, showToast } = props;

  const handleClose = () => setShowModal(false)


  return <React.Fragment>
    <PlasmicRolesCard root={{ ref }} {...props}
      editRoleBtn={{
        onClick: () => setShowModal(true)
      }}
      roleName={role.name}
      chipsStack={{
        children: role.permissions.map((p) => {
          return <PermissionChip
            value={p}
            showCloseBtn={false}
          />

        })
      }}
    />


    <Modal
      visible={showModal}
      title={<div className="role-card-title">Edit Role</div>}
      centered
      onCancel={handleClose}
      footer={null}
    >
        <EditRoleModal role={role} permissions={permissions} showToast={showToast} setShowModal={setShowModal} />
    </Modal>

  </React.Fragment>
}

const RolesCard = React.forwardRef(RolesCard_);

export default RolesCard;
