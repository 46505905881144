

import React, {useState, useRef, useEffect} from "react";
import { PlasmicCreateNewElement } from "./plasmic/astriusdraft/PlasmicCreateNewElement";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";

function CreateNewElement_(props, ref) {
  const history = useHistory()
 
  const { showOptions, setShowOptions, checkPermission, showToast } = useAuth()
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  function useOutsideAlerter(ref1, ref2) {
    useEffect(() => {
   
      // Function for click event
      function handleOutsideClick(event) {
        if ((ref1.current && !ref1.current.contains(event.target)) && (ref2.current && !ref2.current.contains(event.target))) {
          setShowOptions(false)
        }
      }
   
      // Adding click event listener
      document.addEventListener("click", handleOutsideClick);
      return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref1, ref2]);
  }

  useOutsideAlerter(ref1, ref2)


  return <PlasmicCreateNewElement root={{ ref
  }} {...props}
  paymentRequestButton={{
    root: {
      ref: ref1
    },
    onClick: () => {

      if(!checkPermission("Create Milestone")){
        showToast(false,"Permission Denied")
        return;
      }
      
      history.push("/contractsubmission")
    }
  }}
  invoiceButton={{
    root: {
      ref: ref2
    },
    onClick: () => { 

      if(!checkPermission("Create Invoice")){
        showToast(false,"Permission Denied")
        return;
      }
      history.push("/invoicesubmission")
    }
  }}
  subscriptionButton={{
    root: {
      ref: ref2
    },
    onClick: () => { 


      if(!checkPermission("Create Invoice")){
        showToast(false,"Permission Denied")
        return;
      }
      history.push("/subscriptionsubmission")
    }
  }}
  />;
}

const CreateNewElement = React.forwardRef(CreateNewElement_);

export default CreateNewElement;
