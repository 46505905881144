import * as React from "react";
import { PlasmicSelectControllerType__Option } from "./plasmic/astriusdraft/PlasmicSelectControllerType__Option";

function SelectControllerType__Option_(props, ref) {
  const { plasmicProps } = PlasmicSelectControllerType__Option.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectControllerType__Option {...plasmicProps} />;
}

const SelectControllerType__Option = React.forwardRef(
  SelectControllerType__Option_
);

export default Object.assign(SelectControllerType__Option, {
  __plumeType: "select-option"
});
