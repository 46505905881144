import * as React from "react";
import { PlasmicSubscriptionSelect__Option } from "./plasmic/astriusdraft/PlasmicSubscriptionSelect__Option";

function SubscriptionSelect__Option_(props, ref) {
  const { plasmicProps } = PlasmicSubscriptionSelect__Option.useBehavior(
    props,
    ref
  );

  return <PlasmicSubscriptionSelect__Option {...plasmicProps} />;
}

const SubscriptionSelect__Option = React.forwardRef(
  SubscriptionSelect__Option_
);

export default Object.assign(SubscriptionSelect__Option, {
  __plumeType: "select-option"
});
