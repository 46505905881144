// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Layer11Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 76 93"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M0 11.52v80.64h63.92V80.64H75.2V0H11.28v11.52H0zm60.16 76.8H3.76V15.36H47V28.8h13.16v59.52zm-9.4-70.245l6.742 6.885H50.76v-6.885zM15.04 3.84h56.4V76.8h-7.52V26.085L49.658 11.52H15.04V3.84z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M52.64 36.48H11.28v3.84h41.36v-3.84zm0 11.52H11.28v3.84h41.36V48zm0 11.52H11.28v3.84h41.36v-3.84zM28.2 74.88H11.28v3.84H28.2v-3.84zm24.44 0H35.72v3.84h16.92v-3.84zM39.48 24.96H24.44v3.84h15.04v-3.84z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Layer11Icon;
/* prettier-ignore-end */
