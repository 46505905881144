import * as React from "react";
import { PlasmicSelectBizStructure__Overlay } from "./plasmic/astriusdraft/PlasmicSelectBizStructure__Overlay";

function SelectBizStructure__Overlay_(props, ref) {
  const { plasmicProps } = PlasmicSelectBizStructure__Overlay.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectBizStructure__Overlay {...plasmicProps} />;
}

const SelectBizStructure__Overlay = React.forwardRef(
  SelectBizStructure__Overlay_
);

export default Object.assign(SelectBizStructure__Overlay, {
  __plumeType: "triggered-overlay"
});
