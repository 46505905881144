const ClientIcon = require("./clientIcon.png");
const CreditCard = require("./creditCard.png");
const Bank = require("./bank.png");
const Lock = require("./lock.png");
const Download = require("./download.png");
const CreditCardBlue = require("./creditCardBlue.png");
const BankBlue = require("./bankBlue.png");
const BackIcon = require("./back.png");

export {
  ClientIcon,
  CreditCard,
  Bank,
  Lock,
  Download,
  CreditCardBlue,
  BankBlue,
  BackIcon,
};
