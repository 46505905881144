import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: 'AIzaSyBNvCV7bQ0gIS0AogfA5E9G1v7fysMFJKI',
  authDomain: 'astrius-dev.firebaseapp.com',
  projectId: 'astrius-dev',
  storageBucket: 'astrius-dev.appspot.com',
  messagingSenderId: '102859607906',
  appId: '1:102859607906:web:8965076e39ab50a1f8e63b',
  measurementId: 'G-QX1N0C7F4D'
};

const app = initializeApp(firebaseConfig);



export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;

