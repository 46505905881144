import React from "react";
import "../spinner.css";

export default function LoadingSpinner(props) {
  return (
    <div className="spinner" style={props.styles}>
        {/* <span>Loading...</span> */}
    <div className="half-spinner"></div>
    </div>
  );
}