// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React from "react";
import { PlasmicGetStarted } from "./plasmic/astriusdraft/PlasmicGetStarted";
import {useHistory} from "react-router-dom"
import { useAuth } from '../contexts/AuthContext';

function GetStarted_(props, ref) {
 const history = useHistory()
 const { isVerified, currentUser } = useAuth();

  return <PlasmicGetStarted root={{ ref }} {...props} nextButton={{
    onClick: () => history.push("/onboarding")
  }} />;
}

const GetStarted = React.forwardRef(GetStarted_);

export default GetStarted;
