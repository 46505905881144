// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicReviewTransactionsCard } from "./plasmic/astriusdraft/PlasmicReviewTransactionsCard";
import { useHistory } from 'react-router-dom';

function ReviewTransactionsCard_(props, ref) {
  const history = useHistory()

  return <PlasmicReviewTransactionsCard root={{ ref,
    onClick: () => history.push(`reviewtransactionsinstance/${props.id}`)
  }} {...props} />;
}

const ReviewTransactionsCard = React.forwardRef(ReviewTransactionsCard_);

export default ReviewTransactionsCard;
