import * as React from "react";
import { PlasmicSelectControllerType } from "./plasmic/astriusdraft/PlasmicSelectControllerType";
import Option from "./SelectControllerType__Option";
import OptionGroup from "./SelectControllerType__OptionGroup";

function SelectControllerType_(props, ref) {
  const { plasmicProps, state } = PlasmicSelectControllerType.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectControllerType {...plasmicProps} />;
}

const SelectControllerType = React.forwardRef(SelectControllerType_);

export default Object.assign(SelectControllerType, {
  Option,
  OptionGroup,
  __plumeType: "select"
});
