import React, { useRef, useState } from 'react';
import {
  BasisTheoryProvider,
  CardNumberElement,
  CardExpirationDateElement,
  CardVerificationCodeElement,
  useBasisTheory,
} from '@basis-theory/basis-theory-react';


export default function NewCardForm() {

    const { bt } = useBasisTheory('key_AHdPZeeRstPHkDTU2PFKg6', { elements: true });
    // Refs to get access to the Elements instance
    const cardNumberRef = useRef(null);
    const cardExpirationRef = useRef(null);
    const cardVerificationRef = useRef(null);
  
    // stores the current card brand in state, to pass to CardVerificationCodeElement
    const [cardBrand, setCardBrand] = useState();

    const submit = async () => {
        try {
          const token = await bt?.tokens.create({
            type: 'card',
            data: {
              number: cardNumberRef.current,
              expiration_month: cardExpirationRef.current.month(),
              expiration_year: cardExpirationRef.current.year(),
              cvc: cardVerificationRef.current,
            }
          });
          // store token.id in your database
        } catch (error) {
          console.error(error);
        }
      }


  return (
    <BasisTheoryProvider bt={bt}>
    <CardNumberElement
      id="myCardNumber"
      ref={cardNumberRef}
      onChange={({ cardBrand }) => setCardBrand(cardBrand)}
    />
    <div style={{ display: 'flex' }}>
      <div style={{ width: "100%" }}>
        <CardExpirationDateElement
          id="myCardExpiration"
          ref={cardExpirationRef}
        />
      </div>
      <div style={{ width: "100%" }}>
        <CardVerificationCodeElement
          id="myCardVerification"
          ref={cardVerificationRef}
          cardBrand={cardBrand}
        />
      </div>
    </div>
    <button onClick={submit}>Submit</button>
  </BasisTheoryProvider>
  )
}
