// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicOnboardingBusinessDetailsRetryManual } from "./plasmic/astriusdraft/PlasmicOnboardingBusinessDetailsRetryManual";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import {useAuth} from "../contexts/AuthContext"
import "./Onboarding.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {updateDoc, doc} from "firebase/firestore"; 
import { db } from "../firebase"
import LoadingSpinner from "./LoadingSpinner";
import { config } from './constants'
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";


function OnboardingBusinessDetailsRetryManual_(props, ref) {
  const history = useHistory()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [legalBusinessName, setLegalBusinessName] = useState('')
  const [displayBusinessName, setDisplayBusinessName] = useState('')
  const [ein, setEin] = useState("")

  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')

  const [paddress1, setpAddress1] = useState('')
  const [pcity, setpCity] = useState('')
  const [pstate, setpState] = useState('')
  const [pzip, setpZip] = useState('')

  const [industry, setIndustry] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ssn, setSSN] = useState('')
  const [dob, setDob] = useState('')


  const { currentUser } = useAuth()


  return (
    loading ? <LoadingSpinnerWithText text={'Verifying Information - this may take up to 20 seconds'}/> : <PlasmicOnboardingBusinessDetailsRetryManual root={{ ref }} {...props} 
    firstName={{
      value: firstName,
      onChange: (e) => {
        setFirstName(e.target.value)
      }
    }}
    lastName={{
      value: lastName,
      onChange: (e) => {
        setLastName(e.target.value)
      }
    }}
    legalBusinessName={{
      value: legalBusinessName,
      onChange: (e) => {
        setLegalBusinessName(e.target.value)
      }
    }}
    displayBusinessName={{
      value: displayBusinessName,
      onChange: (e) => {
        setDisplayBusinessName(e.target.value)
      }
    }}
    ein={{
      value: ein,
      onChange: (e) => {
        setEin(e.target.value)
      },
      onBlur: (e) => {
        if (!(e.target.value.includes("-")) && ein.length > 2){
          setEin(ein.substring(0, 2) + "-" + ein.substring(2))
        }
      }
    }}
    address1={{
      value: address1,
      onChange: (e) => {
        setAddress1(e.target.value)
      }
    }}
    city={{
      value: city,
      onChange: (e) => {
        setCity(e.target.value)
      }
    }}
    selectState={{
      onChange: (e) => {
       setState(e)
      }
    }}
    zip={{
      value: zip,
      onChange: (e) => {
        setZip(e.target.value)
      }
    }}
    paddress1={{
      value: paddress1,
      onChange: (e) => {
        setpAddress1(e.target.value)
      }
    }}
    pcity={{
      value: pcity,
      onChange: (e) => {
        setpCity(e.target.value)
      }
    }}
    pstate={{
      onChange: (e) => {
       setpState(e)
      }
    }}
    pzip={{
      value: pzip,
      onChange: (e) => {
        setpZip(e.target.value)
      }
    }}
    selectIndustry={{
      onChange: (e) => {
        setIndustry(e)
      }
    }}
    errorText={{
      wrap: node => error ? node : null
    }}
    dob={{
      children: <DatePicker placeholderText={"mm/dd/yyyy"} className="dateBox" selected={dob} onChange={(date) => setDob(date)} />
    }}
    ssn={{
      value: ssn,
      onChange: (e) => {
        setSSN(e.target.value)
      }, 
      onBlur: (e) => {
        if (!(e.target.value.includes("-")) && ssn.length > 4){
          setSSN(ssn.substring(0, 3) + "-" + ssn.substring(3,5) + "-" + ssn.substring(5))
        }
      }
    }}
    continueButton={{
      onClick: async () => {
      
          if (firstName === '' || lastName  === '' || legalBusinessName  === '' || displayBusinessName  === '' || ein  === '' || address1  === '' 
          || city  === '' || zip  === '' ){
            setError(true)
          } else{
           
            setLoading(true)
  
  
            var businessType = ''


            /// hard code values already provided for the state
            // the biz classification is also hard coded - just get that value from the page that has the switch statement
            var reqBody = {
              firstName: firstName,
              lastName: lastName,
              email: currentUser.email,
              type: "business",
              address1: address1,
              city: city,
              state: "IL",
              postalCode: zip,
              controller: {
                firstName: firstName,
                lastName: lastName,
                title: "CEO",
                dateOfBirth: dob.getFullYear() + "-" + ("0" + (Number(dob.getMonth()) + 1)).slice(-2) + "-" + ("0" + dob.getDate()).slice(-2),
                ssn: ssn,
                address: {
                  address1: paddress1,
                  city: pcity,
                  stateProvinceRegion: "IL",
                  postalCode: pzip,
                  country: "US",
                },
              },
              businessClassification: "9ed38146-7d6f-11e3-89d6-5404a6144203",
              businessType: "llc",
              businessName: legalBusinessName,
              ein: ein,
        
            }
  
            setLoading(true)

            const retryStatus = await axios({
              method: "POST",
              url: `${config.endpoint}/onboardingFunctions-handleRetryStatus`,
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${await currentUser.getIdToken()}`
              },
              data: {
                // hard code this
                customerUrl: "https://api.dwolla.com/customers/f5de3bf9-4a7a-4e9d-9719-5868b0c73e6b",
                reqBody: reqBody
              }
            })
        
            setLoading(false)

            history.push("/onboarding-businessdetails-applicationreceived")
  
          }
          
      }
    }}
    
    />
  );
}

const OnboardingBusinessDetailsRetryManual = React.forwardRef(
  OnboardingBusinessDetailsRetryManual_
);

export default OnboardingBusinessDetailsRetryManual;
