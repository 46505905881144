// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import Moment from 'moment';
import { PlasmicContractInstance } from "./plasmic/astriusdraft/PlasmicContractInstance";
import {useParams} from "react-router-dom"
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore"; 
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, listAll, list } from "firebase/storage";
import { useAuth } from "../contexts/AuthContext";
import {parse, stringify, toJSON, fromJSON} from 'flatted';
import LoadingSpinner4 from "./LoadingSpinner4";
import {useHistory} from "react-router-dom"
import ContractInstanceCard from "./ContractInstanceCard";
import MilestoneInstance from "./MilestoneInstance";
import ClientsListCard from "./ClientsListCard";
import { Button, Modal, Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import 'bootstrap/dist/css/bootstrap.min.css';
import AddMilestoneModal from "./AddMilestoneModal"
import MilestoneBody from "./MilestoneBody"
import MilestoneHeader from "./MilestoneHeader"
import MilestoneFooter from "./MilestoneFooter"
import { v4 } from "uuid";
import axios from "axios";
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";
import { config } from './constants'

import "./Modalcss.css"



function ContractInstance_(props, ref1) {
let { contractId } = useParams();
const {currentUser, milestoneModal, setMilestoneModal, setCurrentParam} = useAuth()

const [contractName, setContractName] = useState('')
const [client, setClient] = useState({})
const [budgetAmount, setBudgetAmount] = useState('')
const [amountPaid, setAmountPaid] = useState('')
const [contractFile, setContractFile] = useState('')
const [downloadUrl, setDownloadUrl] = useState('')
const [fileName, setFileName] = useState('')
const [loading, setLoading] = useState(true);
const [milestonesExist, setMilestonesExist] = useState(false)
const [inProgress, setInProgress] = useState([])
const [awaitClientApproval, setAwaitClientApproval] = useState([])
const [awaitEscrowPayment, setAwaitEscrowPayment] = useState([])
const [disputes, setDisputes] = useState([])
const [awaitPayment, setAwaitPayment] = useState([])
const [upcomingMilestones, setUpcomingMilestones] = useState([])

const [milestonesList, setMilestonesList] = useState([])
const [escrowEnabled, setEscrowEnabled] = useState(false)
const [otherData, setOtherData] = useState({})
const [docId, setDocId] = useState('')
const [latestStatus, setLatestStatus] = useState('')
const [loadingText, setLoadingText] = useState(false)
const [userInfo, setuserInfo] = useState()

const formatDate = Moment().format("MMM Do, YYYY");

// add another field called payment request not sent for the milestone payment requests that haven't been sent yet, and then you'll have a field called 
// awaiting payment that shows all milestones that haven't been paid yet that have been sent as requests and then everything else that is paid is just shown on the bottom
// 

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

const history = useHistory()

// for the milestone view on the bottom just have it be filled with all the completed milestones.
// re-do the firebase fucntion so that it's recording dates as well. 


useEffect(() => {

  setLoading(true)

  const t = query(collection(db, "userInfo"), where("user", "==", currentUser.uid))

     onSnapshot(t, (querySnapshot) => {

      querySnapshot.forEach((doc) => {
        setuserInfo(doc.data())


      });

    })


  const q = query(collection(db, "contracts"), where("id", "==", contractId), where("user", "==", currentUser.uid) )
  var tempData={}
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      setContractName(doc.data().title)
      setClient(doc.data().client)
      setBudgetAmount(doc.data().price)
      setContractFile(doc.data().contractFile)
      setFileName(doc.data().contractFileName)
      setMilestonesExist(doc.data().milestonesExist)
      setMilestonesList(doc.data().milestones)
      setAmountPaid(doc.data().amountPaid)
      setLatestStatus(doc.data().milestones[doc.data().currentMilestone - 1])
      setOtherData(doc.data())
      setDocId(doc.id)

      if (!doc.data().milestonesExist){

       switch(doc.data().status){
        case "In Progress":
           setInProgress([...inProgress, {amount: doc.data().price, status: "Escrow Funded", milestoneNumber: "NA", date: doc.data().date.toDate().toDateString()}])
           break;
        case "Awaiting Client Approval":
          setAwaitClientApproval([...awaitClientApproval, {amount: doc.data().price, status: "Awaiting Approval", milestoneNumber: "NA", date: doc.data().date.toDate().toDateString()}])
          break;
        case "Awaiting Escrow Payment":
            setAwaitEscrowPayment([...awaitEscrowPayment, {amount: doc.data().price, status: "Awaiting Payment", milestoneNumber: "NA", date: doc.data().date.toDate().toDateString()}])
            break;
        case "Disputes":
            setDisputes([...disputes, {amount: doc.data().price, status: "Under Dispute", milestoneNumber: "NA", date: doc.data().date.toDate().toDateString()}])
            break;
        case "Awaiting Payment":
              setAwaitPayment([...awaitPayment, {amount: doc.data().price, status: "Not Paid", milestoneNumber: "NA", date: doc.data().date.toDate().toDateString()}])
              break;
        case "Upcoming Milestones":
              setUpcomingMilestones([...upcomingMilestones, {amount: doc.data().price, status: "Request Not Sent", milestoneNumber: "NA", date: doc.data().date.toDate().toDateString()}])
                break;
       } 
      } else {

        var inProgressTemp = []
        var awaitClientApprovalTemp = []
        var awaitEscrowTemp = []
        var disputesTemp = []
        var awaitPaymentTemp = []
        var upcomingMilestonesTemp = []

        for (var i = 0; i < doc.data().milestones.length; i++) {
          
         
          switch(doc.data().milestones[i].status){
            case "In Progress":
               inProgressTemp.push({amount: doc.data().milestones[i].amount, status: "Escrow Funded", milestoneNumber: i + 1, date: doc.data().date.toDate().toDateString()})
               break;
            case "Awaiting Client Approval":
              awaitClientApprovalTemp.push({amount: doc.data().milestones[i].amount, status: "Awaiting Approval", milestoneNumber: i + 1, date: doc.data().date.toDate().toDateString()})
              break;
            case "Awaiting Escrow Payment":
                awaitEscrowTemp.push({amount: doc.data().milestones[i].amount, status: "Awaiting Payment", milestoneNumber: i + 1, date: doc.data().date.toDate().toDateString()})
                break;
            case "Disputes":
              disputesTemp.push({amount: doc.data().milestones[i].amount, status: "Under Dispute", milestoneNumber: i + 1, date: doc.data().date.toDate().toDateString()})
                break;
            case "Awaiting Payment":
              awaitPaymentTemp.push({amount: doc.data().milestones[i].amount, status: "Not Paid", milestoneNumber: i + 1, date: doc.data().date.toDate().toDateString()})
                 break;
            case "Upcoming Milestones":
              upcomingMilestonesTemp.push({amount: doc.data().milestones[i].amount, status: "Request Not Sent", milestoneNumber: i + 1, date: doc.data().date.toDate().toDateString()})
                  break;
           } 
           
        }
        
        setInProgress(inProgressTemp)
        setAwaitClientApproval(awaitClientApprovalTemp)
        setAwaitEscrowPayment(awaitEscrowTemp)
        setDisputes(disputesTemp)
        setAwaitPayment(awaitPaymentTemp)
        setUpcomingMilestones(upcomingMilestonesTemp)

       }

        
      } )

      delay(100).then(() => setLoading(false));


    });
    
    setCurrentParam(contractId)
    

}, [])


function handleDownload(){

  getDownloadURL(ref(storage, contractFile))
  .then((url) => {
 

    handleDownloadUrl(url)

  })
  .catch((error) => {
    // Handle any errors
  });

}



async function handleDownloadUrl (source) {

  const image = await fetch(source)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)

  const link = document.createElement('a')
  link.href = imageURL
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

}

function downloadPDF(pdf) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = "contract-terms.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}


async function handleClientNavigation(){

  var clientId = ""

  // fix clientName
  const q = query(collection(db, "clients"), where("user", "==", currentUser.uid), where("email", "==", client.email))

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    clientId = doc.data().clientId
   
  });
  

  history.push(`/client/${clientId}`)

}

const handleSendMileStone= async(merchantName,newPaymentId)=>{
  axios({
      method: "POST",
      url:`${config.endpoint}/sendMilestone`, 
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      },
      data: {email: client.email, clientCompany: client.company, merchantName: merchantName, milestoneName: otherData.milestones[otherData.currentMilestone - 1].description, milestoneNum: otherData.currentMilestone, contractName: otherData.title, merchantEmail: currentUser.email, amount: otherData.milestones[otherData.currentMilestone - 1].amount, buttonUrl: `https://app.astrius.co/milestonepayments/${newPaymentId}` }
    }).catch((err)=>{

      alert("Failed to submit request, please try again")
    })
  
}

async function sendNewPayment() {
setLoadingText(true)

  
  var fundingSource;
  var invoiceNum = 0
  var merchantName = ""
  var userInfoDocId = ""
  var userData = {}

  const q = query(collection(db, "userInfo"), where("user", "==", currentUser.uid))
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    fundingSource = doc.data().fundingSource
    merchantName = doc.data().merchantName
    invoiceNum = doc.data().invoiceNum;
    userInfoDocId = doc.id
    userData = doc.data()
  });
  
  var newPaymentId = v4()

  var yourToken = v4()

  const ip = await axios.get('https://geolocation-db.com/json/')

  var response = {
    data: {
      id: null
    }
  }
  // Only call api deck invoice creation when api deck 
  //client id is not null meaning the create invoice will 
  //only be called when user has created the customer in ApiDeck
  if(otherData.client.api_deck_clientId) {
    const params = {
      invoice: {
        type: "service",
        number: invoiceNum?.toString(),
        customer: {
          id: otherData.client.api_deck_clientId,
          display_name: otherData.client.contactName,
        },
        invoice_date: new Date().toString(),
        due_date: new Date(otherData.milestones[otherData.currentMilestone - 1].dueDate.seconds*1000).toString(),
        terms: null,
        po_number: null,
        reference: "8765432",
        status: 'authorised',
        invoice_sent: true,
        currency: "USD",
        currency_rate: null,
        tax_inclusive: null,
        sub_total: Number(otherData.milestones[otherData.currentMilestone - 1].amount.replace(/[^0-9\.-]+/g,"")),
        total_tax: null,
        tax_code: null,
        discount_percentage: null,
        discount_amount: null,
        total: Number(otherData.milestones[otherData.currentMilestone - 1].amount.replace(/[^0-9\.-]+/g,"")),
        balance: Number(otherData.milestones[otherData.currentMilestone - 1].amount.replace(/[^0-9\.-]+/g,"")),
        deposit: null,
        customer_memo: null,
        line_items: [
          {
            row_id: "",
            // code: '120-C',
            line_number: 1,
            description: "Milestone Request",
            type: 'sales_item',
            tax_amount: 0,
            total_amount: Number(otherData.milestones[otherData.currentMilestone - 1].amount.replace(/[^0-9\.-]+/g,"")),
            quantity: 1,
            unit_price: Number(otherData.milestones[otherData.currentMilestone - 1].amount.replace(/[^0-9\.-]+/g,"")),
            unit_of_measure: 'pc.',
            discount_percentage: null,
            discount_amount: null,
            location_id: null,
            department_id: null,
            item: {
              id: ""
            },
            tax_rate: {
              id: ""
            },
            ledger_account: {
              id: "",
              nominal_code: "4000",
              code: "4000",
            },
            row_version: ""
          }
        ],
        billing_address: {
          id: "123",
          type: "primary",
          string: "25 Spring Street, Blackburn, VIC 3130",
          name: "HQ US",
          line1: "Main street",
          line2: "apt #",
          line3: "Suite #",
          line4: "delivery instructions",
          street_number: "25",
          city: "San Francisco",
          state: "CA",
          postal_code: "94104",
          country: "US",
          latitude: "40.759211",
          longitude: "-73.984638",
          county: "Santa Clara",
          contact_name: "Elon Musk",
          salutation: "Mr",
          phone_number: "111-111-1111",
          fax: "122-111-1111",
          email: client.email,
          website: "https://elonmusk.com",
          row_version: "1-12345",
        },
        shipping_address: {
          id: "123",
          type: "primary",
          string: "25 Spring Street, Blackburn, VIC 3130",
          name: "HQ US",
          line1: "Main street",
          line2: "apt #",
          line3: "Suite #",
          line4: "delivery instructions",
          street_number: "25",
          city: "San Francisco",
          state: "CA",
          postal_code: "94104",
          country: "US",
          latitude: "40.759211",
          longitude: "-73.984638",
          county: "Santa Clara",
          contact_name: "Elon Musk",
          salutation: "Mr",
          phone_number: "111-111-1111",
          fax: "122-111-1111",
          email: client.email,
          website: "https://elonmusk.com",
          row_version: "1-12345",
        },
        // template_id: null,
        source_document_url: "https://www.invoicesolution.com/invoice/123456",
        row_version: "1-12345",
      },
    };
    response = await axios({
      method: "POST",
      url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=createInvoice`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await currentUser.getIdToken()}`,
      },
      data: params,
    })

    await updateDoc(doc(db, "userInfo", userInfoDocId), {
      invoiceNum: invoiceNum + 1,
    });

  }

  const contractData = otherData
  contractData.milestones[contractData.currentMilestone - 1].api_deck_invoiceId = response ? response.data.id : null
  setOtherData(contractData)

  const escrowPayments = await addDoc(collection(db, "escrowPayments"), {
    api_deck_invoiceId: response ? response.data.id : null,
    user: currentUser.uid,
    userEmail: currentUser.email,
    merchantName: merchantName,
    contractFile: contractFile,
    contractFileName: fileName,
    escrowDeposit: otherData.milestones[otherData.currentMilestone - 1].amount,
    vendorType: "service",
    paymentMethods: otherData.paymentMethods,
    automationOptions: otherData.automationOptions,
    reminderOptions: otherData.reminderOptions,
    contractId: contractId,
    paymentId: newPaymentId,
    contractName: otherData.title,
    milestoneOrContract: "Milestone " + otherData.currentMilestone + ": " + otherData.milestones[otherData.currentMilestone - 1].description,
    sideBarMilestoneName : "Milestone " + otherData.currentMilestone + ": " + otherData.milestones[otherData.currentMilestone - 1].description,
    date: serverTimestamp(),
    clientUrl: "NA",
    fundingDestination: typeof fundingSource === "undefined" ? "" : fundingSource,
    paymentSuccess: false,
    datePaid: 'NA',
    amountNum: Number(otherData.milestones[otherData.currentMilestone - 1].amount.replace(/[^0-9\.-]+/g,"")),
    clientDocId: otherData.clientDocId,
    userInfoDocId: userInfoDocId,
    clientCompany: otherData.client.company,
    clientEmail: otherData.client.email,
    type: "Milestone Payment",
    paymentVoided: false,
    voidDate: "NA",
    contractDescription: otherData.contractDescription,
    merchantId: typeof userData.merchantId === 'undefined' ? '' : userData.merchantId,
    terminalId: typeof userData.terminalId === 'undefined' ? '' : userData.terminalId,
    yourToken: yourToken,
    ip: ip.data.IPv4,
    heartlandPublicKey: userData.heartlandPublicKey,
    plan: userInfo.plan,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    api_deck_ledger_id: userInfo.api_deck_ledger_id ? userInfo.api_deck_ledger_id : null,
    api_deck_supplier_id: userInfo.api_deck_supplier_id ? userInfo.api_deck_supplier_id : null,
    api_deck_bank_id: userData.api_deck_bank_id ? userInfo.api_deck_supplier_id : null,
    client: JSON.stringify(client),
  })

/// left off here in creating the new payment
// the error seems to be that the data you are getting is from [otherData.currentMilestone - 1], which is incorrect since this is still the data from the previous milestone, and you need to get it from the new one you just created. 

  const payments = await addDoc(collection(db, "payments"), {
    api_deck_invoiceId: response ? response.data.id : null,
    user: currentUser.uid,
    userEmail: currentUser.email,
    type: "Milestone Payment",
    amount: otherData.milestones[otherData.currentMilestone - 1].amount,
    amountNum: Number(otherData.milestones[otherData.currentMilestone - 1].amount.replace(/[^0-9\.-]+/g,"")),
    paymentId: newPaymentId,
    status: "Not Paid",
    client: JSON.stringify(client),
    clientCompany: client.company,
    dateCreated: formatDate,
    paymentDataId: v4(),
    datePaid: "NA",
    paymentMethod: "NA",
    paymentNotes: "",
    date: serverTimestamp(),
    clientEmail: client.email,
    clientUrl: "NA",
    datePaidNum: 'NA',
    fundsLocation: 'NA',
    paymentSuccess: false,
    clientDocId: otherData.clientDocId,
    invoiceNum: 'NA',
    fundingDestination: 'NA',
    merchantName: 'NA',
    invoiceFields: 'NA',
    dueDate: 'NA', 
    userInfoDocId: userInfoDocId,
    milestoneOrContract: "Milestone " + otherData.currentMilestone + ": " + otherData.milestones[otherData.currentMilestone - 1].description,
    paymentMethods: otherData.paymentMethods,
    automationOptions: {enabled: false, number: 0, unit: ""},
    reminderOptions: otherData.reminderOptions,
    paymentVoided: false,
    voidDate: "NA",
    contractTitle: otherData.title,
    merchantId: typeof userData.merchantId === 'undefined' ? '' : userData.merchantId,
    terminalId: typeof userData.terminalId === 'undefined' ? '' : userData.terminalId,
    zip: userData.zip,
    yourToken: yourToken,
    ip: ip.data.IPv4,
    heartlandPublicKey: userData.heartlandPublicKey,
    plan: userInfo.plan,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    api_deck_ledger_id: userInfo.api_deck_ledger_id ? userInfo.api_deck_ledger_id : null,
    api_deck_supplier_id: userInfo.api_deck_supplier_id ? userInfo.api_deck_supplier_id : null,
    api_deck_bank_id: userData.api_deck_bank_id ? userData.api_deck_bank_id : null
  })

  var tempMilestones = milestonesList


  tempMilestones[otherData.currentMilestone - 1].status = "Awaiting Payment"
  tempMilestones[otherData.currentMilestone - 1].api_deck_invoiceId = response ? response.data.id : null

await updateDoc(doc(db, "contracts", docId), {
  milestones: tempMilestones,
  paymentId: newPaymentId
})
handleSendMileStone(merchantName,newPaymentId);
  

  setLoadingText(false)


}


  return (
    <>
    {loadingText ? <LoadingSpinnerWithText text={"Sending Milestone Request"}/> :
    <PlasmicContractInstance root={{ ref1 }} {...props} contractName={contractName} clientName={client.company} budgetAmount={budgetAmount.toLocaleString('en-US', {style: 'currency', currency: 'USD', })} escrowAmount={"$" + 0.00} amountPaid={amountPaid.toLocaleString('en-US', {style: 'currency', currency: 'USD', })}
    vertStack={loading && {
      children: <LoadingSpinner4/>
    }}
    requestPaymentButton={{
      wrap: node => typeof latestStatus !== 'undefined' ? (latestStatus.status !== "Awaiting Payment" ? node : null ) : null,
      props: {
        onClick: () => sendNewPayment()
      }
    }}
    addMilestoneButton={{
      onClick: () => setMilestoneModal(true)
    }}
    clientNameStack={{
      // get client id and push it to the url slug with the client id associated with it
      onClick: () => handleClientNavigation()
    }}
    downloadContract={{
      wrap: node => (otherData.contractFile === "" && otherData.contractDescription === '') ? null : node,
      props: {
        onClick: async () => {

          if (otherData.contractFile !== ""){
            handleDownload()
          } else {
            
            const termsPDF = await axios({
              method: "POST",
              url: `${config.endpoint}/contractTerms`,
              data: {
                contractDescription: otherData.contractDescription
              } 
             })
            
            
             downloadPDF(termsPDF.data)

          }
         

        }
      }
    }}
    contractTerms={{
      wrap: node => (otherData.contractFile === "" && otherData.contractDescription === '') ? null : node,
    }}
    inProgressStack={{
      wrap: node => inProgress.length === 0 ? null : node
    }}
    inProgressCards={{
      children: inProgress.map((x) => {
        return <ContractInstanceCard amount={x.amount} status={x.status} milestoneNumber={x.milestoneNumber} date={x.date}/>
      })
    }}
    awaitingApprovalStack={{
      wrap: node => awaitClientApproval.length === 0 ? null : node
    }}
    awaitingApprovalCards={{
      children: awaitClientApproval.map(x => {
        return <ContractInstanceCard amount={x.amount} status={x.status} milestoneNumber={x.milestoneNumber} date={x.date}/>
      })
    }}
    awaitingEscrowStack={{
      wrap: node => awaitEscrowPayment.length === 0 ? null : node
    }}
    awaitingEscrowCards={{
      children: awaitEscrowPayment.map((x) => {
        return <ContractInstanceCard amount={x.amount} status={x.status} milestoneNumber={x.milestoneNumber} date={x.date}/>
      })
    }}
    disputesStack={{
      wrap: node => disputes.length === 0 ? null : node
    }}
    disputesCards={{
      children: disputes.map(x => {
        return <ContractInstanceCard amount={x.amount} status={x.status} milestoneNumber={x.milestoneNumber} date={x.date}/>
      })
    }}
    awaitingPaymentStack={{
      wrap: node => awaitPayment.length === 0 ? null : node
    }}
    awaitingPaymentCards={{
      children: awaitPayment.map(x => {
        return <ContractInstanceCard amount={x.amount} status={x.status} milestoneNumber={x.milestoneNumber} date={x.date}/>
      })
    }}
    upcomingMilestonesStack={{
      wrap: node => upcomingMilestones.length === 0 ? null : node
    }}
    upcomingMilestonesCards={{
      children: upcomingMilestones.map(x => {
        return <ContractInstanceCard amount={x.amount} status={x.status} milestoneNumber={x.milestoneNumber} date={x.date}/>
      })
    }}
    milestoneTitle={{
      wrap: node => milestonesExist ? node : null
    }}
    milestoneStack={ milestonesExist ? {
      children: milestonesList.map((x, i) => {
        i+=1
        return <MilestoneInstance milestoneTitle={x.description} milestoneNumber={i} milestoneAmount={x.amount} milestoneStatus={x.status}/>
      })
      
    }:
    {children: <>
    </>
    }
  }
    />
    }
    {/* <Modal dialogClassName="milestone-modal" show={showModal} onHide={handleClose} 
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Body>
          <AddMilestoneModal/>
        </Modal.Body>
      </Modal> */}
      <Modal dialogClassName="milestone-modal" show={milestoneModal} onHide={setMilestoneModal} 
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header >
          <MilestoneHeader/>
        </Modal.Header>
        <Modal.Body>
          <MilestoneBody/>
        </Modal.Body>
        <Modal.Footer>
          <MilestoneFooter/>
        </Modal.Footer>
      </Modal>
    </>
  )
  

}

const ContractInstance = React.forwardRef(ContractInstance_);

export default ContractInstance;