import * as React from "react";
import { PlasmicSelectState__Option } from "./plasmic/astriusdraft/PlasmicSelectState__Option";

function SelectState__Option_(props, ref) {
  const { plasmicProps } = PlasmicSelectState__Option.useBehavior(props, ref);
  return <PlasmicSelectState__Option {...plasmicProps} />;
}

const SelectState__Option = React.forwardRef(SelectState__Option_);

export default Object.assign(SelectState__Option, {
  __plumeType: "select-option"
});
