import * as React from "react";
import { PlasmicCheckbox2 } from "./plasmic/astriusdraft/PlasmicCheckbox2";

function Checkbox2_(props, ref) {
  const { plasmicProps, state } = PlasmicCheckbox2.useBehavior(props, ref);
  return <PlasmicCheckbox2 {...plasmicProps} />;
}

const Checkbox2 = React.forwardRef(Checkbox2_);

export default Object.assign(Checkbox2, {
  __plumeType: "checkbox"
});
