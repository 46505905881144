import React, { useState } from "react";
import { PlasmicEscrowPaymentsPagePreview } from "./plasmic/astriusdraft/PlasmicEscrowPaymentsPagePreview";
import { useAuth } from "../contexts/AuthContext"
import { useHistory } from 'react-router-dom';
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, listAll, list } from "firebase/storage";
import { parse, stringify, toJSON, fromJSON } from 'flatted';
import { v4 } from "uuid";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import Moment from 'moment';
import { useEffect } from "react";
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";
import { PlasmicEscrowPaymentsPagePreviewAch } from "./plasmic/astriusdraft/PlasmicEscrowPaymentsPagePreviewAch";
import { PlasmicEscrowPaymentsPagePreviewCard } from "./plasmic/astriusdraft/PlasmicEscrowPaymentsPagePreviewCard";
import { PlasmicEscrowPaymentsPagePreviewSurcharge } from "./plasmic/astriusdraft/PlasmicEscrowPaymentsPagePreviewSurcharge";
import { config } from './constants'


function EscrowPaymentsPagePreview_(props, ref1) {
  const [loading, setLoading] = useState(true);

  const { currentUser, escrowPaymentPreview, escrowSubmitParams, milestones, handleSetMilestones, setEscrowSubmitParams } = useAuth()
  const [showSideBar, setShowSideBar] = useState(false)
  const [merchantName, setMerchantName] = useState('')
  const [loadingText, setLoadingText] = useState(false)
  const [pageType, setPageType] = useState('')
  const [approved, setApproved] = useState(false);
  const [userInfo, setuserInfo] = useState()

  const formatDate = Moment().format("MMM Do, YYYY");

  const history = useHistory()

  async function uploadFile(upload) {

    if (upload == null) return '';

    const path = `contractFile/${upload.name + v4()}`

    const imageRef = ref(storage, path);

    uploadBytes(imageRef, upload).then((snapshot) => {


    });

    return path

  }


  useEffect(() => {

    const q = query(collection(db, "userInfo"), where("user", "==", currentUser.uid))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      querySnapshot.forEach((doc) => {

        setMerchantName(doc.data().merchantName)
        setuserInfo(doc.data())


        setApproved(doc.data().cardApproval & doc.data().identityVerification)


      });

    })



    try {

      if (escrowSubmitParams.paymentMethods.ACH && !(escrowSubmitParams.paymentMethods.Card || escrowSubmitParams.paymentMethods.CardSurcharge)) {
        setPageType('ACH')
      } else if ((escrowSubmitParams.paymentMethods.Card || escrowSubmitParams.paymentMethods.CardSurcharge) && !escrowSubmitParams.paymentMethods.ACH) {
        setPageType('Card')
      } else if (escrowSubmitParams.paymentMethods.CardSurcharge && escrowSubmitParams.paymentMethods.ACH) {
        setPageType('CardSurcharge')
      }

    } catch (e) {

    }


    setLoading(false)

  }, [])


  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  const handleSendMileStone=async(client,title,paymentId)=>{
   
      axios({
        method: "POST",
        url: `${config.endpoint}/sendMilestone`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await currentUser.getIdToken()}`
        },
        data: { email: client.email, clientCompany: client.company, merchantName: merchantName, milestoneName: milestones[0].description, milestoneNum: 1, contractName: title, merchantEmail: currentUser.email, amount: escrowPaymentPreview.escrowDeposit, buttonUrl: `https://app.astrius.co/milestonepayments/${paymentId}` }
      }).catch((error)=>{

        alert("Failed to submit request, please try again")
      })
  
    
  }

  async function handleEscrowSubmit({ title, price, upload, uploadName, milestonesExist, paymentMethods, dueDate, automationOptions, reminderOptions, client, contractDescription }) {

    if (!approved) {
      alert("Card payments haven't been fully approved on your account yet. We'll send you an email as soon as this has been enabled.")

      return;
    }

    setLoadingText(true)

    window.addEventListener("beforeunload", handleBeforeUnload);


    const path = await uploadFile(upload)

    const id = v4()

    const paymentId = v4()

    if (milestonesExist) {
      const temp = milestones
      temp[0].paymentId = paymentId
      handleSetMilestones(temp)
    }
    console.log(milestones)
    const ip = await axios.get('https://geolocation-db.com/json/')


    var fundingSource;
    var invoiceNum = 0
    var merchantName = ""
    var userInfoDocId = ""
    var userData = {}

    const q = query(collection(db, "userInfo"), where("user", "==", currentUser.uid))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      fundingSource = doc.data().fundingSource
      merchantName = doc.data().merchantName
      invoiceNum = doc.data().invoiceNum
      userInfoDocId = doc.id
      userData = doc.data()
    });

    console.log(client)
    console.log(milestones[0].amount)
    const totalAmount = parseInt(milestones[0].amount.replace("$", ""))
    var response = {
      data: {
        id: null
      }
    }

    if(client.api_deck_clientId){
      const params = {
        invoice: {
          type: "service",
          number: invoiceNum?.toString(),
          customer: {
            id: client.api_deck_clientId,
            display_name: client.contactName,
          },
          invoice_date: new Date().toString(),
          due_date: milestones[0].dueDate.toString(),
          terms: null,
          po_number: null,
          reference: "8765432",
          status: 'authorised',
          invoice_sent: true,
          currency: "USD",
          currency_rate: null,
          tax_inclusive: null,
          sub_total: Number(escrowPaymentPreview.escrowDeposit.replace(/[^0-9\.-]+/g, "")),
          total_tax: null,
          tax_code: null,
          discount_percentage: null,
          discount_amount: null,
          total: Number(escrowPaymentPreview.escrowDeposit.replace(/[^0-9\.-]+/g, "")),
          balance: Number(escrowPaymentPreview.escrowDeposit.replace(/[^0-9\.-]+/g, "")),
          deposit: null,
          customer_memo: null,
          line_items: [
            {
              row_id: "",
              // code: '120-C',
              line_number: 1,
              // change later to the title but good for now
              description: "Milestone Request",
              type: 'sales_item',
              tax_amount: 0,
              total_amount: Number(escrowPaymentPreview.escrowDeposit.replace(/[^0-9\.-]+/g, "")),
              quantity: 1,
              unit_price: Number(escrowPaymentPreview.escrowDeposit.replace(/[^0-9\.-]+/g, "")),
              unit_of_measure: 'pc.',
              discount_percentage: null,
              discount_amount: null,
              location_id: null,
              department_id: null,
              item: {
                id: ""
              },
              tax_rate: {
                id: ""
              },
              ledger_account: {
                id: "",
                nominal_code: "4000",
                code: "4000",
              },
              row_version: ""
            }
          ],
          billing_address: {
            id: "123",
            type: "primary",
            string: "25 Spring Street, Blackburn, VIC 3130",
            name: "HQ US",
            line1: "Main street",
            line2: "apt #",
            line3: "Suite #",
            line4: "delivery instructions",
            street_number: "25",
            city: "San Francisco",
            state: "CA",
            postal_code: "94104",
            country: "US",
            latitude: "40.759211",
            longitude: "-73.984638",
            county: "Santa Clara",
            contact_name: "Elon Musk",
            salutation: "Mr",
            phone_number: "111-111-1111",
            fax: "122-111-1111",
            email: client.email,
            website: "https://elonmusk.com",
            row_version: "1-12345",
          },
          shipping_address: {
            id: "123",
            type: "primary",
            string: "25 Spring Street, Blackburn, VIC 3130",
            name: "HQ US",
            line1: "Main street",
            line2: "apt #",
            line3: "Suite #",
            line4: "delivery instructions",
            street_number: "25",
            city: "San Francisco",
            state: "CA",
            postal_code: "94104",
            country: "US",
            latitude: "40.759211",
            longitude: "-73.984638",
            county: "Santa Clara",
            contact_name: "Elon Musk",
            salutation: "Mr",
            phone_number: "111-111-1111",
            fax: "122-111-1111",
            email: client.email,
            website: "https://elonmusk.com",
            row_version: "1-12345",
          },
          // template_id: null,
          source_document_url: "https://www.invoicesolution.com/invoice/123456",
          row_version: "1-12345",
        },
      };

      response = await axios({
        method: "POST",
        url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=createInvoice`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await currentUser.getIdToken()}`,
        },
        data: params,
      })


      await updateDoc(doc(db, "userInfo", userInfoDocId), {
        invoiceNum: invoiceNum + 1,
      });

    }
    


    const temp = milestones
    temp[0].api_deck_invoiceId = response ? response.data.id : null
    handleSetMilestones(temp)


    console.log("milestones after update: ")
    console.log(milestones)
    const contractsInstance = await addDoc(collection(db, "contracts"), {
      title: title,
      price: Number(price.replace(/[^0-9\.-]+/g, "")),
      user: currentUser.uid,
      contractFile: path,
      contractFileName: uploadName,
      milestonesExist: milestonesExist,
      dueDate: dueDate,
      paymentMethods: paymentMethods,
      automationOptions: automationOptions,
      reminderOptions: reminderOptions,
      client: client,
      clientCompany: client.company,
      date: serverTimestamp(),
      displayDate: formatDate,
      id: id,
      contractCompleted: false,
      currentMilestone: 1,
      currentPaymentsLinkId: "",
      status: "Awaiting Payment",
      milestones: milestones,
      paymentId: paymentId,
      amountPaid: 0,
      clientDocId: client.docId,
      contractDescription: contractDescription,
      api_deck_bank_id: userData.api_deck_bank_id ? userData.api_deck_bank_id : null
    })

    var yourToken = v4()


    const escrowPayments = await addDoc(collection(db, "escrowPayments"), {
      api_deck_invoiceId: response ? response.data.id : null,
      user: currentUser.uid,
      userEmail: currentUser.email,
      merchantName: merchantName,
      contractFile: path,
      contractFileName: uploadName,
      escrowDeposit: escrowPaymentPreview.escrowDeposit,
      vendorType: "service",
      paymentMethods: paymentMethods,
      reminderOptions: reminderOptions,
      contractId: id,
      paymentId: paymentId,
      contractName: escrowPaymentPreview.title,
      milestoneOrContract: escrowPaymentPreview.milestoneOrContract,
      sideBarMilestoneName: escrowPaymentPreview.milestonesExist ? "Milestone 1: " + milestones[0].description : "",
      date: serverTimestamp(),
      client: JSON.stringify(client),
      invoiceFields:[{balanceDue: Number(escrowPaymentPreview.escrowDeposit.replace(/[^0-9\.-]+/g, ""))}],
      clientUrl: "NA",
      fundingDestination: typeof fundingSource === "undefined" ? "" : fundingSource,
      paymentSuccess: false,
      datePaid: 'NA',
      amountNum: Number(escrowPaymentPreview.escrowDeposit.replace(/[^0-9\.-]+/g, "")),
      clientDocId: client.docId,
      userInfoDocId: userInfoDocId,
      clientCompany: client.company,
      clientEmail: client.email,
      type: "Milestone Payment",
      paymentVoided: false,
      voidDate: "NA",
      contractDescription: contractDescription,
      merchantId: typeof userData.merchantId === 'undefined' ? '' : userData.merchantId,
      terminalId: typeof userData.terminalId === 'undefined' ? '' : userData.terminalId,
      yourToken: yourToken,
      ip: ip.data.IPv4,
      heartlandPublicKey: userData.heartlandPublicKey,
      plan: userInfo.plan,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      api_deck_ledger_id: userInfo.api_deck_ledger_id ? userInfo.api_deck_ledger_id : null,
      api_deck_supplier_id: userInfo.api_deck_supplier_id ? userInfo.api_deck_supplier_id : null,
      api_deck_bank_id: userData.api_deck_bank_id ? userData.api_deck_bank_id : null,
      client: JSON.stringify(client),
    })



    const payments = await addDoc(collection(db, "payments"), {
      api_deck_invoiceId: response ? response.data.id : null,
      user: currentUser.uid,
      userEmail: currentUser.email,
      type: "Milestone Payment",
      amount: escrowPaymentPreview.escrowDeposit,
      amountNum: Number(escrowPaymentPreview.escrowDeposit.replace(/[^0-9\.-]+/g, "")),
      paymentId: paymentId,
      status: "Not Paid",
      client: JSON.stringify(client),
      clientCompany: client.company,
      dateCreated: formatDate,
      paymentDataId: v4(),
      datePaid: "NA",
      paymentMethod: "NA",
      paymentNotes: "",
      date: serverTimestamp(),
      clientEmail: client.email,
      clientUrl: "NA",
      datePaidNum: 'NA',
      fundsLocation: 'NA',
      paymentSuccess: false,
      clientDocId: client.docId,
      invoiceNum: 'NA',
      fundingDestination: 'NA',
      merchantName: 'NA',
      invoiceFields: 'NA',
      dueDate: 'NA',
      userInfoDocId: userInfoDocId,
      milestoneOrContract: escrowPaymentPreview.milestoneOrContract,
      paymentMethods: paymentMethods,
      automationOptions: { enabled: false, number: 0, unit: "" },
      reminderOptions: reminderOptions,
      paymentVoided: false,
      voidDate: "NA",
      contractTitle: title,
      merchantId: typeof userData.merchantId === 'undefined' ? '' : userData.merchantId,
      terminalId: typeof userData.terminalId === 'undefined' ? '' : userData.terminalId,
      zip: userData.zip,
      yourToken: yourToken,
      ip: ip.data.IPv4,
      heartlandPublicKey: userData.heartlandPublicKey,
      plan: userInfo.plan,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      api_deck_ledger_id: userInfo.api_deck_ledger_id ? userInfo.api_deck_ledger_id : null,
      api_deck_supplier_id: userInfo.api_deck_supplier_id ? userInfo.api_deck_supplier_id : null,
      api_deck_bank_id: userData.api_deck_bank_id ? userData.api_deck_bank_id : null
    })
handleSendMileStone(client,title,paymentId)
  


    handleSetMilestones([{ id: new Date().getTime(), description: "", dueDate: "", amount: '', status: "Awaiting Payment" }])

    setEscrowSubmitParams({})


    setLoadingText(false)


    history.push(`/contract/${id}`)

    window.removeEventListener("beforeunload", handleBeforeUnload);

  }



  // function handleDownload(){

  //   

  //   // getDownloadURL(ref(storage,contractFile))
  //   // .then((url) => {


  //   //   handleDownloadUrl(url)

  //   // })
  //   // .catch((error) => {
  //   //   // Handle any errors
  //   // });

  // }


  // // async function handleDownloadUrl (source) {

  // //   const image = await fetch(source)
  // //   const imageBlog = await image.blob()
  // //   const imageURL = URL.createObjectURL(imageBlog)

  // //   const link = document.createElement('a')
  // //   link.href = imageURL
  // //   link.download = contractFileName
  // //   document.body.appendChild(link)
  // //   link.click()
  // //   document.body.removeChild(link)

  // // }



  return (
    <>
      {loading ? <LoadingSpinner /> : loadingText ? <LoadingSpinnerWithText text={"Sending Milestone Request"} /> :
        pageType === "ACH" ? <PlasmicEscrowPaymentsPagePreviewAch root={{ ref1 }} {...props}
          sendText={"Send"}
          merchantName={merchantName}
          escrowDeposit={`Requested Milestone Deposit: ${escrowPaymentPreview.escrowDeposit}`}
          milestoneOrContract={escrowPaymentPreview.milestoneOrContract}
          footerText={`Your funds will be safeguarded until ${currentUser.email} has delivered their service according to the contract terms.`}
          contractName={escrowPaymentPreview.title}
          milestoneName={escrowPaymentPreview.milestonesExist ? "Milestone 1: " + milestones[0].description : ""}
          amount={escrowPaymentPreview.escrowDeposit}
          viewDetails={{
            onClick: () => setShowSideBar(!showSideBar)
          }}
          closeButton={{
            onClick: () => setShowSideBar(!showSideBar)
          }}
          sideBarInstance={{
            wrap: node => showSideBar ? node : null
          }}
          viewContract={{
            wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
          }}
          downloadContract={{
            wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
          }}
          lowerBar={{
            wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
          }}
          goBackArrow={{
            onClick: () => history.push("/contractsubmission")
          }}
          goBackText={{
            onClick: () => history.push("/contractsubmission")
          }}
          sendButton={{
            onClick: () => handleEscrowSubmit(escrowSubmitParams)
          }}
        /> :
          pageType === "Card" ? <PlasmicEscrowPaymentsPagePreviewCard root={{ ref1 }} {...props}
            sendText={"Send"}
            merchantName={merchantName}
            escrowDeposit={`Requested Milestone Deposit: ${escrowPaymentPreview.escrowDeposit}`}
            milestoneOrContract={escrowPaymentPreview.milestoneOrContract}
            footerText={`Your funds will be safeguarded until ${currentUser.email} has delivered their service according to the contract terms.`}
            contractName={escrowPaymentPreview.title}
            milestoneName={escrowPaymentPreview.milestonesExist ? "Milestone 1: " + milestones[0].description : ""}
            amount={escrowPaymentPreview.escrowDeposit}
            viewDetails={{
              onClick: () => setShowSideBar(!showSideBar)
            }}
            closeButton={{
              onClick: () => setShowSideBar(!showSideBar)
            }}
            sideBarInstance={{
              wrap: node => showSideBar ? node : null
            }}
            viewContract={{
              wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
            }}
            downloadContract={{
              wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
            }}
            lowerBar={{
              wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
            }}
            goBackArrow={{
              onClick: () => history.push("/contractsubmission")
            }}
            goBackText={{
              onClick: () => history.push("/contractsubmission")
            }}
            sendButton={{
              onClick: () => handleEscrowSubmit(escrowSubmitParams)
            }}
          /> : pageType === "CardSurcharge" ? <PlasmicEscrowPaymentsPagePreviewSurcharge root={{ ref1 }} {...props}
            sendText={"Send"}
            merchantName={merchantName}
            escrowDeposit={`Requested Milestone Deposit: ${escrowPaymentPreview.escrowDeposit}`}
            milestoneOrContract={escrowPaymentPreview.milestoneOrContract}
            footerText={`Your funds will be safeguarded until ${currentUser.email} has delivered their service according to the contract terms.`}
            contractName={escrowPaymentPreview.title}
            milestoneName={escrowPaymentPreview.milestonesExist ? "Milestone 1: " + milestones[0].description : ""}
            amount={escrowPaymentPreview.escrowDeposit}
            viewDetails={{
              onClick: () => setShowSideBar(!showSideBar)
            }}
            closeButton={{
              onClick: () => setShowSideBar(!showSideBar)
            }}
            sideBarInstance={{
              wrap: node => showSideBar ? node : null
            }}
            viewContract={{
              wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
            }}
            downloadContract={{
              wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
            }}
            lowerBar={{
              wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
            }}
            goBackArrow={{
              onClick: () => history.push("/contractsubmission")
            }}
            goBackText={{
              onClick: () => history.push("/contractsubmission")
            }}
            sendButton={{
              onClick: () => handleEscrowSubmit(escrowSubmitParams)
            }}
          /> :
            <PlasmicEscrowPaymentsPagePreview root={{ ref1 }} {...props}
              sendText={"Send"}
              merchantName={merchantName}
              escrowDeposit={`Requested Milestone Deposit: ${escrowPaymentPreview.escrowDeposit}`}
              milestoneOrContract={escrowPaymentPreview.milestoneOrContract}
              footerText={`Your funds will be safeguarded until ${currentUser.email} has delivered their service according to the contract terms.`}
              contractName={escrowPaymentPreview.title}
              milestoneName={escrowPaymentPreview.milestonesExist ? "Milestone 1: " + milestones[0].description : ""}
              amount={escrowPaymentPreview.escrowDeposit}
              viewDetails={{
                onClick: () => setShowSideBar(!showSideBar)
              }}
              closeButton={{
                onClick: () => setShowSideBar(!showSideBar)
              }}
              sideBarInstance={{
                wrap: node => showSideBar ? node : null
              }}
              viewContract={{
                wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
              }}
              downloadContract={{
                wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
              }}
              lowerBar={{
                wrap: node => (escrowSubmitParams.upload == null && escrowSubmitParams.contractDescription === '') ? null : node
              }}
              goBackArrow={{
                onClick: () => history.push("/contractsubmission")
              }}
              goBackText={{
                onClick: () => history.push("/contractsubmission")
              }}
              sendButton={{
                onClick: () => handleEscrowSubmit(escrowSubmitParams)
              }}
            />}
    </>
  )
}

const EscrowPaymentsPagePreview = React.forwardRef(EscrowPaymentsPagePreview_);

export default EscrowPaymentsPagePreview;