// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect } from "react";
import { PlasmicSubscriptionPaymentsPagePreview } from "./plasmic/astriusdraft/PlasmicSubscriptionPaymentsPagePreview";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { db, storage } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from "firebase/firestore";
import { getMultiFactorResolver } from "firebase/auth";
import InvoiceItem from "./InvoiceItem";
import { format } from "date-fns";
import LoadingSpinner from "./LoadingSpinner";
import Moment from "moment";
import { v4 } from "uuid";
import axios from "axios";
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";
import {PlasmicSubscriptionPaymentsPagePreviewAch} from "./plasmic/astriusdraft/PlasmicSubscriptionPaymentsPagePreviewAch";
import {PlasmicSubscriptionPaymentsPagePreviewCard} from "./plasmic/astriusdraft/PlasmicSubscriptionPaymentsPagePreviewCard";
import {PlasmicSubscriptionPaymentsPagePreviewSurcharge} from "./plasmic/astriusdraft/PlasmicSubscriptionPaymentsPagePreviewSurcharge";
import { BackIcon } from "./paymentCard/assets";
import PaymentCard from "./paymentCard";
import "./paymentCard/paymentCard.css";

import { config } from "./constants";

function SubscriptionPaymentsPagePreview_(props, ref) {
  const [loading, setLoading] = useState(true);
  const {
    currentUser,
    invoiceFields,
    setInvoiceFields,
    invoiceBalance,
    setInvoiceBalance,
    invoiceSubmitParams,
    setInvoiceSubmitParams,
  } = useAuth();
  const [merchantName, setMerchantName] = useState("");
  const [showSidebar, setShowSideBar] = useState(false);
  const [invoiceNum, setInvoiceNum] = useState(0);
  const formatDate = Moment().format("MMM Do, YYYY");
  const [loadingText, setLoadingText] = useState(false);
  const [pageType, setPageType] = useState("");
  const [approved, setApproved] = useState(false);
  const [userInfo, setuserInfo] = useState({})
  const history = useHistory();

  useEffect(() => {
    const q = query(
      collection(db, "userInfo"),
      where("user", "==", currentUser.uid)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setMerchantName(doc.data().merchantName);
        setInvoiceNum(doc.data().invoiceNum);
        setuserInfo(doc.data())
      
        // cards have to be approved and the identity also has to be approved in order for it to be approved
        setApproved(doc.data().cardApproval & doc.data().identityVerification)

      });
    });

    try {
      if (
        invoiceSubmitParams.paymentMethods.ACH &&
        !(
          invoiceSubmitParams.paymentMethods.Card ||
          invoiceSubmitParams.paymentMethods.CardSurcharge
        )
      ) {
        setPageType("ACH");
      } else if (
        (invoiceSubmitParams.paymentMethods.Card ||
          invoiceSubmitParams.paymentMethods.CardSurcharge) &&
        !invoiceSubmitParams.paymentMethods.ACH
      ) {
        setPageType("Card");
      } else if (
        invoiceSubmitParams.paymentMethods.CardSurcharge &&
        invoiceSubmitParams.paymentMethods.ACH
      ) {
        setPageType("CardSurcharge");
      }
    } catch (e) {}
    window.scrollTo({top:0, left:0, behavior: "instant"});
    setLoading(false);
  }, []);


  function formatInvoiceNum() {

    return invoiceSubmitParams.client.invoice_identifier + "-" + String(invoiceSubmitParams.client.invoiceNum).padStart(4, '0');

  }

  
  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  const handleSendInvoice = async(paymentId,city,state,country,products,userInfoDocId)=> {
     axios({
      method: "POST",
      url: `${config.endpoint}/sendInvoiceSubscription`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await currentUser.getIdToken()}`,
      },
      data: {
        clientEmail: invoiceSubmitParams.client.email,
        clientName: invoiceSubmitParams.client.company,
        merchantName: merchantName,
        merchantEmail: currentUser.email,
        amount: invoiceBalance.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
        buttonUrl: `https://app.astrius.co/subscriptionpayments/${paymentId}`,
        invoiceNum: invoiceSubmitParams.client.invoiceNum ? invoiceSubmitParams.client.invoiceNum : invoiceNum,
        invoice_identifier: invoiceSubmitParams.client.invoice_identifier ? invoiceSubmitParams.client.invoice_identifier : null,
        date: format(new Date(), "LL-dd-yy"),
        dueDate: format(invoiceSubmitParams.dueDate, "LL-dd-yy"),
        dueDate2: format(invoiceSubmitParams.dueDate, "MMMM d, y"),
        city: city,
        state: state,
        country: country,
        notes: invoiceSubmitParams.notes,
        products: products,
        invoiceFields: invoiceFields,
        userInfoDocId: userInfoDocId,
        amountNum: invoiceBalance * 100,
      },
    }).then((res)=>{
    console.log(res)
    }).catch((err)=>{console.log(err)});
  }


  async function handleSubmit() {

    if (!approved) {
      alert(
        "Payments haven't been fully approved on your account yet. We'll send you an email as soon as this has been enabled."
      );

      return;
    }
    // add webhook check here


    setLoadingText(true);

    window.addEventListener("beforeunload", handleBeforeUnload);

    try {
     
      const ip = await axios.get("https://geolocation-db.com/json/");
      var fundingSource;
      var merchantName = "";
      var invoiceNum = 0;
      var userInfoDocId = "";
      var city = "";
      var state = "";
      var country = "";
      var userData = {};

      const q = query(
        collection(db, "userInfo"),
        where("user", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        fundingSource = doc.data().fundingSource;
        merchantName = doc.data().merchantName;
        invoiceNum = doc.data().invoiceNum;
        userInfoDocId = doc.id;
        city = doc.data().city;
        state = doc.data().state;
        country = doc.data().country;
        userData = doc.data();
      });

      var paymentId = v4();
      var paymentDataId = v4();

      var products = [];

      for (var i = 0; i < invoiceFields.length; i++) {
        products.push({
          quantity: invoiceFields[i].quantity,
          description: invoiceFields[i].item,
          "tax-rate": 0,
          price: Number(invoiceFields[i].price.replace(/[^0-9\.-]+/g, "")),
        });
      }
     
      if (invoiceSubmitParams.delivery === "email") {
      handleSendInvoice(paymentId,city,state,country,products,userInfoDocId)
      }else{

        axios({
          method: "POST",
          url: `${config.endpoint}/deleteFinalLead`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await currentUser.getIdToken()}`,
          },
          data: {
            merchantEmail: currentUser.email,
          },
        })

      }


      const invoice_dueDate = format(invoiceSubmitParams.dueDate, "YYY-MM-dd");
      var response = {
        data: {
          id: null
        }
      }

     
      if(invoiceSubmitParams.client.api_deck_clientId){
        
        const params = {
          invoice: {
            type: "service",
            number: invoiceNum?.toString(),
            customer: {
              id: invoiceSubmitParams.client.api_deck_clientId,
              display_name: invoiceSubmitParams.client.contactName,
            },
            invoice_date: new Date().toString(),
            due_date: invoice_dueDate,
            terms: "one year",
            po_number: null,
            reference: "8765432",
            status: "authorised",
            invoice_sent: true,
            currency: "USD",
            currency_rate: null,
            tax_inclusive: null,
            sub_total: invoiceBalance,
            total_tax: null,
            tax_code: null,
            discount_percentage: null,
            discount_amount: null,
            total: invoiceBalance,
            balance: invoiceBalance,
            deposit: null,
            customer_memo: invoiceSubmitParams.notes,
            line_items: invoiceFields?.length
              ? invoiceFields?.map((invoice) => ({
                  row_id: invoice?.id,
                  // code: "SALES",
                  line_number: 1,
                  description: invoice?.item,
                  type: "sales_item",
                  tax_amount: 0,
                  total_amount: invoice?.balanceDue,
                  quantity: +invoice?.quantity,
                  unit_price: +invoice?.price?.substring(1),
                  unit_of_measure: "pc.",
                  discount_percentage: 0,
                  discount_amount: 0,
                  location_id: null,
                  department_id: null,
                  item: {
                    id: "",
                  },
                  // may cause issues with Xero so will have to check
                  tax_rate: {
                    id: "",
                    // code: "OUTPUT",
                    // name: "OUTPUT",
                    // rate: 0.00
                  },
                  ledger_account: {
                    id: "",
                    nominal_code: "4000",
                    code: "4000",
                  },
                  row_version: "",
                }))
              : [],
            billing_address: {
              id: "123",
              type: "primary",
              string: "25 Spring Street, Blackburn, VIC 3130",
              name: "HQ US",
              line1: "Main street",
              line2: "apt #",
              line3: "Suite #",
              line4: "delivery instructions",
              street_number: "25",
              city: "San Francisco",
              state: "CA",
              postal_code: "94104",
              country: "US",
              latitude: "40.759211",
              longitude: "-73.984638",
              county: "Santa Clara",
              contact_name: "Elon Musk",
              salutation: "Mr",
              phone_number: "111-111-1111",
              fax: "122-111-1111",
              email: invoiceSubmitParams.client.email,
              website: "https://elonmusk.com",
              row_version: "1-12345",
            },
            shipping_address: {
              id: "123",
              type: "primary",
              string: "25 Spring Street, Blackburn, VIC 3130",
              name: "HQ US",
              line1: "Main street",
              line2: "apt #",
              line3: "Suite #",
              line4: "delivery instructions",
              street_number: "25",
              city: "San Francisco",
              state: "CA",
              postal_code: "94104",
              country: "US",
              latitude: "40.759211",
              longitude: "-73.984638",
              county: "Santa Clara",
              contact_name: "Elon Musk",
              salutation: "Mr",
              phone_number: "111-111-1111",
              fax: "122-111-1111",
              email: invoiceSubmitParams.client.email,
              website: "https://elonmusk.com",
              row_version: "1-12345",
            },
            // template_id: "123456",
            source_document_url: "https://www.invoicesolution.com/invoice/123456",
            row_version: "1-12345",
          },
        };
        response = await axios({
          method: "POST",
          url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=createInvoice`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await currentUser.getIdToken()}`,
          },
          data: params,
        })
      }

      const payments = await addDoc(collection(db, "payments"), {
        api_deck_invoiceId: response ? response.data.id : null,
        user: currentUser.uid,
        userEmail: currentUser.email,
        type: "Subscription Payment",
        amount: invoiceBalance.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
        amountNum: invoiceBalance,
        paymentId: paymentId,
        status: "Not Paid",
        client: JSON.stringify(invoiceSubmitParams.client),
        clientCompany: invoiceSubmitParams.client.company,
        dateCreated: formatDate,
        paymentDataId: paymentDataId,
        datePaid: "NA",
        paymentMethod: "NA",
        paymentNotes: "",
        date: serverTimestamp(),
        clientEmail: invoiceSubmitParams.client.email,
        clientUrl: "NA",
        datePaidNum: "NA",
        fundsLocation: "NA",
        paymentSuccess: false,
        clientDocId: invoiceSubmitParams.client.docId,
        invoiceNum: invoiceSubmitParams.client.invoiceNum ? invoiceSubmitParams.client.invoiceNum : invoiceNum,
        invoice_identifier: invoiceSubmitParams.client.invoice_identifier ? invoiceSubmitParams.client.invoice_identifier : null,
        fundingDestination: typeof fundingSource === "undefined" ? "" : fundingSource,
        milestoneOrContract: "NA",
        merchantName: merchantName,
        invoiceFields: invoiceFields,
        dueDate: invoiceSubmitParams.dueDate,
        userInfoDocId: userInfoDocId,
        reminderOptions: invoiceSubmitParams.reminderOptions,
        automationOptions: invoiceSubmitParams.automationOptions,
        paymentMethods: invoiceSubmitParams.paymentMethods,
        paymentVoided: false,
        voidDate: "NA",
        contractTitle: "NA",
        merchantId:
          typeof userData.merchantId === "undefined" ? "" : userData.merchantId,
        terminalId:
          typeof userData.terminalId === "undefined" ? "" : userData.terminalId,
        zip: userData.zip,
        yourToken: v4(),
        ip: ip.data.IPv4,
        heartlandPublicKey: userData.heartlandPublicKey,
        plan: userInfo.plan,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        api_deck_ledger_id: userInfo.api_deck_ledger_id ? userInfo.api_deck_ledger_id : null,
        api_deck_supplier_id: userInfo.api_deck_supplier_id ? userInfo.api_deck_supplier_id : null,
        api_deck_bank_id: userData.api_deck_bank_id ? userData.api_deck_bank_id : null,
        /// two new fields for subscription payments as opposed to regular invoice payments
        subscriptionCycle: invoiceSubmitParams.cycle,
        subscriptionEnabled: false,
        logoUrl: userInfo?.logoUrl || "",
        color: userInfo?.color || "",
        products: products
      });
    

      await updateDoc(doc(db, "userInfo", userInfoDocId), {
        invoiceNum: invoiceNum + 1,
      });

      await updateDoc(doc(db, "clients", invoiceSubmitParams.client.docId), {
        invoiceNum: invoiceSubmitParams.client.invoiceNum ? (invoiceSubmitParams.client.invoiceNum + 1) : 1,
      })

      setInvoiceBalance(0);
      setInvoiceFields([
        { item: "", quantity: 0, price: "", id: v4(), balanceDue: 0 },
      ]);
      setInvoiceSubmitParams({
        automationOptions: { enabled: false, number: 0, unit: '' },
        client: {},
        dueDate: new Date(),
        notes: "",
        paymentMethods: { ACH: true, Card: false, Wire: true, CardSurcharge: true },
        reminderOptions: { enabled: false, number: 0, unit: '' } 
      });
      history.push(`payment/${paymentDataId}`);
    } catch (e) {
      console.log(e);
      alert("Failed to submit request, please try again");
    }

    window.removeEventListener("beforeunload", handleBeforeUnload);
  }




  function calculateEndDate(startDate, cycle) {
    const cycleMap = {
      Weekly: {number: 1, unit: 'weeks'},
      Biweekly: {number: 2, unit: 'weeks'},
      'Every 3 Weeks': {number: 3, unit: 'weeks'},
      Monthly: {number: 1, unit: 'months'},
      Bimonthly: {number: 2, unit: 'months'},
      'Every 3 Months': {number: 3, unit: 'months'},
      'Every 4 Months': {number: 4, unit: 'months'},
      'Every 5 Months': {number: 5, unit: 'months'},
      'Every 6 Months': {number: 6, unit: 'months'},
    };
  
    const startDateObj = Moment(startDate, 'MMMM D');
    const cycleUnit = cycleMap[cycle].unit;
    const cycleNumber = cycleMap[cycle].number;
    const endDateObj = startDateObj.clone().add(cycleNumber, cycleUnit);
  
  
    const endDateFormatted = endDateObj.format('MMMM D');
  
    return endDateFormatted;
  }


  const reviewData = {
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    merchantName: userInfo?.merchantName,
    type: "Subscription Payment",
    amount: invoiceBalance?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    }),
    subscription_cycle: "",
    subscriptionCycle: invoiceSubmitParams.cycle,
    date: serverTimestamp(),
    invoiceNum: invoiceNum,
    dueDate: format(invoiceSubmitParams?.dueDate, "YYY-MM-dd"),
    logoUrl: userInfo?.logoUrl || "",
    color: userInfo?.color || ""
  };
  
  
  return loading ? (
    <LoadingSpinner />
  ) : loadingText ? (
    <LoadingSpinnerWithText text={invoiceSubmitParams.delivery === "email" ? "Sending Subscription Invoice" : "Creating Subscription Invoice"} />
  ) : <div>
  <div className="send-main">
  <div className="sendContainer">
    <div className="backButtonContainer" 
    onClick={ () => history.push("/subscriptionsubmission") }>
      <img src={BackIcon} alt="Back Arrow" width={40} className="pointer" />
      <div className="goBack">Go Back</div>
    </div>
    <div className="sendButton" onClick={ () => handleSubmit() }>{invoiceSubmitParams.delivery === "no_email" ? "Create Link": "Send"}</div>
  </div>
  </div>
  {
   (!loading && Object.keys(userInfo).length) ? <PaymentCard
      component={"preview"}
      pageData={reviewData}
      pageType={pageType}
      handleBankTransfer={()=>{}}
      handleModalOpen={() => {}}
    /> : <div className={'paymentCardContainer paymentCardPreviewContainer'}></div>
  }
</div>;
}

const SubscriptionPaymentsPagePreview = React.forwardRef(
  SubscriptionPaymentsPagePreview_
);

export default SubscriptionPaymentsPagePreview;
