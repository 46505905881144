// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicSidebar } from "./plasmic/astriusdraft/PlasmicSidebar";
import { Link, useHistory } from "react-router-dom"

function Sidebar_(props, ref) {

  const history = useHistory()
  // Use PlasmicSidebar to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicSidebar are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all SidebarProps here, but feel free
  // to do whatever works for you.
  
  return <PlasmicSidebar root={{ ref }} {...props}  
  dashboardButton={{
    onClick: () => history.push("/")
  }}
  paymentsButton={{
    onClick: () => history.push("/paymentslist")
  }}
  contractsButton={{
    onClick: () => history.push("/contractslist")
  }}
  clientsButton={{
    onClick: () => history.push("/clientslist")
  }}
  settingsButton={{
    onClick: () => history.push("/settings/billing")
  }}
  rolesBtn = {{
    onClick: () => history.push("/roles")
  }}
  />;
}

const Sidebar = React.forwardRef(Sidebar_);

export default Sidebar;
