import React from 'react'
import ProgressBar from "@ramonak/react-progress-bar";

export default function ProgressBarPage(props) {
  return (
    <>
    <h1>Loading...</h1>
    <ProgressBar completed={props.completed} />
    </>
  )
}