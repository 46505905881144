// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicContractsList2 } from "./plasmic/astriusdraft/PlasmicContractsList2";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, useHits } from 'react-instantsearch-hooks-web';
import '@algolia/autocomplete-theme-classic';
import { useAuth } from "../contexts/AuthContext";
import ContractsListCard from "./ContractsListCard";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAt, endAt, startAfter, endBefore, limitToLast } from "firebase/firestore"; 
import LoadingSpinner from "./LoadingSpinner";


const algoliaClient = algoliasearch('3YSA4177MW', '0019c18519fefc703571e7786ea74dad');  


const searchClient = {

    ...algoliaClient,
  
   search(requests) {
  
  
      if (requests.every(({ params }) => !params.query)) {
        // setOpenContractSearch(false)
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        });
      }
      // setOpenContractSearch(true)
      return algoliaClient.search(requests);
    },
  };
  







function CustomHits(props) {
  const { hits, results, sendEvent, query } = useHits();
 const {  currentUser, contractHits, setContractHits, openContractSearch, setOpenContractSearch} = useAuth()


if (typeof results.query === 'undefined'){
  setOpenContractSearch(false)
} else {
  setOpenContractSearch(true)
}


setContractHits(hits)




 return(<>
  </>)
}

// if billing is an issue, then you can maybe change the instance search to a regular search to limit the number of queries. 
// Also add ranking to the index in algolia by date

function ContractsList2_(props, ref) {




  const {  currentUser, contractHits, setContractHits, openContractSearch, setOpenContractSearch} = useAuth()
  const [contracts, setContracts] = useState([])
  const [last, setLast] = useState({})
  const [first, setFirst] = useState({})
  const [showPagination, setShowPagination] = useState(false)
  const [loading, setLoading] = useState(true)


// determines if pagination is enabled or not based on how much data needs to be fetched.
  useEffect(() => {

    const q = query(collection(db, "contracts"), where("user", "==", currentUser.uid), orderBy("date", "desc"))
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempDocs = [];
      querySnapshot.forEach((doc) => {
        tempDocs.push(doc)
      });
    
   if (tempDocs.length > 10){
     setShowPagination(true)
   }
    
     
  
    });
   
  
  
  }, [])




useEffect(() => {

  const q = query(collection(db, "contracts"), where("user", "==", currentUser.uid), orderBy("date", "desc"), limit(10) )

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const tempData = [];
    const tempDocs = []
    querySnapshot.forEach((doc) => {
      tempData.push(doc.data())
      tempDocs.push(doc)
    });
  
  setContracts(tempData)

  setLast(tempDocs[tempDocs.length - 1])
  setFirst(tempDocs[0])
  
  setLoading(false)
   

  });
 


}, [])



/// function for next page pagination
function nextPage(){

// if (contracts.length == 11){

  const q = query(collection(db, "contracts"), where("user", "==", currentUser.uid), orderBy("date", "desc"), startAfter(last), limit(10) )

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const tempData = [];
    const tempDocs = []
    querySnapshot.forEach((doc) => {
      tempData.push(doc.data())
      tempDocs.push(doc)
    });
   
  if (tempDocs.length > 0){
    setLast(tempDocs[tempDocs.length - 1])
    setFirst(tempDocs[0])
  }
  


  setContracts(tempData)

  });


}
/// function for last page pagination

function prevPage(){

  const q = query(collection(db, "contracts"), where("user", "==", currentUser.uid), orderBy("date", "desc"), endBefore(first), limitToLast(10) )

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const tempData = [];
    const tempDocs = []
    querySnapshot.forEach((doc) => {
      tempData.push(doc.data())
      tempDocs.push(doc)
    });
   
    if (tempDocs.length > 0){
      setLast(tempDocs[tempDocs.length - 1])
      setFirst(tempDocs[0])
    }

  setContracts(tempData)

  });

}



  return <PlasmicContractsList2 root={{ ref }} {...props} searchVertStack={{
    children:
    <>
     <InstantSearch searchClient={searchClient} indexName="Firebase_Contract_Collection"  >
      <Configure />
      <SearchBox className="searchbox" placeholder="Search..." />
      <CustomHits /> 
    </InstantSearch>
    </>
  }}
  contractCardsStack={{
    children:
    <>
    {openContractSearch ? contractHits.map( x=> {

      if ( x.user == currentUser.uid){
        return <ContractsListCard title={x.title} client={x.clientCompany} contractStatus={x.status} totalAmount={x.price.toLocaleString('en-US', {style: 'currency', currency: 'USD', })} date={x.displayDate} id={x.id} contractCompleted={x.contractCompleted}/>
      }
     
    }) : contracts.map( x => {
      return <ContractsListCard title={x.title} client={x.clientCompany} contractStatus={x.status} totalAmount={x.price.toLocaleString('en-US', {style: 'currency', currency: 'USD', })} date={x.displayDate} id={x.id} contractCompleted={x.contractCompleted}/>
    })
    }
    </>
  }}
  paginationStack={{
    wrap: node => showPagination ? node : null
  }}
  nextButton={{
    onClick: () => nextPage()
  }}
  prevButton={{
    onClick: () => prevPage()
  }}
  vertStack={ loading && {
    children: <LoadingSpinner/>
  }}
  />;
}

const ContractsList2 = React.forwardRef(ContractsList2_);

export default ContractsList2;
