import * as React from "react";
import { PlasmicSelectBusinessType__Overlay } from "./plasmic/astriusdraft/PlasmicSelectBusinessType__Overlay";

function SelectBusinessType__Overlay_(props, ref) {
  const { plasmicProps } = PlasmicSelectBusinessType__Overlay.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectBusinessType__Overlay {...plasmicProps} />;
}

const SelectBusinessType__Overlay = React.forwardRef(
  SelectBusinessType__Overlay_
);

export default Object.assign(SelectBusinessType__Overlay, {
  __plumeType: "triggered-overlay"
});
