import React, {useState, useEffect} from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore"; 
import { useHistory } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

export default function OnboardVerifiedRoute({ component: Component, ...rest }) {
  const { currentUser, globalLoading } = useAuth()
  const [loading, setLoading] = useState(true)
  const [onboarded, setOnboarded] = useState(false);
  const [bankingPage, setBankingPage] = useState(false)
  const [planPage, setPlanPage] = useState(false)
  const [confirmPage, setConfirmPage] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (!currentUser) return setLoading(false);

    const q = query(
      collection(db, 'userInfo'),
      where('user', '==', currentUser.uid)
    );

    onSnapshot(q, (snapshot) => {
      if (snapshot.empty){
        return setLoading(false);
      } else {
        const userInfo = snapshot.docs[0].data();

        // sets onboarded to true for now if the user document exists.

        // complete this code to check if the user has completed all the onboarding steps
        // also consider adding an area that marks the database as true if onboaridng is complete. 
        // use the sean1107obrien+933223@gmail.com account as a reference for if its working without having the other part enabled

        if (userInfo.hasOwnProperty('onboarding')) {

        if (userInfo.onboarding.setupDetailsComplete && !(userInfo.onboarding.bankingDetailsComplete)) {
          setBankingPage(true)
        } else if (userInfo.onboarding.bankingDetailsComplete && !(userInfo.onboarding.planInformationComplete)) {
          setPlanPage(true)
        } else if (userInfo.onboarding.planInformationComplete && !(userInfo.onboarding.confirmIdentityStarted)) {
          setConfirmPage(true)
        } else {
          setOnboarded(true)
        }


      } else {
        setOnboarded(true)

      }

        

        return setLoading(false);

      }

      
    } );
  }, [currentUser]);

  if (globalLoading || loading) return <LoadingSpinner/>;

  return (
    <Route
      {...rest}
      render={props => {


        if (currentUser) {
           

            if (onboarded){
                return <Component {...props} />
            } else if (bankingPage) {   
              return <Redirect to="/onboarding-banking-details"/>
            } else if (planPage) {
              return <Redirect to="/onboarding-plan-information"/>
            } else if (confirmPage) {
              return <Redirect to="/onboarding-confirm-identity"/>
            } else {
                return <Redirect to="/get-started"/>
            }

          
        } else {
          return <Redirect to="/login" />
        }
       

      }}
    ></Route>
  )
    }