// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Image1SvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.0"}
      viewBox={"0 0 24 24"}
      preserveAspectRatio={"xMidYMid meet"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7 10.5c-5.4 5.5-4.5 7.4 1 2l4-3.9 4 3.9c2.2 2.1 4.3 3.6 4.7 3.2C21.3 15 13.3 6 12 6c-.3 0-2.6 2-5 4.5z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Image1SvgIcon;
/* prettier-ignore-end */
