// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicPaymentsPagePreview } from "./plasmic/astriusdraft/PlasmicPaymentsPagePreview";
import {useAuth} from "../contexts/AuthContext"
import { useHistory } from 'react-router-dom';
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore"; 
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, listAll, list } from "firebase/storage";
import {parse, stringify, toJSON, fromJSON} from 'flatted';
import { v4 } from "uuid";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import { config } from './constants'

function PaymentsPagePreview_(props, ref1) {
  const [loading, setLoading] = useState(false);

  const {currentUser, escrowPaymentPreview, escrowSubmitParams, milestones, handleSetMilestones, showSideBar, setShowSideBar} = useAuth()

  const history = useHistory()

  async function uploadFile(upload){

    if (upload == null) return '';

    const path = `contractFile/${upload.name + v4()}`

    const imageRef = ref(storage, path);

    uploadBytes(imageRef, upload).then((snapshot) => {
      

    });

    return path    

}


async function handleEscrowSubmit({title, price, upload, milestonesExist, paymentMethods, dueDate, automationOptions, reminderOptions, client}){



  setLoading(true)
  
  const path = await uploadFile(upload)

  const id = v4()

  const paymentId = v4()

if (milestonesExist){
  const temp = milestones
  temp[0].paymentId = paymentId
  handleSetMilestones(temp)
}


const uploadName = ''

try {
  uploadName = upload.name
} catch (e){

}

/// again, change the company name to the actual company name once the onboarding has been completed. 


// have a payments link id in the url, allowing the database to search for the contract containing that link. It will then check a couple of factors in the contract database item,
// such as do milestones exist and if so, what milestone number are they on, and then display data to the screen depending on that info via a useEffect(). For example, no milestones exist means the price
// amount would just be the contract amount, and milestones do exist would then be the amount in whatever milestone number it's on. That means you'll have to continually change the 
// current payments id in the contract so that you can keep fetching that data. 
// or you can have create a seperate databse item called payments that has the URL, and then 


  const escrowsubmisionsInstance = await addDoc(collection(db, "contracts"), {
     title: title,
     price: price,
     user: currentUser.uid,
     contractFile: path,
     contractFileName: uploadName,
     milestonesExist: milestonesExist,
     dueDate: dueDate,
     paymentMethods: JSON.stringify(paymentMethods),
     automationOptions: JSON.stringify(automationOptions),
     reminderOptions: JSON.stringify(reminderOptions),
     client: JSON.stringify(client),
     date: serverTimestamp(),
     cardDisplayDate: serverTimestamp().toDate().toDateString(),
     id: id,
     currentMilestone: 1,
     currentPaymentsLinkId: "",
     status: "Awaiting Escrow Payment",
     milestones: milestones,
     paymentId: paymentId
    })



    const escrowPayments = await addDoc(collection(db, "escrowPayments"), {
      user: currentUser.uid,
      escrowDeposit: escrowPaymentPreview.escrowDeposit,
      vendorType: "service",
      paymentMethods: JSON.stringify(paymentMethods),
      automationOptions: JSON.stringify(automationOptions),
      reminderOptions: JSON.stringify(reminderOptions),
      contractId: id,
      paymentId: paymentId,
      contractName: escrowPaymentPreview.title,
      milestoneOrContract: escrowPaymentPreview.milestoneOrContract,
      sideBarMilestoneName : escrowPaymentPreview.milestonesExist ? "Milestone 1: " + milestones[0].description : "",
      contractFileName: uploadName,
    })
 

    // try {
    //   const resp = await axios.post('https://us-central1-astrius-dev.cloudfunctions.net/sendEmail', {email: client.email, companyName: currentUser.email, amount: escrowPaymentPreview.escrowDeposit, vendorType: "service", buttonUrl: `http://localhost:3000/escrowpayments${paymentId}` })
    // } catch(e){
    //   alert("Failed to submit request, please try again")
    // }
    


  //  for (var i = 0; i < milestones.length; i++){
  //    await addDoc(collection(escrowsubmisionsInstance, "Milestone Entries"), {
  //      milestoneTitle: milestones[i].description,
  //      milestonePrice: milestones[i].amount,
  //      dueDate: milestones[i].dueDate,
  //      milestoneNumber: i + 1,
  //     })
    
  //  }

setLoading(false)

history.push(`/contract/${id}`)

}


  

  return (
    <>
  {loading ?  <LoadingSpinner/> :
  <PlasmicPaymentsPagePreview root={{ ref1 }} {...props} 
  sendText={"Send"}
  merchantName={currentUser.email}
  escrowDeposit={`Requested Escrow Deposit: ${escrowPaymentPreview.escrowDeposit}`}
  milestoneOrContract={escrowPaymentPreview.milestoneOrContract}
  footerText={`Your funds will be safeguarded until ${currentUser.email} has delivered their service according to the contract terms.`}
  viewDetails={{
    onClick: () => setShowSideBar(!showSideBar)
  }}
  paymentsSideBar={{
    wrap: node => showSideBar ? node : null,
    props: {
      contractName: escrowPaymentPreview.title,
      milestoneName: escrowPaymentPreview.milestonesExist ? "Milestone 1: " + milestones[0].description : "",
      amount: escrowPaymentPreview.escrowDeposit

    }
  }}
  goBackArrow={{
    onClick: () => history.push("/escrowpayment")
  }}
  goBackText={{
    onClick: () => history.push("/escrowpayment")
  }}
  sendButton={{
   onClick: () => handleEscrowSubmit(escrowSubmitParams)
  }}
  /> } 
  </>
  )
}

const PaymentsPagePreview = React.forwardRef(PaymentsPagePreview_);

export default PaymentsPagePreview;
