// Link in email can be changed here: https://console.firebase.google.com/u/3/project/astrius-dev/authentication/emails
import { applyActionCode, verifyPasswordResetCode } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {isMobile} from 'react-device-detect';
import { auth } from '../firebase';
import { PlasmicGetStarted2 } from "./plasmic/astriusdraft/PlasmicGetStarted2";
import axios from 'axios';
import { config } from "./constants";
import CreateNewPassword from "./CreateNewPassword";


const VerifyEmail = () => {
  const { currentUser, setIsVerified } = useAuth();
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [actionCode, setActionCode] = useState(null);
  // ?mode=action&oobCode=code
  const { search } = useLocation();
  const history = useHistory();

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    const mode = searchParams.get('mode');
    const actionCode = searchParams.get('oobCode');
    setActionCode(actionCode)

    if (actionCode == null) {
      setHasError(true);
      setLoading(false);
      return;
    } else if (mode === 'resetPassword') {
      
      verifyPasswordResetCode(auth, actionCode).then( async () => {
        
        // now set the state to the plasmic wrapper page to enter the new password and call confirmPasswordReset as described here https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#confirmpasswordreset
        setResetPassword(true);
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setHasError(true);
      })

    } else if (mode === 'verifyEmail' ) {

    applyActionCode(auth, actionCode)
      .then( async () => {
        if (currentUser != null) {


        currentUser.reload().then(async () => {
          
          
          history.push('/get-started')
        
        })

        } else {
          setLoading(false);
        }

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setHasError(true);
      })

    } else {
      setHasError(true);
      setLoading(false);
      return;
    }

  }, []);

  if (loading) {
    return <></>;
  }

  if (hasError) {
    return <div>Invalid Verification Link</div>;
  } else if (resetPassword) {
    return <CreateNewPassword code={actionCode}/>;
  } else {

    if (isMobile){
      return <PlasmicGetStarted2/>
    } else {

      return (
        <div>
          Your email has been verified. Please return to the site and login again to setup your
          profile.
        </div>
      );

    }

  }
};

export default VerifyEmail;
