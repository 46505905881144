import algoliasearch from 'algoliasearch/lite';
import React, { useCallback } from 'react';
import { InstantSearch, SearchBox, Hits, Configure, useHits } from 'react-instantsearch-hooks-web';
import SearchResults from "./SearchResults"

const searchClient = algoliasearch('3YSA4177MW', '0019c18519fefc703571e7786ea74dad');  

// instantsearch.widgets.hits({
//     container: "#Hits"
//   });

// function Hit({ hit }) {
//     return (
//         JSON.stringify(hit)
//         // <>
//         // <SearchResults companyName={hit.company} emailAddress={hit.email}/>
//         // </>
//     );
//   }

function CustomHits(props) {
    const { hits, results, sendEvent } = useHits(props);
    

    return (
        hits.map(x => {
            return <SearchResults companyName={x.company} emailAddress={x.email} />
        })
    )
  }


export default function Search() {
    
  return (
      <>
      <h1>hello</h1>
      <InstantSearch searchClient={searchClient} indexName="Firebase_Client_Collection">
      <Configure hitsPerPage={3} />
      <SearchBox
      placeholder="search for products"/>
    <CustomHits />
    </InstantSearch>
      </>
    
  )
}
