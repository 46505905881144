// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicOnboardingPersonalSuccess } from "./plasmic/astriusdraft/PlasmicOnboardingPersonalSuccess";
import LoadingSpinner from "./LoadingSpinner";
import {useHistory} from "react-router-dom"
import {useAuth} from "../contexts/AuthContext"

function OnboardingPersonalSuccess_(props, ref) {

  const {onboardModal, setOnboardModal} = useAuth()
  const history = useHistory()
  

  return onboardModal ? <LoadingSpinner/> : <PlasmicOnboardingPersonalSuccess root={{ ref }} {...props} continueButton={{onClick: () => history.push("/")}}/>;
}

const OnboardingPersonalSuccess = React.forwardRef(OnboardingPersonalSuccess_);

export default OnboardingPersonalSuccess;
