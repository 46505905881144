// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useRef, useEffect} from "react";
import { PlasmicNewSetupDetails } from "./plasmic/astriusdraft/PlasmicNewSetupDetails";
import { useHistory } from 'react-router-dom';
import {useAuth} from "../contexts/AuthContext"
import DatePicker from "react-datepicker";
import {db} from "../firebase"
import "react-datepicker/dist/react-datepicker.css";
import "./Onboarding.css"
import useLocalStorage from "../hooks/useLocalStorage";
import CurrencyInput from "react-currency-input-field";
import "./Boarding.css"
import { hotjar } from 'react-hotjar';
import LoadingSpinner2 from "./LoadingSpinner2";
import { config } from './constants'
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from "firebase/firestore";
import { v4 } from "uuid";
import axios from 'axios';


hotjar.initialize(3206049, 6);

function NewSetupDetails_(props, ref) {
  const history = useHistory()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [industry, setIndustry] = useState('')
  const [businessType, setBusinessType] = useState('')
  const [loading, setLoading] = useState(true)
  const [website, setWebsite] = useState('')
  const [error, setError] = useState(false)
  const [docId, setDocId] = useState('')
  const [setupDetailsComplete, setSetupDetailsComplete] = useState(false)
  const {currentUser} = useAuth()

  const monthlyVolume = useRef(null)
  const averageTicket = useRef(null)

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  useEffect(async () => {

    window.addEventListener("beforeunload", handleBeforeUnload);

    const q = query(
      collection(db, "userInfo"),
      where("user", "==", currentUser.uid)
    );

    const querySnapshot = await getDocs(q);

      if (querySnapshot.empty){
        setDocId(v4())
        setSetupDetailsComplete(false)
        setLoading(false)
      } else {

        querySnapshot.forEach((doc) => {
        
          console.log(doc.data().firstName)

          setFirstName(doc.data().firstName)
          setLastName(doc.data().lastName)
          setIndustry(doc.data().industry)
          setBusinessType(doc.data().businessType)
          setWebsite(doc.data().website)
          setDocId(doc.id)

          // figure out why it isn't letting you do this.
          // also make sure that the select components are working
          // monthlyVolume.current.value = Number(doc.data().monthlyVolume)
          // averageTicket.current.value = Number(doc.data().averageTicket)
         
          setLoading(false)

        });
        
      }  
    

  }, [] )


  return <PlasmicNewSetupDetails root={{ ref }} {...props} 
  vertStack={ loading && {
    children: <LoadingSpinner2/>
  }}
  firstName={{
    value: firstName,
    onChange: (e) => {
      setFirstName(e.target.value)
    }
  }}
  lastName={{
    value: lastName,
    onChange: (e) => {
      setLastName(e.target.value)
    }
  }} 
  selectBusinessType={{
    value: businessType,
    onChange: (e) => {
      setBusinessType(e)
    }
  }}
  selectIndustry={{
    value: industry,
    onChange: (e) => {
      setIndustry(e)
    }
  }}
  website={{
    value: website,
    onChange: (e) => {
      setWebsite(e.target.value)
    }
  }}
  monthlyVolume={{
    children: <CurrencyInput ref={monthlyVolume}
    className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  averageTicket={{
    children: <CurrencyInput ref={averageTicket}
    className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  errorText={{
    wrap: node => error ? node : null
  }}
  continueButton={{
    onClick: async () => {

      var mcc = ''
      var productDescription = ''
      var commodityCode = ''

      switch (industry){
        case "9ed38146-7d6f-11e3-89d6-5404a6144203":
          productDescription = "Marketing Services"
          mcc = '7311'
          commodityCode = '915-01'
          break;
        case "9ed46b9e-7d6f-11e3-9fec-5404a6144203":
          productDescription = "Graphic Design Services"
          mcc = '7333'
          commodityCode = '915-96'
          break;
        case "9ed3a851-7d6f-11e3-92a4-5404a6144203":
          productDescription = "Software Product/Service"
          mcc = '7372'
          commodityCode = '920-40'
          break;
        case "9ed3cf60-7d6f-11e3-a80d-5404a6144203":
          productDescription = "Accounting Services"
          mcc = '8931'
          commodityCode = '946-11'
          break;
        case "9ed3814f-7d6f-11e3-aaf6-5404a6144203":
          productDescription = "Wholesale Products"
          mcc = "5300"
          commodityCode = '010-83'
          break;
        case "9ed3813d-7d6f-11e3-bd65-5404a6144203":
          productDescription = "Education Services"
          mcc = '8299'
          commodityCode = '924-18'
          break;
        case "9ed3813c-7d6f-11e3-82cc-5404a6144203":
          productDescription = "Consulting Services"
          mcc = '7392';
          commodityCode = '918-20'
          break;
        case "9ed3cf63-7d6f-11e3-b50c-5404a6144203":
          productDescription = "Investment Services"
          mcc = "8999"
          commodityCode = "946-56"
          break;
        case "9ed46b95-7d6f-11e3-970b-5404a6144203":
          productDescription = "eCommerce Services"
          mcc = "7399"
          commodityCode = "918-36"
          break;
        case "9ed3a853-7d6f-11e3-a35d-5404a6144203":
          productDescription = "Web Hosting and Design Service"
          mcc = "7372"
          commodityCode = "209-31"
          break;
        case "9ed4449a-7d6f-11e3-a380-5404a6144203":
          productDescription = "Retail Products"
          mcc = "5999"
          commodityCode = '918-70'
          break;
        case "9ed41d7a-7d6f-11e3-ae21-5404a6144203":
          productDescription = "Construction Services"
          mcc = '8999'
          commodityCode = '918-31'
          break;
        case "9ed46ba6-7d6f-11e3-ae95-5404a6144203":
          productDescription = "Legal Services"
          mcc = '8111'
          commodityCode = "961-49"
          break;
        case "9ed35a38-7d6f-11e3-a24f-5404a6144203":
          productDescription = "Publishing Services"
          mcc = '2741'
          commodityCode = "961-90"
          break;
        case "9ed444a6-7d6f-11e3-ad1c-5404a6144203":
          productDescription = "Photography Services"
          mcc = '7333'
          commodityCode = "915-72"
          break;
        case "9ed492a5-7d6f-11e3-8d8f-5404a6144203":
          productDescription = "Telecommunication Services"
          mcc = '4814'
          commodityCode = "915-79"
          break;
        case "9ed4449f-7d6f-11e3-b867-5404a6144203":
          productDescription = "Public Relation Services"
          mcc = '7392'
          commodityCode = "915-03"
          break;
        case "9ed46ba3-7d6f-11e3-9efe-5404a6144203":
          productDescription = "Insurance Services"
          mcc = '5960'
          commodityCode = "953-56"
          break;
        case "9ed492ab-7d6f-11e3-9907-5404a6144203":
          productDescription = "Real Estate Services"
          mcc = '6513'
          commodityCode = "958-83"
          break;
        case "9ed3814e-7d6f-11e3-9087-5404a6144203":
          productDescription = "Secretarial Services"
          mcc = '7339'
          commodityCode = "961-02"
          break;
        case "9ed46b9a-7d6f-11e3-a11b-5404a6144203":
          productDescription = "Event Planning Services"
          mcc = '8999'
          commodityCode = "962-34"
          break;
        case "9ed3f679-7d6f-11e3-a884-5404a6144203":
          productDescription = "Gifting Services"
          mcc = '5947'
          commodityCode = "037-43"
          break;
        case "9ed4b9bb-7d6f-11e3-af9f-5404a6144203":
          productDescription = "Travel Agency Services"
          mcc = '4722'
          commodityCode = "961-78"
          break;
        case "9ed38143-7d6f-11e3-bc00-5404a6144203":
          productDescription = "Recruiting Services"
          mcc = '7361'
          commodityCode = "918-85"
          break;
        case "9ed3cf5a-7d6f-11e3-9a99-5404a6144203":
          productDescription = "Digital Media"
          mcc = "7311"
          commodityCode = "052-48"
          break;
          
      }

// make sure the product description select error gets fixed

      if (firstName === '' || lastName  === '' || monthlyVolume.current.value === '' || averageTicket.current.value === '' || industry === '' || website === '' || businessType === ''){
        setError(true)

console.log({firstName: firstName, lastName: lastName, monthlyVolume: monthlyVolume.current.value, averageTicket: averageTicket.current.value, industry: industry, website: website, businessType: businessType})

      } else {

        setLoading(true)


        history.push("/onboarding-banking-details")

        if(setupDetailsComplete){

          await updateDoc(doc(db, "userInfo", docId), {
            user: currentUser.uid,
            email: currentUser.email,
            firstName: firstName,
            lastName: lastName,
            date: new Date(),
            monthlyVolume: monthlyVolume.current.value,
            averageTicket: averageTicket.current.value,
            industryCode: industry,
            productDescription: productDescription,
            website: website,
            businessType: businessType,
            invoiceNum: 1,
            customPrice: 0,
            customLimit: 0,
            monthlyAmount: 0,
            cardApproval: false,
            verificationStatus: "NA",
            identityVerification: false,
            propayApproval: false,
            heartlandPublicKey: "",
            heartlandSecretKey: "",
            fraudCount: 0,
            onboarding: {
              setupDetailsComplete: true,
              bankingDetailsComplete: false,
              planInformationComplete: false,
              confirmIdentityStarted: false,
            }
          })

        } else {

        await setDoc(doc(db, "userInfo", docId), {
          user: currentUser.uid,
          email: currentUser.email,
          firstName: firstName,
          lastName: lastName,
          date: new Date(),
          monthlyVolume: monthlyVolume.current.value,
          averageTicket: averageTicket.current.value,
          industryCode: industry,
          productDescription: productDescription,
          website: website,
          businessType: businessType,
          invoiceNum: 1,
          customPrice: 0,
          customLimit: 0,
          monthlyAmount: 0,
          cardApproval: false,
          verificationStatus: "NA",
          identityVerification: false,
          propayApproval: false,
          heartlandPublicKey: "",
          heartlandSecretKey: "",
          fraudCount: 0,
          onboarding: {
            setupDetailsComplete: true,
            bankingDetailsComplete: false,
            planInformationComplete: false,
            confirmIdentityStarted: false,
          }
        })

      }

      axios({
        method: "POST",
        url: `${config.endpoint}/crmFunctions-updateLeads`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await currentUser.getIdToken()}`,
        },
        data: {
          email: currentUser.email,
          firstName: firstName,
        }
      })


      setLoading(false)

        // change this when the URL
        

      }
    } }}
  />;
}

const NewSetupDetails = React.forwardRef(NewSetupDetails_);

export default NewSetupDetails;
