// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicCardModalHeader } from "./plasmic/astriusdraft/PlasmicCardModalHeader";
import {useAuth} from "../contexts/AuthContext"

function CardModalHeader_(props, ref) {
  const {closeCardModal, setCloseCardModal} = useAuth()


  return <PlasmicCardModalHeader root={{ ref }} {...props} 
  closeButton={{
     onClick: () => setCloseCardModal(false),
     style: {color: props?.color || "#3555ff"}
    }}
  />;
}

const CardModalHeader = React.forwardRef(CardModalHeader_);

export default CardModalHeader;
