// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicPaymentsList } from "./plasmic/astriusdraft/PlasmicPaymentsList";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, useHits } from 'react-instantsearch-hooks-web';
import '@algolia/autocomplete-theme-classic';
import { useAuth } from "../contexts/AuthContext";
import ContractsListCard from "./ContractsListCard";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAt, endAt, startAfter, endBefore, limitToLast } from "firebase/firestore"; 
import PaymentsListCard from "./PaymentsListCard";
import LoadingSpinner from "./LoadingSpinner";


const algoliaClient = algoliasearch('3YSA4177MW', '0019c18519fefc703571e7786ea74dad');  


const searchClient = {

    ...algoliaClient,
  
   search(requests) {
  
  
      if (requests.every(({ params }) => !params.query)) {
        // setOpenContractSearch(false)
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        });
      }
      // setOpenContractSearch(true)
      return algoliaClient.search(requests);
    },
  };
  


  function CustomHits(props) {
    const { hits, results, sendEvent, query } = useHits();
   const {  currentUser,  paymentHits, setPaymentHits, openPaymentSearch, setOpenPaymentSearch} = useAuth()
  
  
  if (typeof results.query === 'undefined'){
    setOpenPaymentSearch(false)
  } else {
    setOpenPaymentSearch(true)
  }
  
  
  setPaymentHits(hits)
  
  
  
  
   return(<>
    </>)
  }



function PaymentsList_(props, ref) {
  const {  currentUser,  paymentHits, setPaymentHits, openPaymentSearch, setOpenPaymentSearch} = useAuth()
 const [payments, setPayments] = useState([])
 const [last, setLast] = useState({})
 const [first, setFirst] = useState({})
 const [showPagination, setShowPagination] = useState(false)
 const [loading, setLoading] = useState(true)


 useEffect(() => {

  const q = query(collection(db, "payments"), where("user", "==", currentUser.uid), orderBy("date", "desc"))

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const tempDocs = [];
    querySnapshot.forEach((doc) => {
      tempDocs.push(doc)
    });
  
 if (tempDocs.length > 10){
   setShowPagination(true)
 }
  
   

  });
  

}, [])




useEffect(() => {

const q = query(collection(db, "payments"), where("user", "==", currentUser.uid), orderBy("date", "desc"), limit(10) )

const unsubscribe = onSnapshot(q, (querySnapshot) => {
  const tempData = [];
  const tempDocs = []
  querySnapshot.forEach((doc) => {
    tempData.push(doc.data())
    tempDocs.push(doc)
  });

setPayments(tempData)

setLast(tempDocs[tempDocs.length - 1])
setFirst(tempDocs[0])

setLoading(false)
 

});



}, [])



/// function for next page pagination
function nextPage(){

// if (contracts.length == 11){

const q = query(collection(db, "payments"), where("user", "==", currentUser.uid), orderBy("date", "desc"), startAfter(last), limit(10) )

const unsubscribe = onSnapshot(q, (querySnapshot) => {
  const tempData = [];
  const tempDocs = []
  querySnapshot.forEach((doc) => {
    tempData.push(doc.data())
    tempDocs.push(doc)
  });
 
if (tempDocs.length > 0){
  setLast(tempDocs[tempDocs.length - 1])
  setFirst(tempDocs[0])
}



setPayments(tempData)

});


}


/// function for last page pagination

function prevPage(){

const q = query(collection(db, "payments"), where("user", "==", currentUser.uid), orderBy("date", "desc"), endBefore(first), limitToLast(10) )

const unsubscribe = onSnapshot(q, (querySnapshot) => {
  const tempData = [];
  const tempDocs = []
  querySnapshot.forEach((doc) => {
    tempData.push(doc.data())
    tempDocs.push(doc)
  });
 
  if (tempDocs.length > 0){
    setLast(tempDocs[tempDocs.length - 1])
    setFirst(tempDocs[0])
  }

setPayments(tempData)

});

}






  return <PlasmicPaymentsList root={{ ref }} {...props} 
  searchVertStack={{
    children:
    <>
     <InstantSearch searchClient={searchClient} indexName="Firebase_Payments_Collection"  >
      <Configure />
      <SearchBox className="searchbox" placeholder="Search..." />
      <CustomHits /> 
    </InstantSearch>
    </>
  }}
  paymentsListStack={{
    children:
    <>
    {openPaymentSearch ? paymentHits.map(x => {


       if ( x.user == currentUser.uid){
        return <PaymentsListCard key={x.paymentDataId} amount={x.oldAmount ? x.oldAmount : x.amountNum.toLocaleString('en-US', {style: 'currency', currency: 'USD', })} clientCompany={x.clientCompany} paidStatus={x.status} paymentType={x.type} dateCreated={x.dateCreated} id={x.paymentDataId} paymentVoided={x.paymentVoided}/>
      }

    }) : payments.map(x => {

      return <PaymentsListCard key={x.paymentDataId} amount={x.oldAmount ? x.oldAmount : x.amountNum.toLocaleString('en-US', {style: 'currency', currency: 'USD', })} clientCompany={x.clientCompany} paidStatus={x.status} paymentType={x.type} dateCreated={x.dateCreated} id={x.paymentDataId} paymentVoided={x.paymentVoided}/>
    })}
    </>
  }}
  paginationStack={{
    wrap: node => showPagination ? node : null
  }}
  nextButton={{
    onClick: () => nextPage()
  }}
  prevButton={{
    onClick: () => prevPage()
  }}
  vertStack={ loading && {
    children: <LoadingSpinner/>
  }}
  />;
}

const PaymentsList = React.forwardRef(PaymentsList_);

export default PaymentsList;
