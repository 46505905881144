// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function IconIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 330 330"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M216.358 271.76a15.001 15.001 0 00-13.858-9.26H180V15c0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15v247.5h-22.5a15 15 0 00-10.606 25.607l37.5 37.5C157.322 328.536 161.161 330 165 330s7.678-1.464 10.607-4.394l37.5-37.5a15 15 0 003.251-16.346z"
        }
      ></path>
    </svg>
  );
}

export default IconIcon;
/* prettier-ignore-end */
