// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Layer1Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 17 11"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M15.838 4.593L11.16.175A.69.69 0 0010.692 0a.689.689 0 00-.465.183.61.61 0 00-.196.437.608.608 0 00.185.441l3.515 3.32H.708a.706.706 0 00-.498.17.63.63 0 00-.155.208.596.596 0 00.155.707.706.706 0 00.498.17h13.076l-3.566 3.317a.63.63 0 00-.161.204.597.597 0 00.131.708.704.704 0 00.97-.023l4.677-4.36a.596.596 0 000-.889h.003z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Layer1Icon;
/* prettier-ignore-end */
