import React, {useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

export default function TestModal() {
   
async function testTransfer(){





}


  return (
    <>
    <button onClick={testTransfer}>click</button>
  </>
  )
}
