import * as React from "react";
import { PlasmicCheckbox3 } from "./plasmic/astriusdraft/PlasmicCheckbox3";

function Checkbox3_(props, ref) {
  const { plasmicProps, state } = PlasmicCheckbox3.useBehavior(props, ref);
  return <PlasmicCheckbox3 {...plasmicProps} />;
}

const Checkbox3 = React.forwardRef(Checkbox3_);

export default Object.assign(Checkbox3, {
  __plumeType: "checkbox"
});
