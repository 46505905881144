// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicPaymentSuccess } from "./plasmic/astriusdraft/PlasmicPaymentSuccess";
import axios from "axios";
import { db } from "../firebase"
import { getDoc, doc } from "firebase/firestore"; 
import LoadingSpinner from "./LoadingSpinner";
import { config } from './constants'

function PaymentSuccess_(props, ref) {
 const [loading, setLoading] = useState(false)

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "receipt.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    setLoading(false)
    downloadLink.click();
  }

  return <PlasmicPaymentSuccess root={{ ref }} {...props}
  rectangle={loading && {
    children: <LoadingSpinner/>
  }}
  downloadReciept={{
    onClick: async () => {

setLoading(true)

    var items = []

    if (props.screenData.type === "Milestone Payment"){
      items.push({item: props.screenData.milestoneOrContract, description: "", quantity: 1, amount: props.screenData.amountNum * 100})
    } else{
    
      var invoiceFields =  props.screenData.invoiceFields

      for (var i = 0; i < invoiceFields.length; i++){
        items.push({item: invoiceFields[i].item, description: "", quantity: invoiceFields[i].quantity, amount: invoiceFields[i].balanceDue * 100})
      }

    }

    var amount = props.screenData.amountNum

    if (typeof props.screenData.surchargedAmount !== 'undefined') {

      amount = props.screenData.surchargedAmount

      if (Number(props.screenData.surchargedAmount) !== props.screenData.amountNum){
        items.push({item: "Card processing fee", description: "", quantity: 1, amount: (props.screenData.surchargedAmount - props.screenData.amountNum) * 100 })
      }

    } 


 const receipt = await axios({
  method: "POST",
  url: `${config.endpoint}/sendReceipt`,
  headers: {
    "Content-Type": "application/json",
  },
  data: {
    userInfoDocId: props.screenData.userInfoDocId,
    clientCompany: props.screenData.clientCompany,
    clientEmail: props.screenData.clientEmail,
    items: items,
    amount: amount * 100,
  }
 })

 

 downloadPDF(receipt.data)

     
    }
  }}
  />;
}

const PaymentSuccess = React.forwardRef(PaymentSuccess_);

export default PaymentSuccess;
