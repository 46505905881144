// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicReviewTransactions } from "./plasmic/astriusdraft/PlasmicReviewTransactions";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, useHits } from 'react-instantsearch-hooks-web';
import '@algolia/autocomplete-theme-classic';
import { useAuth } from "../contexts/AuthContext";
import ContractsListCard from "./ContractsListCard";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAt, endAt, startAfter, endBefore, limitToLast } from "firebase/firestore"; 
import ReviewTransactionsCard from "./ReviewTransactionsCard"
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";


function ReviewTransactions_(props, ref) {

  const {  currentUser,  paymentHits, setPaymentHits, openPaymentSearch, setOpenPaymentSearch} = useAuth()
  const [payments, setPayments] = useState([])
  const [last, setLast] = useState({})
  const [first, setFirst] = useState({})
  const [showPagination, setShowPagination] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {

    // finish applying this whre clause to all queries and also ensure that it still works without any bugs. 
    const q = query(collection(db, "payments"), where('charged', '==', false), orderBy("date", "desc"))
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempDocs = [];
      querySnapshot.forEach((doc) => {
        tempDocs.push(doc)
      });
    
   if (tempDocs.length > 10){
     setShowPagination(true)
   }
      
  
    });
    
  
  }, [])


  useEffect(() => {

    const q = query(collection(db, "payments"), where('charged', '==', false), orderBy("date", "desc"), limit(10) )
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      const tempDocs = []
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
        tempDocs.push(doc)
      });
    
    setPayments(tempData)
    
    setLast(tempDocs[tempDocs.length - 1])
    setFirst(tempDocs[0])
    
    setLoading(false)
     
    
    });
    
    
    
    }, [])


    function nextPage() {

      // if (contracts.length == 11){
      
      const q = query(collection(db, "payments"), where('charged', '==', false), orderBy("date", "desc"), startAfter(last), limit(10) )
      
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tempData = [];
        const tempDocs = []
        querySnapshot.forEach((doc) => {
          tempData.push(doc.data())
          tempDocs.push(doc)
        });
       
      if (tempDocs.length > 0){
        setLast(tempDocs[tempDocs.length - 1])
        setFirst(tempDocs[0])
      }
      
      
      
      setPayments(tempData)
      
      });
      
      
      }
      
      
      /// function for last page pagination
      
      function prevPage(){
      
      const q = query(collection(db, "payments"), where('charged', '==', false), orderBy("date", "desc"), endBefore(first), limitToLast(10) )
      
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tempData = [];
        const tempDocs = []
        querySnapshot.forEach((doc) => {
          tempData.push(doc.data())
          tempDocs.push(doc)
        });
       
        if (tempDocs.length > 0){
          setLast(tempDocs[tempDocs.length - 1])
          setFirst(tempDocs[0])
        }
      
      setPayments(tempData)
      
      });
      
      }


  return <PlasmicReviewTransactions root={{ ref }} {...props} 
  paymentsListStack={{
    children:
    <>
    {payments.map(x => {
      return <ReviewTransactionsCard key={x.paymentDataId} amount={x.oldAmount ? x.oldAmount : x.amountNum.toLocaleString('en-US', {style: 'currency', currency: 'USD', })} clientCompany={x.clientCompany} paidStatus={x.status} paymentType={x.type} dateCreated={x.dateCreated} id={x.paymentDataId} paymentVoided={x.paymentVoided}/>
    })}
    </>
  }}
  paginationStack={{
    wrap: node => showPagination ? node : null
  }}
  nextButton={{
    onClick: () => nextPage()
  }}
  prevButton={{
    onClick: () => prevPage()
  }}
  vertStack={ loading && {
    children: <LoadingSpinner/>
  }}
  />;

}

const ReviewTransactions = React.forwardRef(ReviewTransactions_);

export default ReviewTransactions;
