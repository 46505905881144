// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect, useCallback } from "react";
import { PlasmicBillingSettings } from "./plasmic/astriusdraft/PlasmicBillingSettings";
import { db, storage } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from "firebase/firestore";
import LoadingSpinner from "./LoadingSpinner";
import { useAuth } from "../contexts/AuthContext";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from "react-plaid-link";
import axios from "axios";
import { v4 } from "uuid";
import { config } from "./constants";
import { ApideckVault } from "@apideck/vault-js";
import LoadingSpinner4 from "./LoadingSpinner4";
import InvitedStatusCard from "./InvitedStatusCard"
import AcceptedStatusCard from "./AcceptedStatusCard"
import { Button, Modal } from "react-bootstrap"
import InviteUserCard from "./InviteUserCard"
import InviteUserModal from "./InviteUserModal"
import { ToastContainer, toast } from 'react-toastify';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined, DeleteTwoTone, ReloadOutlined } from '@ant-design/icons';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./paymentCard/paymentCard.css";

function Link(props, ref1) {
  const [showPlans, setShowPlans] = useState(false);
  const [freeCheck, setFreeCheck] = useState(false);
  const [customCheck, setCustomCheck] = useState(false);
  const [premiumCheck, setPremiumCheck] = useState(false);
  const [growthCheck, setGrowthCheck] = useState(false);
  const [newPlan, setNewPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountingSoftware, setAccountingSoftware] = useState(props.screenData.accountingSoftware ? props.screenData.accountingSoftware : "Not Linked")
  const [showModal, setShowModal] = useState(false)
  const [user, setuser] = useState()
  const [loading2, setloading2] = useState(false)
  const [tab, setTab] = useState("financialDetail");
  const { currentUser, authUser ,checkPermission,showToast} = useAuth();
  const [imageLoading, setImageLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [color, setColor] = useState("");


  useEffect(() => {
    if (!currentUser) return setloading2(false);

    const q = query(
      collection(db, 'userInfo'),
      where('user', '==', currentUser.uid)
    );

    onSnapshot(q, (snapshot) => {
      if (snapshot.empty) return setloading2(false);

      const userInfo = snapshot.docs[0].data();
      setuser(userInfo)
      setImageUrl(userInfo?.logoUrl || "");
      setColor(userInfo?.color || "");
      setloading2(false)
    })
  }, [])
  

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };


  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPEG/JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };


  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setImageLoading(true);
      return;
    }
  };

  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        96x96 
      </div>
    </div>
  )

  const uploadFile = (e) => {
    if (e?.file == null) return;
    const reader = new FileReader();
    reader.readAsDataURL(e?.file);
    reader.addEventListener('load', event => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement('img');
      image.src = _loadedImageUrl;
      image.addEventListener('load', () => {
        // get image width and height
        const { width, height } = image;
        if(width != 96 && height != 96)
        { 
          toast.error("Logo size should be 96 x 96.", {
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setImageLoading(false);
          return;
        }
        const imageRef = ref(storage, `images/${e?.file.name + v4()}`);
        uploadBytes(imageRef, e?.file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
        setImageUrl(url);
      });
    });
      });
    });  
  };

  const handleRemoveLogo = async () => {
    setImageLoading(false);
    setImageUrl("");
  }
 

  const updateImageAndColor = async () => {
    try {
    
      
      await axios({
        method: "POST",
        url: `${config.endpoint}/addLogoAndColor`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          logoUrl: imageUrl,
          color : color,
          docId: props?.screenData?.docId
        }
      })
      showToast(true, "Company Logo and color updated successfully")

    } catch (error) {
      showToast(false, "Something went wrong")
      
    }
  }

  const handleClose = () => setShowModal(false)
  async function updatePlan() {
    var oldPlan = props.screenData.plan;

    if (newPlan !== "") {
      setShowPlans(false);

      if (newPlan === "Custom") {

        await updateDoc(doc(db, "userInfo", props.screenData.docId), {
          plan: newPlan,
          customLimit: 1,
          customPrice: 0.0025,
        });

      } else if (props.screenData.plan === "Free" && newPlan !== "Free") {
        
        await updateDoc(doc(db, "userInfo", props.screenData.docId), {
          plan: newPlan,
          date: new Date(),
          monthlyAmount: 0,
        });

      } else {
        await updateDoc(doc(db, "userInfo", props.screenData.docId), {
          plan: newPlan,
        });
      }

      setLoading(false);

      if (
        (newPlan === "Free" && oldPlan !== "Free") ||
        (newPlan !== "Free" && oldPlan === "Free")
      ) {
        alert(
          "Success! Your plan has been updated and your card processing rate will be changed by our acquiring bank within the next several hours. Please note that any payments made prior to this change will still be processed at your previous rate."
        );
      }
    }
  }



  const linkToApiDeck = async () => {
    if(!checkPermission("Link Accounting Software")){
      showToast(false,"Permission Denied")
      return;
    }

    setLoading(true)
    const response = await axios({
      method: "GET",
      url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=getApiDockAuthentication`,
      headers: {
        Authorization: `Bearer ${await currentUser.getIdToken()}`,
      },

    })
    
    ApideckVault.open({
      token: response.data.session_token,
      unifiedApi: "accounting",
      onClose: async () => {
       

        setLoading(true)

        const response = await axios({
          method: "GET",
          url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=checkConnectors`,
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`,
          },
    
        })
         setLoading(false)

        // also need to add a check if they switch their accounting software to something else
        if (!props.screenData.api_deck_ledger_id) {
       
              const ledgerPayload = {
                ledgerAccount: {
                  display_id: '23kdck2dd22d',
                  code: v4().substring(0, 10),
                  sub_type: "",
                  name: "Payment Processing Costs",
                  fully_qualified_name: "Payment Processing Costs",
                  description: "The costs to process payments",
                  classification: "expense",
                  type: "costs_of_sales",
                  currency: 'USD',
                  active: true,
                  status: 'active'
                },
              };

          
            window.addEventListener("beforeunload", handleBeforeUnload);


              const createLedgerResponse = await axios({
                method: "POST",
                url: `${config.endpoint}/apiDeckFunctions?consumerId=${currentUser.uid}&method=createLedger`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${await currentUser.getIdToken()}`,
                },
                data: ledgerPayload,
              })
            
              if(createLedgerResponse.data.accountingSoftware){
                setAccountingSoftware(createLedgerResponse.data.accountingSoftware)
              }

            window.removeEventListener("beforeunload", handleBeforeUnload);

        }


    }
  });

    setLoading(false)
  
    
  };

  const onSuccess = useCallback(async (public_token, metadata) => {
    setLoading(true)

    // combine this with the other one


    const updatePropayAccount = await axios({
      method: "POST",
      url: `${config.endpoint}/propayFunctions-updateBank`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await currentUser.getIdToken()}`,
      },
      data: {
        metaData: metadata,
        public_token_object: {
          public_token: public_token,
        },
        propayNumber: props.screenData.propayNumber,
      },
    })


    const updateBank = await axios({
      method: "POST",
      url: `${config.endpoint}/achFunctions-updateBank`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await currentUser.getIdToken()}`,
      },
      data: {
        public_token_object: {
          public_token: public_token,
        },
        metadata: metadata,
        user: currentUser.uid,
        userInfoDocId: props.screenData.docId,
        account_holder_name: props.screenData.firstName + " " + props.screenData.lastName,
      },
    })



    setLoading(false);
  }, []);

  const config1 = {
    onSuccess,
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: props.linkToken,
    env: config.version,
  };

  const { open, ready } = usePlaidLink(config1);

return ( <React.Fragment>
    <PlasmicBillingSettings
      root={{ ref1 }}
      {...props}
      vertStack={ loading && {
        children: <LoadingSpinner4/>
      }}
      financialDetails={{
        onClick: () => setTab("financialDetail")
      }}
      billingDetail={{
        wrap: (node) =>  tab === "financialDetail" ? node : null
      }}
      accountingSoftwareStack={{
        wrap: (node) =>  tab === "financialDetail" ? node : null
      }}
      userManagement={{
        onClick: () => setTab("userManagement")
      }}
      branding={{
        onClick: () => setTab("branding")
      }}
      brandingStack={{
        wrap: (node) =>  tab === "branding" ? node : null
      }}
      accountingSoftware={accountingSoftware}
      currentPlan={props.screenData.plan}
      bankName={props.screenData.bankName}
      freeStack={{
        wrap: (node) => (props.screenData.plan === "Free" ? null : node),
      }}
      customStack={{
        wrap: (node) => (props.screenData.plan === "Custom" ? null : node),
      }}
      premiumStack={{
        wrap: (node) => (props.screenData.plan === "Premium" ? null : node),
      }}
      growthStack={{
        wrap: (node) => (props.screenData.plan === "Growth" ? null : node),
      }}
      planStack={{
        wrap: (node) => (showPlans ? node : null),
      }}
      switchPlanStack={{
        wrap: (node) => (!showPlans ? node : null),
      }}
      switchPlan={{
        onClick: () => {
          if(!checkPermission("Switch Payment Plan")){
            showToast(false,"Permission Denied")
            return;
          }
          setShowPlans(true)
        }
      }}
      freeCheck={{
        isChecked: freeCheck,
        onChange: (e) => {
          setNewPlan("Free");
          setFreeCheck(!freeCheck);
          setCustomCheck(false);
          setPremiumCheck(false);
          setGrowthCheck(false);
        },
      }}
      premiumCheck={{
        isChecked: premiumCheck,
        onChange: (e) => {
          setNewPlan("Premium");
          setPremiumCheck(!premiumCheck);
          setCustomCheck(false);
          setGrowthCheck(false);
          setFreeCheck(false);
        },
      }}
      growthCheck={{
        isChecked: growthCheck,
        onChange: (e) => {
          setNewPlan("Growth");
          setGrowthCheck(!growthCheck);
          setCustomCheck(false);
          setPremiumCheck(false);
          setFreeCheck(false);
        },
      }}
      customCheck={{
        isChecked: customCheck,
        onChange: (e) => {
          setNewPlan("Custom");
          setCustomCheck(!customCheck);
          setPremiumCheck(false);
          setGrowthCheck(false);
          setFreeCheck(false);
        },
      }}
      closeButton={{
        onClick: () => {
          setShowPlans(false);
          setCustomCheck(false);
          setPremiumCheck(false);
          setFreeCheck(false);
          setGrowthCheck(false);
          setNewPlan("");
        },
      }}
      finalizePlan={{
        onClick: () => updatePlan(),
      }}
      switchBank={{
        onClick: () => {

          if(!checkPermission("Switch Bank Account")){
            showToast(false,"Permission Denied")
            return;
          }

          open()
        },
      }}
      linkAccount={{ onClick: () => linkToApiDeck() }}
      inviteUsersBtn = {{
        onClick: () => setShowModal(true)
      }}
      userListStack = {{
        children :<> 
       {loading2 ? <LoadingSpinner styles = {{height:"100px"}}/> :
        user?.invites && user?.invites.map((e) => {
          return <InviteUserCard key={e.email} userName = {e.email} statusCard = {e.status ? <AcceptedStatusCard/> : <InvitedStatusCard/>}/> 
        })}
        </>
      }}
      inviteUsersStack = {{
        wrap: node => authUser?.isChild || tab !== "userManagement" ? null : node
      }}
      colorPickerContainer = {{
        children: <div className="logo-color-picker-container">
          <div className="logoText">Button Color Picker</div>
          <div className="colorPickerInput">
          <input type="color" value={color} onChange={(e) => setColor(e.target.value)}/>
          {color != "" && <ReloadOutlined className="pointer" onClick={() => setColor("")}/>}
          </div>
          </div>
      }}
      logo = {{
        wrap: () => 
        <div className="upload-container">
        <div>
        <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader logo-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={uploadFile}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: '100%',
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
        </div>
       {imageUrl && <DeleteTwoTone className="pointer" onClick={handleRemoveLogo} twoToneColor={"#ff2400"}/>}
        </div>
      }}

      handleSubmit={{
        onClick: () => updateImageAndColor()
      }}
      
    />

    <Modal show={showModal} onHide={handleClose}
    backdrop="static"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">Invite User</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <InviteUserModal setShowModal = {setShowModal} showToast={showToast} user= {user}/>
    </Modal.Body>
    
  </Modal>
  </React.Fragment>
    
  );
}

function BillingSettings_(props, ref) {
  const [screenData, setScreenData] = useState({});

  const [linkToken, setLinkToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const { currentUser } = useAuth();

  

  async function generateToken() {
    const response = await axios({
      method: "POST",
      url: `${config.endpoint}/achFunctions-plaidAPIRequest`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await currentUser.getIdToken()}`,
      },
      data: {
        user: v4(),
      },
    });

    setLinkToken(response.data.link_token);

    setLoading(false)

  }

  useEffect(() => {
    const q = query(
      collection(db, "userInfo"),
      where("user", "==", currentUser.uid)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setScreenData({
          plan: doc.data().plan,
          bankName: doc.data().accountMetaData.accounts[0].name,
          docId: doc.id,
          customerUrl: doc.data().dwollaUrl ? doc.data().dwollaUrl : null,
          merchantName: doc.data().merchantName,
          mid: doc.data().mid,
          propayNumber: doc.data().propayNumber,
          phone: doc.data().phone,
          email: doc.data().email,
          businessType: doc.data().businessType,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          businessType: doc.data().businessType,
          api_deck_supplier_id: doc.data().api_deck_supplier_id,
          api_deck_ledger_id: doc.data().api_deck_ledger_id,
          accountingSoftware: doc.data().accountingSoftware,
          docId: doc.id
        });
      });
    });

    generateToken();
  }, []);

  
  return (
    <>
      { (linkToken == null || loading || !Object.keys(screenData).length) ? (
        <PlasmicBillingSettings vertStack={{
          children: <LoadingSpinner4/>
      }} root={{ ref }} {...props} />
      ) : (
        <Link linkToken={linkToken} screenData={screenData} />
      )}
    </>
  );

  
}

const BillingSettings = React.forwardRef(BillingSettings_);

export default BillingSettings;
