import * as React from "react";
import { PlasmicSelectBusinessType__OptionGroup } from "./plasmic/astriusdraft/PlasmicSelectBusinessType__OptionGroup";

function SelectBusinessType__OptionGroup(props) {
  const { plasmicProps } =
    PlasmicSelectBusinessType__OptionGroup.useBehavior(props);
  return <PlasmicSelectBusinessType__OptionGroup {...plasmicProps} />;
}

export default Object.assign(SelectBusinessType__OptionGroup, {
  __plumeType: "select-option-group"
});
