// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicResetPassword } from "./plasmic/astriusdraft/PlasmicResetPassword";
import { useAuth } from "../contexts/AuthContext"
import validator from 'validator'

function ResetPassword_(props, ref) {

  const { resetPassword } = useAuth()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [content, setContent] = useState('')


async function handleSubmit() {

setError(false)
setSuccess(false)

if (!(validator.isEmail(content))){
  setError(true)
} else {

try {

var email = content
setLoading(true)
await resetPassword(email)
setContent('')
setSuccess(true)

} catch(e){
  setError(true)

}
setLoading(false)

}

}



  return <PlasmicResetPassword root={{ ref }} {...props} resetPasswordButton={{
    onClick: () => handleSubmit(),
    disabled: loading
  }}
  loginEmailInput={{
    danger: error,
    value: content,
    onChange: (e) => {
      setContent(e.target.value);
    }

  }}
  invalidEmailText={{
    wrap: node => error ? node : null
  }}
  emailSuccessText={{
    wrap: node => success ? node : null
  }}
  />;
}

const ResetPassword = React.forwardRef(ResetPassword_);

export default ResetPassword;
