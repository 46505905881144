import * as React from "react";
import { PlasmicSelectCountry__OptionGroup } from "./plasmic/astriusdraft/PlasmicSelectCountry__OptionGroup";

function SelectCountry__OptionGroup(props) {
  const { plasmicProps } = PlasmicSelectCountry__OptionGroup.useBehavior(props);
  return <PlasmicSelectCountry__OptionGroup {...plasmicProps} />;
}

export default Object.assign(SelectCountry__OptionGroup, {
  __plumeType: "select-option-group"
});
