// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicAddClientModal } from "./plasmic/astriusdraft/PlasmicAddClientModal";
import { useAuth } from "../contexts/AuthContext";

function AddClientModal_(props, ref) {
 
const [companyContent, setCompanyContent] = useState('')
const [contactContent, setContactContent] = useState('')
const [emailContent, setEmailContent] = useState('')
const [invoiceIdContent, setInvoiceIdContent] = useState('')

const {clientSubmission, setClientSubmission} = useAuth()


  return <PlasmicAddClientModal root={{ ref }} {...props} 
  companyNameInput={{
    value: companyContent,
    placeholder: "Enter here",
    onChange: (e) => {
      setCompanyContent(e.target.value)
    },
    onBlur: (e) => {
      const temp = clientSubmission
      temp.company = e.target.value
      setClientSubmission(temp)
    }
  }}
  contactNameInput={{
    value: contactContent,
    placeholder: "Enter here",
    onChange: (e) => {
      setContactContent(e.target.value)
    },
    onBlur: (e) => {
      const temp = clientSubmission
      temp.contactName = e.target.value
      setClientSubmission(temp)
    }
  }}
  emailInput={{
    value: emailContent,
    placeholder: "Enter here",
    onChange: (e) => {
      setEmailContent(e.target.value)
    },
    onBlur: (e) => {
      const temp = clientSubmission
      temp.email = e.target.value
      setClientSubmission(temp)
    }
  }}
  identifier={{
    value: invoiceIdContent,
    onChange: (e) => {
      setInvoiceIdContent(e.target.value)
    },
    onBlur: (e) => {
      const temp = clientSubmission
      temp.invoiceId = e.target.value
      setClientSubmission(temp)
    }
  }}
  />;
}

const AddClientModal = React.forwardRef(AddClientModal_);

export default AddClientModal;