// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicTestNewPage } from "./plasmic/astriusdraft/PlasmicTestNewPage";
import { db } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit } from "firebase/firestore"; 
import { useAuth } from "../contexts/AuthContext";
import axios from "axios"
import {useHistory} from "react-router-dom"

function TestNewPage_(props, ref) {
  const [content, setContent] = useState('')
  const [dataItems, setDataItems] = useState([])
  const { currentUser } = useAuth()
  const history = useHistory()

async function handleSubmit(){
 
var tempContent = content

  setContent('')

  const escrowsubmisionsInstance = await addDoc(collection(db, "escrowsubmissions"), {
    title: "sample title",
    price: "sample price"
   })
    
    
   await addDoc(collection(escrowsubmisionsInstance, "Milestone Entries"), {
    milestoneTitle: "sample milestone title",
    milestonePrice: "sample milestone price",
    dueDate: "sample milestone due date"
   })
   

//   const docRef = doc(db, "customers", "sample");


//   const colRef = collection(docRef, "checkout_sessions")

//   await addDoc(colRef, {
//   price: "sddd",
//   and: "dsdds",
//   more: "shit",
// });

  // await addDoc(collection(db, "cities"), {
  //   contractTitle: tempContent,
  //   price: "$1000",
  //   user: currentUser.uid,
  //   date: serverTimestamp()
  // });

  // await addDoc(collection(db, "cities"), {
  //   name: "Tokyo",
  //   content: tempContent,
  //   user: currentUser.uid,
  //   date: serverTimestamp()
  // });

  
  
}


async function getData(){

const docRef = doc(db, "cities", "LA");
const docSnap = await getDoc(docRef);
console.log(docSnap.data().name)


const q = query(collection(db, "cities"), where("user", "==", currentUser.uid))

const querySnapshot = await getDocs(q);
console.log("QuereySnapshot: " + querySnapshot)

querySnapshot.forEach((doc) => {
  
  console.log(doc.id, " => ", doc.data());
});

}



useEffect(() => {
  const q = query(collection(db, "cities"), where("user", "==", currentUser.uid), orderBy("date"), limit(3))
  const tempData=[]
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const tempContent = [];
    querySnapshot.forEach((doc) => {
      tempData.push({id: doc.id, data: doc.data() })
        
    });
    
    setDataItems(tempData)
  });

}, [])


async function addItems(){

const idObject = JSON.stringify({
  id: currentUser.uid
})

 await axios({
  method: "POST",
  url: "https://us-central1-astrius-dev.cloudfunctions.net/setCustomClaims",
  data: {
    uid: idObject
  }
 })

 history.push("/")

}




function getData() {

console.log(currentUser)

  currentUser.getIdTokenResult()
  .then((idTokenResult) => {

    console.log(idTokenResult.claims.onboarded)


  }).catch((error) => {
    console.log(error)
  })

}


function handleAdTest() {

  
  window.dataLayer.push({'event': 'button1-click'})


}

async function testPro() {
// create test for call  testPro webhook
const res = await axios({
  method: "POST",
  url: 'https://us-central1-astrius-dev.cloudfunctions.net/propayFunctions-heartlandWebhook',
  headers: {
    'authorization': 'Basic jadf7923n11224343Sr12kfd090dfj3223hdf134nsd1djdid213'
  }, 
  data:
  {"credentials":{"deviceId":4174927,"siteId":386025,"publicApiKey":"pkapi_prod_abGs9IFbyTNV7oA2Z9","secretApiKey":"skapi_prod_MenjBQBPtD8AwuQ02Wj5mnExAz9ql_WKlVb4HWlZ_Q","website":"33415248","merchantId":"650000012641745"}}
})

console.log(res.data)

}


  return (
  <>
  <button onClick={testPro}>testPro</button>
<button onClick={addItems}>hello</button>
<button onClick={getData}>Get data</button>
<button onClick={handleAdTest}>Test it</button>
​<a href="#" name="button1" style={{display: "none"}} id="pivotButton" onclick="dataLayer.push({'event': 'button1-click'});">Button 1</a>
  </> )
}

const TestNewPage = React.forwardRef(TestNewPage_);

export default TestNewPage;
