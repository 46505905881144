// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect, useCallback} from "react";
import { PlasmicNewConfirmIdentity } from "./plasmic/astriusdraft/PlasmicNewConfirmIdentity";
import LoadingSpinner2 from "./LoadingSpinner2";
import {useAuth} from "../contexts/AuthContext"
import {db} from "../firebase"
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";
import { config } from './constants'
import {useHistory} from 'react-router-dom'
import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess } from 'react-plaid-link';
import LoadingSpinner2WithText from "./LoadingSpinner2WithText";


function Link (props, ref1) {
 
const [loading, setLoading] = useState(false)
const [confirmIdentityStarted, setConfirmIdentityStarted] = useState(false)
const history = useHistory()

const {currentUser} = useAuth()

useEffect(() => {

  setConfirmIdentityStarted(props.confirmIdentityStarted)

}, [])

const onSuccess = useCallback(async (public_token, metadata) => {  

setLoading(true)

  const response = await axios({
    method: "POST",
    url: `${config.endpoint}/onboardingFunctions-individualOnboarding`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await currentUser.getIdToken()}`
    },
    data: {
      "user": currentUser.uid,
      "session_id": metadata.link_session_id,
      "timestamp": new Date(),
      "email": currentUser.email,
    }
  })

  await updateDoc(doc(db, "userInfo", props.docId), {
    onboarding: {
      setupDetailsComplete: true,
      bankingDetailsComplete: true,
      planInformationComplete: true,
      confirmIdentityStarted: true,
    }
  })

  history.push("/application-received")

  setLoading(false)


  })

  const config1 = {
    onSuccess,
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: props.linkToken,
    env: config.version,
  }
  
  const {open, ready} = usePlaidLink(config1)


  return <PlasmicNewConfirmIdentity root={{ ref1 }} {...props} buttonLabel={confirmIdentityStarted ? "Continue Verification" : "Start Verification"}
  vertStack={ loading && {
    children: <LoadingSpinner2/>
  }}
  verificationButton={{
    onClick: async () => {

      // handles KYC verification here
      open()

// update database userInfo doc showing that the confirmIdentityStarted is true
      

    }
  }}
  />;
}



function NewConfirmIdentity_(props, ref) {
 
  const [loading, setLoading] = useState(true)
  const [linkToken, setLinkToken] = useState(null);
  const [docId, setDocId] = useState("")
  const [confirmIdentityStarted, setConfirmIdentityStarted] = useState(false)
  const [ip, setIp] = useState("")
  const [bizLoading, setBizLoading] = useState(false)

  const {currentUser} = useAuth()
  const history = useHistory()

  async function generateToken() {

    const q = query(
      collection(db, "userInfo"),
      where("user", "==", currentUser.uid)
    );

    const querySnapshot = await getDocs(q);

      var docId = ""
      var businessType = ""
      var firstName = ""
      var lastName = ""
      var confirmIdentityStarted = false

    querySnapshot.forEach((doc) => {
    
      docId = doc.id
      businessType = doc.data().businessType
      firstName = doc.data().firstName
      lastName = doc.data().lastName
      confirmIdentityStarted = doc.data().onboarding.confirmIdentityStarted

    });

    // change option1 to individual
    setDocId(docId)
    setConfirmIdentityStarted(confirmIdentityStarted)


if (businessType === "individual") {

    const response = await axios({
      method: "POST",
      url: `${config.endpoint}/onboardingFunctions-identityVerificationLinkToken`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      },
      data: {
        "user": currentUser.uid,
        "email": currentUser.email,
        "firstName":firstName,
        "lastName":lastName,
      }
    })
    
    setLinkToken(response.data)
    
    setLoading(false)

  } else {

    const ip = await axios.get('https://geolocation-db.com/json/')

    setIp(ip.data.IPv4)

    setLoading(false)
  }

    }


    useEffect(() => {

      const script = document.createElement('script')
      script.src = "https://cdn.withpersona.com/dist/persona-v4.7.1.js"
      script.async = true

      document.body.appendChild(script)


      return () => {
        document.body.removeChild(script)
      }


    }, [])


    useEffect(() => {
     
      generateToken()
      
      }, [] )
  

      return ( linkToken == null ? <PlasmicNewConfirmIdentity root={{ ref }} {...props} buttonLabel={confirmIdentityStarted ? "Continue Verification" : "Start Verification"} vertStack={ loading ? {
        children: <LoadingSpinner2/>
      } : bizLoading &&  {
        children: <LoadingSpinner2WithText text={'Validating Information'}/> 
      } }
      verificationButton={{
        onClick: async () => {
          // handle KYB verification here
          setBizLoading(true)

          const client = new window.Persona.Client({
            templateId: 'itmpl_F24d9JoXyDaLUNikEQGPxkgg',
            referenceId: currentUser.uid,
            environment: config.version,
            onReady: () => {
              setBizLoading(false)
              client.open()
            },
            onComplete: async ({ inquiryId, status, fields }) => {


              if (status === "failed") {

              // handle failed status
      
               
              } else {
      
                // handle success status
      
                setLoading(true)

                
                const response = await axios({
                  method: "POST",
                  url: `${config.endpoint}/onboardingFunctions-businessOnboarding`,
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${await currentUser.getIdToken()}`
                  },
                  data: {
                    "user": currentUser.uid,
                    "fields": fields,
                    "timestamp": new Date(),
                    "ip": ip,
                    "email": currentUser.email,
                  }
                })

                await updateDoc(doc(db, "userInfo", docId), {
                  onboarding: {
                    setupDetailsComplete: true,
                    bankingDetailsComplete: true,
                    planInformationComplete: true,
                    confirmIdentityStarted: true,
                  }
                })

              history.push("/application-received")
                

              setLoading(false)

              }
      
            },
            onCancel: () => {
           
            }
          });

        }
      }}
      /> : <Link linkToken={linkToken} docId={docId} confirmIdentityStarted={confirmIdentityStarted}/> ) 
 

  }

const NewConfirmIdentity = React.forwardRef(NewConfirmIdentity_);

export default NewConfirmIdentity;
