// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useRef, useEffect} from "react";
import { PlasmicOnboardingBusinessDetails } from "./plasmic/astriusdraft/PlasmicOnboardingBusinessDetails";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import {useAuth} from "../contexts/AuthContext"
import CurrencyInput from "react-currency-input-field";
import "./Boarding.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { hotjar } from 'react-hotjar';

hotjar.initialize(3206049, 6);


function OnboardingBusinessDetails_(props, ref1) {
 
  const history = useHistory()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [legalBusinessName, setLegalBusinessName] = useState('')
  const [displayBusinessName, setDisplayBusinessName] = useState('')
  const [ein, setEin] = useState("")
  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [industry, setIndustry] = useState('')
  const [error, setError] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [website, setWebsite] = useState('')
  const [suite, setSuite] = useState('')
  const [einError, setEinError] = useState(false)
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [addressError, setAddressError] = useState(false)
  const [cityError, setCityError] = useState(false)
  const [stateError, setStateError] = useState(false)
  const [zipError, setZipError] = useState(false)
  const [legalBusinessNameError, setLegalBusinessNameError] = useState(false)
  const [displayBusinessNameError, setDisplayBusinessNameError] = useState(false)
  const [suiteError, setSuiteError] = useState(false)
  const [industryError, setIndustryError] = useState(false)
  const [linkError, setLinkError] = useState(false)
  const [volumeError, setVolumeError] = useState(false)
  const [averageError, setAverageError] = useState(false)
  const [highestEstimationError, setHighestEstimationError] = useState(false)
  const [einlengtherror, seteinlengtherror] = useState(false)

  const monthlyVolume = useRef(null)
  const averageTicket = useRef(null)
  const highestTicket = useRef(null)

  const { businessDetails, setBusinessDetails} = useAuth()

  /// List of industries right now (make sure to updated in case this changes)
  // ["Food retail and service","Manufacturing","Business to business","Services - other",
  // "Entertainment and media","Home and garden","Baby","Travel","Clothing, accessories, and shoes",
  // "Health and personal care","Beauty and fragrances","Computers, accessories, and services","Retail",
  // "Toys and hobbies","Pets and animals","Education","Arts, crafts, and collectibles","Financial services and products",
  // "Government","Vehicle sales","Nonprofit","Vehicle service and accessories","Electronics and telecom","Books and magazines",
  // "Religion and spirituality (for profit)","Sports and outdoors","Gifts and flowers"]


  // just add all of the industries that you want in the drop-down menu and then make sure that the name of the selected industry is passed through here. 
  // You'll just have to check that it works, and also ask the people at Dwolla if it's ok what you're doing with not giving them all of the industry options.

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };


  useEffect(() => {

window.addEventListener("beforeunload", handleBeforeUnload);

  }, [])


  return <PlasmicOnboardingBusinessDetails root={{ ref1 }} {...props} 
  firstName={{
    value: firstName,
    onChange: (e) => {
      setFirstName(e.target.value)
      setFirstNameError(false)
    }
  }}
  lastName={{
    value: lastName,
    onChange: (e) => {
      setLastName(e.target.value)
      setLastNameError(false)
    }
  }}
  legalBusinessName={{
    value: legalBusinessName,
    onChange: (e) => {
      setLegalBusinessName(e.target.value)
      setLegalBusinessNameError(false)
    }
  }}
  displayBusinessName={{
    value: displayBusinessName,
    onChange: (e) => {
      setDisplayBusinessName(e.target.value)
      setDisplayBusinessNameError(false)
    }
  }}
  ein={{
    value: ein,
    onChange: (e) => {
      setEin(e.target.value)
      setEinError(false)
    },
    onBlur: (e) => {
      if (!(e.target.value.includes("-")) && ein.length > 2){
        setEin(ein.substring(0, 2) + "-" + ein.substring(2))
      }
    }
  }}
  address1={{
    value: address1,
    onChange: (e) => {
      setAddress1(e.target.value)
      setAddressError(false)
    }
  }}
  city={{
    value: city,
    onChange: (e) => {
      setCity(e.target.value)
      setCityError(false)
    }
  }}
  selectState={{
    onChange: (e) => {
     setState(e)
     setStateError(false)
    }
  }}
  zip={{
    value: zip,
    onChange: (e) => {
      setZip(e.target.value)
      setZipError(false)
    }
  }}
  selectIndustry={{
    onChange: (e) => {
      setIndustry(e)
      setIndustryError(false)
    }
  }}
  website={{
    value: website,
    onChange: (e) => {
      setWebsite(e.target.value)
      setLinkError(false)
    }
  }}
  suite={{
    value: suite,
    onChange: (e) => {
      setSuite(e.target.value)
      setSuiteError(false)
    }, 
    onBlur: (e) => {
      if (!(e.target.value.includes("suite") || e.target.value.includes("Suite") || e.target.value.includes("apartment") || e.target.value.includes("Apartment") || e.target.value.includes("STE") || e.target.value.includes("ste") || e.target.value.includes("Ste") || e.target.value.includes("apt") || e.target.value.includes("Apt") || e.target.value.includes("APT") || e.target.value.includes("Unit") || e.target.value.includes("unit")) && (e.target.value !== "") && (e.target.value !== " ")) {
        setSuite("Suite " + e.target.value)
      }
    }
  }}
  monthlyVolume={{
    children: <CurrencyInput ref={monthlyVolume}
    onValueChange={(value) => value !== undefined && setVolumeError(false)}
    className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  averageTicket={{
    children: <CurrencyInput ref={averageTicket}
    onValueChange={(value) => value !== undefined && setAverageError(false)}
    className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  highestTicket={{
    children: <CurrencyInput ref={highestTicket}
    onValueChange={(value) => value !== undefined && setHighestEstimationError(false)}
    className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  
  einlengtherror={{
    wrap: node => einlengtherror ? node : null
  }}
  errorText={{
    wrap: node => error ? node : null
  }}
  firstNameError={{
    wrap: node => firstNameError ? node : null
  }}
  lastNameError={{
    wrap: node => lastNameError ? node : null
  }}
  einError={{
    wrap: node => einError ? node : null
  }}
  addressError={{
    wrap: node => addressError  ? node : null
  }}
  cityError={{
    wrap: node => cityError  ? node : null
  }}
  stateError={{
    wrap: node => stateError  ? node : null
  }}
  zipError={{
    wrap: node => zipError  ? node : null
  }}
  legalNameError={{
    wrap: node => legalBusinessNameError  ? node : null
  }}
  displayNameError={{
    wrap: node => displayBusinessNameError  ? node : null
  }}
  industryError={{
    wrap: node => industryError  ? node : null
  }}
  linkError={{
    wrap: node => linkError ? node : null
  }}
  volumeError={{
    wrap: node => volumeError ? node : null
  }}
  averageTransactionError={{
    wrap: node => averageError ? node : null
  }}
  highestEstimationError={{
    wrap: node => highestEstimationError ? node : null
  }}
  continueButton={{
    onClick: () => {

      var mcc = ''
      var productDescription = ''

      var commodityCode = ''

      switch (industry){
        case "9ed38146-7d6f-11e3-89d6-5404a6144203":
          productDescription = "Marketing Services"
          mcc = '7311'
          commodityCode = '915-01'
          break;
        case "9ed46b9e-7d6f-11e3-9fec-5404a6144203":
          productDescription = "Graphic Design Services"
          mcc = '7333'
          commodityCode = '915-96'
          break;
        case "9ed3a851-7d6f-11e3-92a4-5404a6144203":
          productDescription = "Software Product/Service"
          mcc = '7372'
          commodityCode = '920-40'
          break;
        case "9ed3cf60-7d6f-11e3-a80d-5404a6144203":
          productDescription = "Accounting Services"
          mcc = '8931'
          commodityCode = '946-11'
          break;
        case "9ed3814f-7d6f-11e3-aaf6-5404a6144203":
          productDescription = "Wholesale Products"
          mcc = "5300"
          commodityCode = '010-83'
          break;
        case "9ed3813d-7d6f-11e3-bd65-5404a6144203":
          productDescription = "Education Services"
          mcc = '8299'
          commodityCode = '924-18'
          break;
        case "9ed3813c-7d6f-11e3-82cc-5404a6144203":
          productDescription = "Consulting Services"
          mcc = '7392';
          commodityCode = '918-20'
          break;
        case "9ed3cf63-7d6f-11e3-b50c-5404a6144203":
          productDescription = "Investment Services"
          mcc = "8999"
          commodityCode = "946-56"
          break;
        case "9ed46b95-7d6f-11e3-970b-5404a6144203":
          productDescription = "eCommerce Services"
          mcc = "7399"
          commodityCode = "918-36"
          break;
        case "9ed3a853-7d6f-11e3-a35d-5404a6144203":
          productDescription = "Web Hosting and Design Service"
          mcc = "7372"
          commodityCode = "209-31"
          break;
        case "9ed4449a-7d6f-11e3-a380-5404a6144203":
          productDescription = "Retail Products"
          mcc = "5999"
          commodityCode = '918-70'
          break;
        case "9ed41d7a-7d6f-11e3-ae21-5404a6144203":
          productDescription = "Construction Services"
          mcc = '8999'
          commodityCode = '918-31'
          break;
        case "9ed46ba6-7d6f-11e3-ae95-5404a6144203":
          productDescription = "Legal Services"
          mcc = '8111'
          commodityCode = "961-49"
          break;
        case "9ed35a38-7d6f-11e3-a24f-5404a6144203":
          productDescription = "Publishing Services"
          mcc = '2741'
          commodityCode = "961-90"
          break;
        case "9ed444a6-7d6f-11e3-ad1c-5404a6144203":
          productDescription = "Photography Services"
          mcc = '7333'
          commodityCode = "915-72"
          break;
        case "9ed492a5-7d6f-11e3-8d8f-5404a6144203":
          productDescription = "Telecommunication Services"
          mcc = '4814'
          commodityCode = "915-79"
          break;
        case "9ed4449f-7d6f-11e3-b867-5404a6144203":
          productDescription = "Public Relation Services"
          mcc = '7392'
          commodityCode = "915-03"
          break;
        case "9ed46ba3-7d6f-11e3-9efe-5404a6144203":
          productDescription = "Insurance Services"
          mcc = '5960'
          commodityCode = "953-56"
          break;
        case "9ed492ab-7d6f-11e3-9907-5404a6144203":
          productDescription = "Real Estate Services"
          mcc = '6513'
          commodityCode = "958-83"
          break;
        case "9ed3814e-7d6f-11e3-9087-5404a6144203":
          productDescription = "Secretarial Services"
          mcc = '7339'
          commodityCode = "961-02"
          break;
        case "9ed46b9a-7d6f-11e3-a11b-5404a6144203":
          productDescription = "Event Planning Services"
          mcc = '8999'
          commodityCode = "962-34"
          break;
        case "9ed3f679-7d6f-11e3-a884-5404a6144203":
          productDescription = "Gifting Services"
          mcc = '5947'
          commodityCode = "037-43"
          break;
        case "9ed4b9bb-7d6f-11e3-af9f-5404a6144203":
          productDescription = "Travel Agency Services"
          mcc = '4722'
          commodityCode = "961-78"
          break;
        case "9ed38143-7d6f-11e3-bc00-5404a6144203":
          productDescription = "Recruiting Services"
          mcc = '7361'
          commodityCode = "918-85"
          break;
        case "9ed3cf5a-7d6f-11e3-9a99-5404a6144203":
          productDescription = "Digital Media"
          mcc = "7311"
          commodityCode = "052-48"
          break;
          
      }

      if(firstName === "" )
        setFirstNameError(true)
      if(lastName === "")
        setLastNameError(true)
      if(address1 === "")
        setAddressError(true)
      if(suite === "")
        setSuiteError(true)
      if(city === "")
        setCityError(true)
      if(state === "")
        setStateError(true)
      if(zip === "")
        setZipError(true)
      if(legalBusinessName === "")
        setLegalBusinessNameError(true)
      if(displayBusinessName === "")
        setDisplayBusinessNameError(true)
      if(ein === "")
        setEinError(true)
      if(industry === "")
        setIndustryError(true)
      if(website === "")
        setLinkError(true)
      if(monthlyVolume.current.value === "")
        setVolumeError(true)
      if(highestTicket.current.value === "")
        setHighestEstimationError(true)
      if(averageTicket.current.value === "")
        setAverageError(true)


      setBusinessDetails({ firstName: firstName, lastName: lastName, legalBusinessName: legalBusinessName, displayBusinessName: displayBusinessName, ein: ein, address1: address1, city: city, 
          state: state, zip: zip, industry: industry, monthlyVolume: monthlyVolume.current.value, averageTicket: averageTicket.current.value, highestTicket: highestTicket.current.value, mcc: mcc, productDescription: productDescription, website: website, commodityCode: commodityCode, suite: suite })

          
          
        if (firstName === '' || lastName  === '' || legalBusinessName  === '' || displayBusinessName  === '' || ein  === '' || address1  === '' 
        || city  === '' || state  === '' || zip  === '' || industry  === '' || monthlyVolume.current.value === '' || averageTicket.current.value === '' || highestTicket.current.value === '' || website === ''){
          setError(true)
        } else if(ein.length !== 10){
          console.log("ein error",ein.length)
          seteinlengtherror(true)

        }
        else{
          
          window.removeEventListener("beforeunload", handleBeforeUnload);
          
          history.push("/onboarding-controller")
        }
        
    }
  }}

  />;
}




const OnboardingBusinessDetails = React.forwardRef(OnboardingBusinessDetails_);

export default OnboardingBusinessDetails;
