// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicNewApplicationReceived } from "./plasmic/astriusdraft/PlasmicNewApplicationReceived";
import {useHistory} from 'react-router-dom'

function NewApplicationReceived_(props, ref) {
 
  const history = useHistory()

  return <PlasmicNewApplicationReceived root={{ ref }} {...props} 
  continueButton={{
    onClick: () => {
      history.push("/")
    }
  }}
  />;
}

const NewApplicationReceived = React.forwardRef(NewApplicationReceived_);

export default NewApplicationReceived;
