// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicMilestoneFooter } from "./plasmic/astriusdraft/PlasmicMilestoneFooter";
import { useAuth } from "../contexts/AuthContext";

function MilestoneFooter_(props, ref) {

  const {submitNewMilestone} = useAuth()

  return <PlasmicMilestoneFooter root={{ ref }} {...props}
  submitButton={{
    onClick: () => submitNewMilestone()
  }}
  />;
}

const MilestoneFooter = React.forwardRef(MilestoneFooter_);

export default MilestoneFooter;
