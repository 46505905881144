import * as React from "react";
import { PlasmicSelectCountry__Option } from "./plasmic/astriusdraft/PlasmicSelectCountry__Option";

function SelectCountry__Option_(props, ref) {
  const { plasmicProps } = PlasmicSelectCountry__Option.useBehavior(props, ref);
  return <PlasmicSelectCountry__Option {...plasmicProps} />;
}

const SelectCountry__Option = React.forwardRef(SelectCountry__Option_);

export default Object.assign(SelectCountry__Option, {
  __plumeType: "select-option"
});
