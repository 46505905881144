import * as React from "react";
import { PlasmicSelectState__OptionGroup } from "./plasmic/astriusdraft/PlasmicSelectState__OptionGroup";

function SelectState__OptionGroup(props) {
  const { plasmicProps } = PlasmicSelectState__OptionGroup.useBehavior(props);
  return <PlasmicSelectState__OptionGroup {...plasmicProps} />;
}

export default Object.assign(SelectState__OptionGroup, {
  __plumeType: "select-option-group"
});
