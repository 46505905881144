// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useMemo} from "react";
import { useAuth } from "../contexts/AuthContext";
import { PlasmicConfirmEmail } from "./plasmic/astriusdraft/PlasmicConfirmEmail";
import { sendEmailVerification } from "firebase/auth";
import {auth} from "../firebase"
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module'
import { PlasmicGetStarted } from "./plasmic/astriusdraft/PlasmicGetStarted";


const tagManagerArgs = {
    gtmId: 'GTM-NF9C29K'
}
 
TagManager.initialize(tagManagerArgs)



function ConfirmEmail_(props, ref) {
  
 const {currentUser, isVerified, setIsVerified} = useAuth()
const history = useHistory();


  return <PlasmicConfirmEmail root={{ ref }} {...props} emailText={"Check " + currentUser.email + " to verify your email and get started"} 
  resendEmailButton={{
    onClick: async () => {
      await sendEmailVerification(auth.currentUser)
    }
  }}/>;
}

const ConfirmEmail = React.forwardRef(ConfirmEmail_);

export default ConfirmEmail;
