// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { useHistory } from 'react-router-dom';
import { PlasmicClientPaymentsCard } from "./plasmic/astriusdraft/PlasmicClientPaymentsCard";
import PaidStatusCard from "./PaidStatusCard"
import NotPaidStatusCard from "./NotPaidStatusCard"

function ClientPaymentsCard_(props, ref) {
  const history = useHistory()

  return <PlasmicClientPaymentsCard root={{ ref,
    onClick: () => history.push(`/payment/${props.id}`)
  }} {...props}
  paymentStatusStack={{
    children: props.status === 'Paid' ? <PaidStatusCard/> : <NotPaidStatusCard/>
  }}
  />;
}

const ClientPaymentsCard = React.forwardRef(ClientPaymentsCard_);

export default ClientPaymentsCard;
