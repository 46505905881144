import * as React from "react";
import { PlasmicSelectState } from "./plasmic/astriusdraft/PlasmicSelectState";
import Option from "./SelectState__Option";
import OptionGroup from "./SelectState__OptionGroup";

function SelectState_(props, ref) {
  const { plasmicProps, state } = PlasmicSelectState.useBehavior(props, ref);
  return <PlasmicSelectState {...plasmicProps} />;
}

const SelectState = React.forwardRef(SelectState_);

export default Object.assign(SelectState, {
  Option,
  OptionGroup,
  __plumeType: "select"
});
