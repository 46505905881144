import * as React from "react";
import { PlasmicSelectBizStructure__Option } from "./plasmic/astriusdraft/PlasmicSelectBizStructure__Option";

function SelectBizStructure__Option_(props, ref) {
  const { plasmicProps } = PlasmicSelectBizStructure__Option.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectBizStructure__Option {...plasmicProps} />;
}

const SelectBizStructure__Option = React.forwardRef(
  SelectBizStructure__Option_
);

export default Object.assign(SelectBizStructure__Option, {
  __plumeType: "select-option"
});
