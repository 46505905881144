// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Icons8Settings1Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 23 21"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M21.694 8.913l-2.719-.406a6.78 6.78 0 00-.649-1.43l1.587-2.065a.394.394 0 00-.052-.539l-1.788-1.632a.493.493 0 00-.594-.044L15.25 4.26a8.14 8.14 0 00-1.579-.6l-.474-2.471c-.039-.201-.23-.348-.453-.348h-2.53c-.226 0-.418.15-.454.352l-.44 2.46a8.18 8.18 0 00-1.581.592l-2.221-1.45a.493.493 0 00-.593.045L3.137 4.472a.393.393 0 00-.051.538l1.563 2.042c-.278.46-.5.947-.661 1.453l-2.682.408c-.222.034-.385.21-.385.414v2.31c0 .204.16.378.38.414l2.682.434c.16.506.382.992.661 1.454L3.061 15.96a.394.394 0 00.05.541l1.788 1.635a.493.493 0 00.589.047l2.24-1.433a8.19 8.19 0 001.585.598l.449 2.462c.036.202.228.35.453.35h2.53c.223 0 .414-.146.452-.346l.481-2.474a8.16 8.16 0 001.578-.603l2.256 1.445a.492.492 0 00.59-.046L19.89 16.5a.394.394 0 00.048-.542l-1.609-2.04c.273-.454.49-.933.647-1.432l2.723-.436c.22-.035.38-.21.38-.414v-2.31c0-.205-.163-.381-.385-.414zM11.5 13.44c-1.778 0-3.22-1.316-3.22-2.94 0-1.624 1.442-2.94 3.22-2.94 1.778 0 3.22 1.316 3.22 2.94 0 1.624-1.442 2.94-3.22 2.94z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icons8Settings1Icon;
/* prettier-ignore-end */
