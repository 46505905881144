import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

export default function EmailVerifiedRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()



  return (
    <Route
      {...rest}
      render={props => {
        if (currentUser){

          if (currentUser.emailVerified){
            return <Component {...props}/>
          } else {
            return <Redirect to="/verify-email" />
          }
          
        } else {
          return <Redirect to="/login" />
        }
       
      }}
    ></Route>
  )
    }