// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicDashboardPlasmic2TestingFormatIdea2 } from "./plasmic/astriusdraft/PlasmicDashboardPlasmic2TestingFormatIdea2";
import { useAuth } from "../contexts/AuthContext";
import { Button, Modal, Form } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import 'bootstrap/dist/css/bootstrap.min.css';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';



function DashboardPlasmic2TestingFormatIdea2_(props, ref) {
  const { currentUser } = useAuth()
  const [open1, setOpenModal] = useState(false)

  const history = useHistory()
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  const data = [{name: 'Page A', uv: 20}, {name: 'Page B', uv: 13}, {name: 'Page A', uv: 30} ];

/// consider using this one instead - https://www.fusioncharts.com/charts/line-area-charts/simple-line-chart?framework=react 

  return (
    <>
    <PlasmicDashboardPlasmic2TestingFormatIdea2 root={{ ref 
  }} {...props} graphStack={{
      children:
      <>
      {/* <ResponsiveContainer width="100%" height="100%">
      <LineChart width={600} height={300} data={data}>
    <Line type="monotone" dataKey="uv" stroke="#8884d8" />
    </LineChart>
      </ResponsiveContainer> */}
      </>
    }} 
    viewDetails={{
      onClick: () => history.push('/paymentslist')
    }}
    viewDetails2={{
      onClick: () => history.push('/contractslist')
    }}
    />

    </>
  );
}

const DashboardPlasmic2TestingFormatIdea2 = React.forwardRef(
  DashboardPlasmic2TestingFormatIdea2_
);

export default DashboardPlasmic2TestingFormatIdea2;
