// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicReviewTransactionsInstance } from "./plasmic/astriusdraft/PlasmicReviewTransactionsInstance";
import { useParams } from "react-router-dom";
import { db, storage } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import { config } from "./constants";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom"


function ReviewTransactionsInstance_(props, ref) {
  let { paymentDataId } = useParams();
  const [screenData, setScreenData] = useState({})
  const [loading, setLoading] = useState(true)
  const [userInfo, setUserInfo] = useState({})
  const { currentUser, authUser ,checkPermission,showToast} = useAuth();
  const  [docId, setDocId] = useState("")

  const history = useHistory()

  useEffect(async () => {

    const q = query(
      collection(db, "payments"),
      where("paymentDataId", "==", paymentDataId),
    );

    var userInfoDocId = ""


    const paymentSnapshot = await getDocs(q);
    paymentSnapshot.forEach((doc) => {
      setScreenData(doc.data())
      setDocId(doc.id)
      userInfoDocId = doc.data().userInfoDocId
    })

    const userRef = doc(db, "userInfo", userInfoDocId)
    const userSnap = await getDoc(userRef);

    setUserInfo(userSnap.data())


    setLoading(false)

  }, [])

  return loading ? <LoadingSpinner/> : <PlasmicReviewTransactionsInstance root={{ ref }} {...props} 
  transactionData={"Amount: " + screenData.amount + "\n Merchant Name: " + screenData.merchantName + "\n Individual Name: " + userInfo.firstName + " " + userInfo.lastName + "\n Merchant Email: " + screenData.userEmail + "\n Website: " + userInfo.website + "\n Product Description: " + userInfo.productDescription + "\n Monthly Volume: " + userInfo.monthlyVolume + "\n Average Ticket: " + userInfo.averageTicket + "\n Client Company: " + screenData.clientCompany + "\n Client Contact Name: " + JSON.parse(screenData.client).contactName+ "\n Client Email: " + screenData.clientEmail
   + "\n Invoice Items: " + screenData.invoiceFields.map((x) => { return x.item + ", "}) + "\n IP Match: " + screenData.ipMatch
  }
  approveButton={{
    onClick: async () => {
      setLoading(true)

      const response = await axios({
        method: "POST",
        url: `${config.endpoint}/propayFunctions-chargePaymentMethod`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await currentUser.getIdToken()}`,
        },
        data: {
          payment: screenData,
          userInfo: userInfo
        }
      })

      history.push('/reviewtransactions')

      setLoading(false)

    }
  }}
  declineButton={{
    onClick: async () => {

      setLoading(true)

      // eventually write code that updates this to send an email saying their account was banned if this ends up becoming a problem
      await updateDoc(doc(db, "payments", docId), {
        charged: true,
        paymentFraud: true,
      })

      await updateDoc(doc(db, "userInfo", screenData.userInfoDocId), {
        identityVerification: false
      });

      history.push('/reviewtransactions')

      setLoading(false)

    }
  }}
  />;

}

const ReviewTransactionsInstance = React.forwardRef(
  ReviewTransactionsInstance_
);

export default ReviewTransactionsInstance;
