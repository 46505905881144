// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect, useCallback}  from "react";
import { useHistory } from "react-router-dom";
import { PlasmicNewOnboardingBankingDetails } from "./plasmic/astriusdraft/PlasmicNewOnboardingBankingDetails";
import LoadingSpinner2 from "./LoadingSpinner2";
import axios from "axios";
import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess } from 'react-plaid-link';
import { v4 } from "uuid";
import {useAuth} from "../contexts/AuthContext"
import { config } from './constants'


function Link(props, ref1) {

  const {currentUser, publicToken, setPublicToken, metaData, setMetaData} = useAuth()
  const [showAlternate, setShowAlternate] = useState(false)
  const [accountNum, setAccountNum] = useState("")
  const [routingNum, setRoutingNum] = useState("")
  const [bankName, setBankName] = useState("")
  const [accountType, setAccountType] = useState("")
  const [loading, setLoading] = useState(false)


  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };


  useEffect(() => {

// adjust to the person that needs to manually link their bank account.
if (currentUser.email === "sidney@mishe.co"){
  setShowAlternate(true)
}

  }, [])


  const history = useHistory()


  const onSuccess = useCallback (async (public_token, metadata) => {

    window.addEventListener("beforeunload", handleBeforeUnload);

    axios({
      method: "POST",
      url: `${config.endpoint}/onboardingFunctions-getPlaidAccessToken`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      },
      data: {
      uid: currentUser.uid,
      public_token: public_token,
      metadata: metadata
      }
    })
    

    history.push("/onboarding-plan-information")

  })

  const config1 = {
    onSuccess,
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: props.linkToken,
    env: config.version,
  }
  
  const {open, ready} = usePlaidLink(config1)


return <PlasmicNewOnboardingBankingDetails root={{ ref1 }} {...props} addBankButton={{
  onClick: () => open()
}}
vertStack={ loading && {
  children: <LoadingSpinner2/>
}}
manualAdd={{
  wrap: (node) => (showAlternate ? node : null),
}}
accountNum={{
  value: accountNum,
  onChange: (e) => {
    setAccountNum(e.target.value)
  }
}}
routingNum={{
  value: routingNum,
  onChange: (e) => {
    setRoutingNum(e.target.value)
  }
}}
bankName={{
  value: bankName,
  onChange: (e) => {
    setBankName(e.target.value)
  }
}}
accountType={{
  onChange: (e) => {
    console.log(e)
    setAccountType(e)
  }
}}
manualSubmit={{
  onClick: () => {
  setPublicToken({accountNum: accountNum, routingNum: routingNum, bankName: bankName, accountType: accountType})
  history.push("/onboarding-plan-information")

  }
}}
/>;


}



function NewOnboardingBankingDetails_(props, ref) {

  const [loading, setLoading] = useState(true)
  const [linkToken, setLinkToken] = useState(null);
  const {currentUser} = useAuth()
  

  async function generateToken(){


    const response = await axios({
      method: "POST",
      url: `${config.endpoint}/achFunctions-plaidAPIRequest`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${await currentUser.getIdToken()}`
      },
      data: {
        "user": currentUser.uid
      }
    })
    
    setLinkToken(response.data.link_token)
    
    setLoading(false)

    }

    useEffect(() => {
     
      generateToken()
      
      }, [])


  return ( linkToken == null ? <PlasmicNewOnboardingBankingDetails root={{ ref }} {...props} vertStack={ loading && {
    children: <LoadingSpinner2/>
  }}/> : <Link linkToken={linkToken}/> )

}

const NewOnboardingBankingDetails = React.forwardRef(
  NewOnboardingBankingDetails_
);

export default NewOnboardingBankingDetails;
