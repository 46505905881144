// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicCreateChildUserPage } from "./plasmic/astriusdraft/PlasmicCreateChildUserPage";
import { useLocation } from 'react-router-dom';
import { config } from "./constants";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios"
import ChildPassword from "./ChildPassword"

function CreateChildUserPage_(props, ref) {

  const { search } = useLocation();
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(false)

  const searchParams = new URLSearchParams(search);
  const data = searchParams.get('data');


  const showToast = (status, msg) => {

    if (status) {
      toast.success(msg, {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      toast.error(msg, {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }


  }



  return <React.Fragment>
    <ToastContainer />

    <PlasmicCreateChildUserPage root={{ ref }} {...props}
      mainStack={{
        children: <>
          {loading ? <LoadingSpinner styles={{ height: "100px" }} />
            : <ChildPassword data={data} showToast={showToast} setLoading={setloading} />
          }
        </>
      }}
      displayText={loading && "Verifying User. Please wait !"}



    />


  </React.Fragment>
}

const CreateChildUserPage = React.forwardRef(CreateChildUserPage_);

export default CreateChildUserPage;
