// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicOnboardingBusinessDetailsRetry } from "./plasmic/astriusdraft/PlasmicOnboardingBusinessDetailsRetry";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import {useAuth} from "../contexts/AuthContext"
import "./Onboarding.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {updateDoc, doc} from "firebase/firestore"; 
import { db } from "../firebase"
import LoadingSpinner from "./LoadingSpinner";
import { config } from './constants'
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";

function OnboardingBusinessDetailsRetry_(props, ref) {
  const history = useHistory()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [legalBusinessName, setLegalBusinessName] = useState('')
  const [displayBusinessName, setDisplayBusinessName] = useState('')
  const [ein, setEin] = useState("")
  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [industry, setIndustry] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  

  const {currentUser, setupDetails, tempUrl, setTempUrl, onboardModal, setOnboardModal} = useAuth()

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };
  
  useEffect(() => {

console.log(4)

    window.addEventListener("beforeunload", handleBeforeUnload);
    
      }, [])


  return onboardModal ? <LoadingSpinner/> : loading ? <LoadingSpinnerWithText text={'Verifying Information - this may take up to 20 seconds'}/> : <PlasmicOnboardingBusinessDetailsRetry root={{ ref }} {...props}
  firstName={{
    value: firstName,
    onChange: (e) => {
      setFirstName(e.target.value)
    }
  }}
  lastName={{
    value: lastName,
    onChange: (e) => {
      setLastName(e.target.value)
    }
  }}
  legalBusinessName={{
    value: legalBusinessName,
    onChange: (e) => {
      setLegalBusinessName(e.target.value)
    }
  }}
  displayBusinessName={{
    value: displayBusinessName,
    onChange: (e) => {
      setDisplayBusinessName(e.target.value)
    }
  }}
  ein={{
    value: ein,
    onChange: (e) => {
      setEin(e.target.value)
    },
    onBlur: (e) => {
      if (!(e.target.value.includes("-")) && ein.length > 2){
        setEin(ein.substring(0, 2) + "-" + ein.substring(2))
      }
    }
  }}
  address1={{
    value: address1,
    onChange: (e) => {
      setAddress1(e.target.value)
    }
  }}
  city={{
    value: city,
    onChange: (e) => {
      setCity(e.target.value)
    }
  }}
  selectState={{
    onChange: (e) => {
     setState(e)
    }
  }}
  zip={{
    value: zip,
    onChange: (e) => {
      setZip(e.target.value)
    }
  }}
  selectIndustry={{
    onChange: (e) => {
      setIndustry(e)
    }
  }}
  errorText={{
    wrap: node => error ? node : null
  }}
  continueButton={{
    onClick: async () => {
    
        if (firstName === '' || lastName  === '' || legalBusinessName  === '' || displayBusinessName  === '' || ein  === '' || address1  === '' 
        || city  === '' || state  === '' || zip  === '' || industry  === ''){
          setError(true)
        } else{
         
          setLoading(true)


          var businessType = ''

          switch(setupDetails.businessStructure){
            case "single_member_llc":
              businessType = 'llc'
              break;
            case "multi_member_llc":
              businessType = 'llc'
              break;
            case "private_corporation":
              businessType = 'corporation'
              break;
            case "private_partnership":
              businessType = 'partnership'
              break;
          }

          var reqBody = {
            firstName: firstName,
            lastName: lastName,
            email: currentUser.email,
            type: "business",
            address1: address1,
            city: city,
            state: state,
            postalCode: zip,
            controller: {
              firstName: tempUrl.controller.firstName,
              lastName: tempUrl.controller.lastName,
              title: tempUrl.controller.jobTitle,
              dateOfBirth: tempUrl.controller.dob.getFullYear() + "-" + ("0" + (Number(tempUrl.controller.dob.getMonth()) + 1)).slice(-2) + "-" + ("0" + tempUrl.controller.dob.getDate()).slice(-2),
              ssn: tempUrl.controller.ssn,
              address: {
                address1: tempUrl.controller.address1,
                city: tempUrl.controller.city,
                stateProvinceRegion: tempUrl.controller.state,
                postalCode: tempUrl.controller.zip,
                country: tempUrl.controller.country,
              },
            },
            businessClassification: industry,
            businessType: businessType,
            businessName: legalBusinessName,
            ein: ein,
      
          }

          const retryStatus = await axios({
            method: "POST",
            url: `${config.endpoint}/onboardingFunctions-handleRetryStatus`,
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${await currentUser.getIdToken()}`
            },
            data: {
              customerUrl: tempUrl.customerUrl,
              reqBody: reqBody
            }
          })
         
         
          
    
          var customerUrlJSON = JSON.stringify({
            customerUrl: tempUrl.customerUrl
          })
      
          
          const resVerificationStatus = await axios({
            method: "POST",
            url: `${config.endpoint}/onboardingFunctions-getVerificationStatus`,
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${await currentUser.getIdToken()}`
            },
            data: {
              customerUrl: customerUrlJSON
            }
          })
      
       
          var businessVerificationStatus = resVerificationStatus.data.status
    
          
        
    
          if (businessVerificationStatus === "verified"){
    
         

            await updateDoc(doc(db, "userInfo", tempUrl.docId), {
              verificationStatus: businessVerificationStatus,
              date: new Date()
          
            })
    
    
            var proceed = true

            for (var i = 0; i < tempUrl.owners.length; i++){
           
              if (tempUrl.owners[i].ownerVerification !== "verified"){
  
                proceed = false
  
                setTempUrl({ownerUrl: tempUrl.owners[i].ownerUrl, name: tempUrl.owners[i].name, docId: tempUrl.docId, owners: tempUrl.owners, customerUrl: tempUrl.customerUrl })
  
                if (tempUrl.owners[i].ownerVerification === "incomplete"){
                  history.push("/verify-owners")
                  break
                } else {
                  history.push("/verify-owners-document")
                  break
                }
  
              
  
              }
  
            } 

            if (proceed){

              await updateDoc(doc(db, "userInfo", tempUrl.docId), {
                verificationStatus: "verified",
            
              })

              history.push("/business-approved")

            }
  
    
          } else if (businessVerificationStatus === 'document'){

      
        
            var linkNames = resVerificationStatus.data._links
    
            if (linkNames.hasOwnProperty("verify-with-document")) {
    
              setTempUrl({customerUrl: tempUrl.customerUrl, docId: tempUrl.docId, verifyType: "verify-with-document", owners: tempUrl.owners})
    
              /// change the verify type to the correct one for each merchant. 
              await updateDoc(doc(db, "userInfo", tempUrl.docId), {
                verificationStatus: "document",
                verifyType: "verify-with-document"
            
              })

              history.push("/onboarding-businessdetails-document")
    
            } else if (linkNames.hasOwnProperty("verify-business-with-document")) {
    
              setTempUrl({customerUrl: tempUrl.customerUrl, docId: tempUrl.docId, verifyType: "verify-business-with-document", owners: tempUrl.owners})
    
              await updateDoc(doc(db, "userInfo", tempUrl.docId), {
                verificationStatus: "document",
                verifyType: "verify-business-with-document"
            
              })

              history.push("/onboarding-businessdetails-document")
    
            } else if (linkNames.hasOwnProperty("verify-controller-and-business-with-document")){
    
              setTempUrl({customerUrl: tempUrl.customerUrl, docId: tempUrl.docId, verifyType: "verify-controller-and-business-with-document", owners: tempUrl.owners})
    
              await updateDoc(doc(db, "userInfo", tempUrl.docId), {
                verificationStatus: "document",
                verifyType: "verify-controller-and-business-with-document"
            
              })

              history.push("/onboarding-businessdetails-document")
    
            }
    
            
          }

          window.removeEventListener("beforeunload", handleBeforeUnload);

        }
        
    }
  }}
  />;

}

const OnboardingBusinessDetailsRetry = React.forwardRef(
  OnboardingBusinessDetailsRetry_
);

export default OnboardingBusinessDetailsRetry;
