import * as React from "react";
import { PlasmicSubscriptionSelect__OptionGroup } from "./plasmic/astriusdraft/PlasmicSubscriptionSelect__OptionGroup";

function SubscriptionSelect__OptionGroup(props) {
  const { plasmicProps } =
    PlasmicSubscriptionSelect__OptionGroup.useBehavior(props);
  return <PlasmicSubscriptionSelect__OptionGroup {...plasmicProps} />;
}

export default Object.assign(SubscriptionSelect__OptionGroup, {
  __plumeType: "select-option-group"
});
