// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Rect3338Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 17 13"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M8.316 0a1.042 1.042 0 00-.897.44L.146 11.338c-.405.604.08 1.374.866 1.375h14.545c.786 0 1.27-.77.865-1.375L9.15.44A1.036 1.036 0 008.316 0z"
        }
        fill={"#fff"}
      ></path>
    </svg>
  );
}

export default Rect3338Icon;
/* prettier-ignore-end */
