import React from "react";
import "../spinnertext2.css";

export default function LoadingSpinner2(props) {
  return (
    <div className="spinner2">
       <span className="displaytext">{props.text}...</span>
    <div className="half-spinner2"></div>
    </div>
  );
}