import React from "react";
import "../spinnertext.css";

export default function LoadingSpinnerWithText(props) {

  // window.onbeforeunload = function() {
  //   return "";
  // };

  return (
    <div className="spinner">
        <span className="displaytext1">{props.text}...</span>
    <div className="half-spinner"></div>
    </div>
  );
}