// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { PlasmicSignOutButton } from "./plasmic/astriusdraft/PlasmicSignOutButton";
import { useAuth } from "../contexts/AuthContext";


function SignOutButton_(props, ref) {
  const { currentUser } = useAuth()

 
  

  return <PlasmicSignOutButton root={{ ref }} {...props} />;

}


const SignOutButton = React.forwardRef(SignOutButton_);

export default SignOutButton;
