// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React from "react";
import { useHistory } from 'react-router-dom';
import { PlasmicPaymentsListCard } from "./plasmic/astriusdraft/PlasmicPaymentsListCard";
import PaidStatusCard from "./PaidStatusCard"
import NotPaidStatusCard from "./NotPaidStatusCard"
import VoidStatusCard from "./VoidStatusCard"

function PaymentsListCard_(props, ref) {
  const history = useHistory()

  return <PlasmicPaymentsListCard root={{ ref,
  onClick: () => history.push(`payment/${props.id}`)
  }} {...props} 
  statusCardStack={{
    children: props.paymentVoided ? <VoidStatusCard/> : props.paidStatus === 'Paid' ? <PaidStatusCard/> : <NotPaidStatusCard/>
  }}
  />;
}

const PaymentsListCard = React.forwardRef(PaymentsListCard_);

export default PaymentsListCard;
