// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicMilestoneHeader } from "./plasmic/astriusdraft/PlasmicMilestoneHeader";
import { useAuth } from "../contexts/AuthContext";

function MilestoneHeader_(props, ref) {
  const {currentUser, milestoneModal, setMilestoneModal} = useAuth()
 
  return <PlasmicMilestoneHeader root={{ ref }} {...props} 
  closeButton={{
    onClick: () => setMilestoneModal(false)
  }}
  />;
}

const MilestoneHeader = React.forwardRef(MilestoneHeader_);

export default MilestoneHeader;
