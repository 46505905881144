// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import "./Onboarding.css"
import React, {useState, useEffect} from "react";
import { PlasmicOnboardingController } from "./plasmic/astriusdraft/PlasmicOnboardingController";
import { useHistory } from 'react-router-dom';
import {useAuth} from "../contexts/AuthContext"
import ControllerCard from "./ControllerCard"
import { v4 } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function OnboardingController_(props, ref) {

  const history = useHistory()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [ssn, setSSN] = useState('')
  const [address1, setAddress1] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [dob, setDob] = useState('')
  const [passport, setPassport] = useState('')
  const [error, setError] = useState(false)
  const [currentDate, setCurrentDate] = useState('')
  const [addControllerStack, setAddControllerStack] = useState(false)
  const [controllerStackError, setControllerStackError] = useState(false)
  const [signature, setSignature] = useState('')
  const [customError, setCustomError] = useState('')
  const [controllerType, setControllerType] = useState('')
  const {controllers, setControllers, businessDetails, currentUser} = useAuth()
  const [ageError, setAgeError] = useState(false)
  const [driversLicense, setDriversLicense] = useState('')
  const [driverState, setDriverState] = useState('')
  const [ownership, setOwnership] = useState()
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [ssnError, setSSNError] = useState(false)

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  function getAge(date) {
    
    var ageInMilliseconds = new Date() - new Date(date);

    return Math.floor(ageInMilliseconds/1000/60/60/24/365)

 }

 useEffect(() => {

  window.addEventListener("beforeunload", handleBeforeUnload);

 }, [])

  
  return <PlasmicOnboardingController root={{ ref }} {...props}
  firstName={{
    value: firstName,
    onChange: (e) => {
      setFirstName(e.target.value)
    }
  }}
  lastName={{
    value: lastName,
    onChange: (e) => {
      setLastName(e.target.value)
    }
  }}
  selectControllerType={{
    value: controllerType,
    onChange: (e) => {
      setControllerType(e)
      if (e === 'manager' || e === 'both'){
        setEmail(currentUser.email)
      }
    }
  }}
  jobTitle={{
    value: jobTitle,
    onChange: (e) => {
      setJobTitle(e.target.value)
    }
  }}
  countrySelect={{
    value: country,
    onChange: (e) => {
      setCountry(e)
    }
  }}
  address1={{
    value: address1,
    onChange: (e) => {
      setAddress1(e.target.value)
    }
  }}
  city={{
    value: city,
    onChange: (e) => {
      setCity(e.target.value)
    }
  }}
  selectState={{
    wrap: node => country === 'US' || country === '' ? node : null,
    props: {
      value: state,
      onChange: (e) => {
        setState(e)
        setDriverState(e)
       }
    }
  }}
  zip={{
    value: zip,
    onChange: (e) => {
      setZip(e.target.value)
    }
  }}
  email={{
    value: email,
    onChange: (e) => {
      setEmail(e.target.value)
    }
  }}
  phoneNumber={{
    value: phone,
    onChange: (e) => {
      setPhone(e.target.value)
    }
  }}
  dob={{
    children: <DatePicker placeholderText={"mm/dd/yyyy"} className="dateBox" selected={dob} onChange={(date) => setDob(date)} />
  }}
  ssnStack={{
    wrap: node => country === 'US' || country === '' ? node : null
  }}
  ssn={{
    value: ssn,
    onChange: (e) => {
      setSSN(e.target.value)
      setPassport('NA')
    }, 
    onBlur: (e) => {
      if (!(e.target.value.includes("-")) && ssn.length > 4){
        setSSN(ssn.substring(0, 3) + "-" + ssn.substring(3,5) + "-" + ssn.substring(5))
      }
    }
  }}
  passportStack={{
    wrap: node => country === 'US' || country === '' ? null : node
  }}
  passport={{
    value: passport,
    onChange: (e) => {
      setPassport(e.target.value)
      setSSN('NA')
    }
  }}
  errorText={{
    wrap: node => error ? node : null,
  }}
  ageError={{
    wrap: node => ageError ? node : null
  }}
  ssnError={{
    wrap: node => ssnError ? node : null
  }}
  ownership={{
    value: ownership,
    onChange: (e) => {
      setOwnership(e.target.value)
    }
    
  }}
  submitButton={{
    onClick: async () => {

     
      if (firstName === '' || lastName  === '' || jobTitle  === '' || country  === '' || address1  === '' 
      || city  === '' || state  === '' || zip  === '' || dob  === '' || ssn === '' || passport === '' || controllerType === '' || email === '' || phone === ''){


      setControllerStackError(true)
      } else if (getAge(dob) < 18){
        setControllerStackError(false)
        setAgeError(true)
      } else if (ssn.length != 11){
        setSSNError(true)
      } 
      else {
        var temp = controllers
        temp.push({firstName: firstName, lastName: lastName, jobTitle: jobTitle, country: country, address1: address1,
          city: city, state: state, zip: zip, dob: dob, ssn: ssn, passport: passport, id: v4(), controllerType: controllerType, ownership: ownership, email: email, phoneNumber: phone
          })
        await setControllers(temp)
        setAddControllerStack(false)
        setFirstName('')
        setLastName('')
        setJobTitle('')
        setCountry('')
        setAddress1('')
        setCity('')
        setState('')
        setZip('')
        setDob('')
        setSSN('')
        setPassport('')
        setControllerStackError(false)
        setAgeError(false)
        setSSNError(false)
        setDriversLicense('')
        setDriverState('')
        setOwnership()

      }
     
    }
  }}
  signature={{
    value: signature,
    onChange: (e) => {
      setSignature(e.target.value)
    }
  }}
  currentDate={{
    children: <DatePicker className="dateBox" selected={currentDate} onChange={(date) => setCurrentDate(date)} />
  }}
  controllerStack={{
      children: controllers.map(x => {
        return <ControllerCard key={v4()} id={x.id} controllerName={x.firstName + " " + x.lastName}/>
      })
  }}
  addControllerStack={{
    wrap: node => addControllerStack ? node : null
  }}
  addNew={{
    wrap: node => addControllerStack ? null : node,
    props: {
      onClick: () => setAddControllerStack(true)
    }
  }}
  deleteStack={{
    onClick: () => {
      setAddControllerStack(false)
      setError(false)
    }
  }}
  controllerStackError={{
    wrap: node => controllerStackError ? node : null
  }}
  errorSlot={customError}
  certificationText={"I, " + businessDetails.firstName + " " + businessDetails.lastName + ", hereby certify, to the best of my knowledge, that the information provided above is complete and correct."}
  continueButton={{
    onClick: () => {

      var managerError = true
      var ownerError = true

      for (var i = 0; i < controllers.length; i++){

        if (controllers[i].controllerType === "manager"){
          managerError = false
        } else if (controllers[i].controllerType === "owner"){
          ownerError = false
        } else if (controllers[i].controllerType === "both"){
          managerError = false
          ownerError = false
        }

      }




      if (controllers.length == 0){
        setCustomError("Must add at least one person who is responsible for managing your company")
        setError(true)
      } else if (signature === '' || currentDate === ''){
        setCustomError("Must complete all required fields")
        setError(true)
      } else if (managerError){
        setCustomError("Must add at least one manager (or set the type to 'Both')")
        setError(true)
      } else if (ownerError){
        setCustomError("Must add at least one owner (or set the type to 'Both') ")
        setError(true)
      } else {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        
        history.push("/onboarding-businessbankingdetails")
        setError(false)
      }
      

    }
  }}
  />;
}

const OnboardingController = React.forwardRef(OnboardingController_);

export default OnboardingController;
