// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicMilestoneBody } from "./plasmic/astriusdraft/PlasmicMilestoneBody";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Input.css"
import CurrencyInput from "react-currency-input-field";
import { useAuth } from "../contexts/AuthContext";


function MilestoneBody_(props, ref) {


  
const {milestoneName,
  setMilestoneName,
  milestoneAmount,
  milestoneDueDate,
  setMilestoneDueDate, 
  milestoneDescription, 
  setMilestoneDescription} = useAuth()


  return <PlasmicMilestoneBody root={{ ref }} {...props} 
  name={{
    value: milestoneName,
    onChange: (e) => {
      setMilestoneName(e.target.value)
    }
  }}
  description={{
    value: milestoneDescription,
    onChange: (e) => {
      setMilestoneDescription(e.target.value)
    }
  }}
  dueDate={{
    children: <DatePicker className="dateBox2" selected={milestoneDueDate} onChange={(date) => setMilestoneDueDate(date)} />
  }}
  amount={{
    children: <CurrencyInput ref={milestoneAmount} 
    className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    // value={value}
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
  />
  }}
  />;
}

const MilestoneBody = React.forwardRef(MilestoneBody_);

export default MilestoneBody;
