import * as React from "react";
import { PlasmicSelectBusinessType__Option } from "./plasmic/astriusdraft/PlasmicSelectBusinessType__Option";

function SelectBusinessType__Option_(props, ref) {
  const { plasmicProps } = PlasmicSelectBusinessType__Option.useBehavior(
    props,
    ref
  );

  return <PlasmicSelectBusinessType__Option {...plasmicProps} />;
}

const SelectBusinessType__Option = React.forwardRef(
  SelectBusinessType__Option_
);

export default Object.assign(SelectBusinessType__Option, {
  __plumeType: "select-option"
});
