// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React from "react";
import { useHistory } from 'react-router-dom';
import { PlasmicContractsListCard } from "./plasmic/astriusdraft/PlasmicContractsListCard";
import InProgressSign from "./InProgressSign"
import CompletedSign from "./CompletedSign"

function ContractsListCard_(props, ref) {
  const history = useHistory()

  return <PlasmicContractsListCard root={{ ref, 
  onClick: () => history.push(`/contract/${props.id}`)
  }} {...props} contractStatusStack={{
    children: props.contractCompleted ? <CompletedSign/> : <InProgressSign/>
  }}
  />;
}

const ContractsListCard = React.forwardRef(ContractsListCard_);

export default ContractsListCard;
