import React from 'react'
import axios from "axios";
import { Button } from 'react-bootstrap';
import { useAuth } from "../contexts/AuthContext";

export default function AnotherTest() {

    const {currentUser} = useAuth()

// try doing the sendgrid transactional email using the tutorial it showed with the client side web API right here to see if it works.



    async function handleSubmit(){

        // const resp = await axios({
        //     method: 'POST',
        //     url: 'https://api.sendgrid.com/v3/mail/send',
        //     headers: {
        //         'Authorization': 'Bearer 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',
        //         'Content-Type': 'application/json'
        //     },
        //     data: {

        //     }

        // })

        // try {
        //     const resp = await axios.get('https://us-central1-astrius-dev.cloudfunctions.net/testCors')
        //     console.log(resp.data)
        // } catch(e){
        //     console.log(e)
        // }

        const resp = await axios({
            method: "POST",
            url: "http://localhost:5001/astrius-dev/us-central1/cardFunctions-nextTest",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${await currentUser.getIdToken()}`
              },
            data: {
                john :"test"
            }
        })


    }
  


    return (
        <>
        <h1>hello world</h1>
        <button onClick={handleSubmit}>submit</button>
        </>
        
    )
}
