// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicOnboardingPersonalApplicationReceived } from "./plasmic/astriusdraft/PlasmicOnboardingPersonalApplicationReceived";
import LoadingSpinner from "./LoadingSpinner";
import {useHistory} from "react-router-dom"
import {useAuth} from "../contexts/AuthContext"

function OnboardingPersonalApplicationReceived_(props, ref) {
 
  const {onboardModal, setOnboardModal} = useAuth()
  const history = useHistory()

  return onboardModal ? <LoadingSpinner/> : <PlasmicOnboardingPersonalApplicationReceived root={{ ref }} {...props} />

}

const OnboardingPersonalApplicationReceived = React.forwardRef(
  OnboardingPersonalApplicationReceived_
);

export default OnboardingPersonalApplicationReceived;
